import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import { DateFormat } from '../../enum/DateFormat';
import { Comment } from '../../interfaces/comment';
import { getClientFormattedDate } from '../../lib/utilities';

interface IProps {
  input: Comment[];
  includeSystem?: boolean;
  includeUser?: boolean;
}

const CommentComponent: React.FC<IProps> = ({ input, includeSystem, includeUser }) => {
  const filteredComments =
    input && input.length > 0
      ? includeUser && !includeSystem
        ? input.filter((comment) => !comment.isSystemComment)
        : includeSystem && !includeUser
          ? input.filter((comment) => comment.isSystemComment)
          : input
      : [];
  return (
    <Fragment>
      {filteredComments && filteredComments.length > 0 ? (
        <Fragment>
          {filteredComments.map((item, index: number) => (
            <span key={index}>
              <Row className="mb-1" style={{ padding: '0 10px' }}>
                <Col md="3">
                  <span className="span-cell bold">{`Date/Time: `}</span>
                  {getClientFormattedDate(item.create_date, DateFormat.MediumBoth)}
                </Col>
                <Col md="3">
                  <span className="span-cell bold">{`From: `}</span>
                  {item.userName}
                </Col>
                <Col md="3">
                  <span className="span-cell bold">{`Provider: `}</span>
                  {item.userProvider?.name}
                </Col>
              </Row>
              <Row className="mb-3" style={{ padding: '0 10px' }}>
                <Col>
                  <span className="span-cell bold">{`Comment: `}</span>
                  {item.contentText}
                </Col>
                <Col>
                  <span style={{ fontWeight: 'bold' }}>{`Attachments: `}</span>
                  {(item.attachments && item.attachments.length) > 0 ? (
                    item.attachments.map((attachment) => (
                      <a
                        key={index + '-' + attachment.unique_id}
                        style={{ fontWeight: 'bold', color: '#0f72b1' }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`/api/attachments/${attachment.unique_id}`}
                      >
                        {attachment.fileName}
                      </a>
                    ))
                  ) : (
                    <span>None</span>
                  )}
                </Col>
              </Row>
            </span>
          ))}
        </Fragment>
      ) : (
        <Row style={{ padding: '0 10px' }}>
          <Col md="12">
            <p>None</p>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default CommentComponent;
