import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Label } from 'reactstrap';
import { ReportFormat, ReportPostData, ReportType } from '../../../../interfaces/report';
import { getReportObject } from '../../../../redux/report/thunks';
import { SelectOption } from '../../../CustomSelect';
import LabelSelect from '../../../shared/LabelSelect';
import { getApiFormattedDate, getUtcStartDate } from '../../../../lib/utilities';
import GeneralDatePicker from '../../../GeneralDatePicker';

interface IProps {
  getReportObject: Function;
}
const SixtyDaysReport: React.FC<IProps> = ({ getReportObject }) => {
  const [startDate, setStartDate] = useState<string>(getUtcStartDate({ months: 1 }));
  const [chosenLabels, setChosenLabels] = useState<number[]>([]);

  const handleSelectLabels = (value: any) => {
    setChosenLabels(value ? value.map((item: SelectOption) => item.value) : []);
  };

  const createExcel = () =>
    getReportObject(
      {
        startDate: getApiFormattedDate(startDate),
        chosenLabels
      } as ReportPostData,
      ReportType.SixtyDaysReport,
      ReportFormat.Excel
    );

  return (
    <Fragment>
      <Label className="traceback-label ps-0 mt-4">Provide The Starting Date *</Label>
      <div className="d-flex mb-4">
        <GeneralDatePicker
          startDate={startDate}
          setStartDate={setStartDate}
          containerClassname="col-md-2"
          onlyStartDate
        />
      </div>
      <Label className="traceback-label ps-0">Campaign Labels Filter</Label>
      <div className="d-flex ps-0 me-0 mb-4">
        <Col className="ps-0 col-md-3">
          <LabelSelect
            isMulti
            addAllItem
            value={chosenLabels}
            onChange={handleSelectLabels}
            numberDisplayed={2}
            maxMenuHeight={170}
          />
        </Col>
      </div>
      <Button
        className="telecom-btn me-2"
        color="primary"
        style={{
          height: '28px',
          padding: '5px',
          fontSize: '14px',
          width: '150px'
        }}
        onClick={createExcel}
      >
        {`Create .xlsx Doc`}
      </Button>
    </Fragment>
  );
};

const mapActionsToProps = {
  getReportObject
};

export default connect(null, mapActionsToProps)(SixtyDaysReport);
