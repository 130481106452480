import React, { Fragment, useState } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import { Button, Popover, PopoverBody } from 'reactstrap';
import uploadDropLogo from '../assets/images/upload-drop.svg';
import uploadLogo from '../assets/images/upload.svg';
import {
  MAX_FILE_SIZE,
  supportedAudioFileFormats,
  supportedFilesFormats,
  verifyAttachmentsList,
  verifySizeAttachments
} from '../lib/dropZoneUtils';

interface IProps {
  attachments: any[];
  addFile: Function;
  removeFile: Function;
  alignment?: string;
  noPopover?: boolean;
  disabled?: boolean;
  uniqueId?: string;
  audiofile?: boolean;
}

const CustomDropZone: React.FC<IProps> = ({
  attachments,
  removeFile,
  addFile,
  alignment,
  noPopover,
  disabled,
  uniqueId,
  audiofile
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const [errorAttachments, setErrorAttachments] = useState('');
  const supportedFiles = audiofile ? supportedAudioFileFormats : supportedFilesFormats;
  const toggle = () => {
    setShowPopover((value) => !value);
  };
  const setErrors = (fileRejections: FileRejection[]) => {
    const files = fileRejections.map((v) => v.file);
    if (verifySizeAttachments(files) && verifyAttachmentsList(files, supportedFiles)) {
      setErrorAttachments(
        `File size to large for upload, please limit files to 5MB or less. File type needs to be [${supportedFiles.join(
          ', '
        )}]`
      );
    } else if (!verifySizeAttachments(files) && verifyAttachmentsList(files, supportedFiles)) {
      setErrorAttachments(
        `Can't upload file type. Please limit uploads to [${supportedFiles.join(', ')}]`
      );
    } else if (verifySizeAttachments(files) && !verifyAttachmentsList(files, supportedFiles)) {
      setErrorAttachments('File size to large for upload, please limit files to 5MB or less.');
    }
  };
  return noPopover ? (
    <Fragment>
      {attachments &&
        attachments.map((file: any, index: number) => (
          <p key={`file-${index}`} className="telecom-text mb-0">
            {`${file.name} `}
            <i
              className="fa fa-close"
              style={{ color: 'red', cursor: 'pointer' }}
              onClick={(e) => {
                e.preventDefault();
                removeFile(file);
              }}
            ></i>
          </p>
        ))}
      <Dropzone
        onDrop={(fileList) => {
          addFile(fileList);
        }}
        accept={audiofile ? { 'audio/*': supportedFiles } : { 'application/*': supportedFiles }}
        maxSize={MAX_FILE_SIZE}
        onDropRejected={(fileRejections: FileRejection[]) => setErrors(fileRejections)}
        onDropAccepted={() => setErrorAttachments('')}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <p className="text-center">
              <img width="30" height="30" src={uploadDropLogo} alt="upload-drop" />
            </p>
            <p className="text-center">Drag and Drop files here</p>
          </div>
        )}
      </Dropzone>
      {errorAttachments && <small className="text-danger">{errorAttachments}</small>}
    </Fragment>
  ) : (
    <Fragment>
      <small>Upload supporting documentation.</small>
      {attachments &&
        attachments.map((file: any, index: number) => (
          <p key={`file-${index}`} className="telecom-text mb-0">
            {`${file.name} `}
            <i
              className="fa fa-close"
              style={{ color: 'red', cursor: 'pointer' }}
              onClick={(e) => {
                e.preventDefault();
                removeFile(file);
              }}
            ></i>
          </p>
        ))}
      <div className={`d-flex justify-content-${alignment ? alignment : 'start'} m-0`}>
        <Button
          className="telecom-btn"
          id={`${uniqueId ? uniqueId : 'upload-button'}`}
          style={{
            marginTop: '5px',
            marginRight: '0px',
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={(e) => {
            e.preventDefault();
            toggle();
          }}
          disabled={disabled}
        >
          <img style={{ width: '14px', marginRight: '5px' }} src={uploadLogo} alt="upload" />
          Upload
        </Button>
      </div>
      <Popover
        target={`${uniqueId ? uniqueId : '#upload-button'}`}
        placement="bottom"
        isOpen={showPopover}
      >
        <PopoverBody>
          <div>
            <Button
              className="dropZoneClose"
              onClick={(e) => {
                e.preventDefault();
                toggle();
              }}
            >
              <i className="fa fa-close" style={{ fontSize: '16px' }} />
            </Button>
          </div>
          <div className="d-flex justify-content-center">
            <Dropzone
              onDrop={(fileList) => {
                addFile(fileList);
                toggle();
              }}
              accept={
                audiofile ? { 'audio/*': supportedFiles } : { 'application/*': supportedFiles }
              }
              maxSize={MAX_FILE_SIZE}
              onDropRejected={(fileRejections: FileRejection[]) => setErrors(fileRejections)}
              onDropAccepted={() => setErrorAttachments('')}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p className="text-center">
                    <img width="30" height="30" src={uploadDropLogo} alt="upload-drop" />
                  </p>
                  <p className="text-center">Drag and Drop files</p>
                  <p className="text-center">or</p>
                  <p className="text-center">
                    <Button
                      className="telecom-btn"
                      style={{ minWidth: '100px', marginRight: '0px' }}
                    >
                      Browse
                    </Button>
                  </p>
                </div>
              )}
            </Dropzone>
          </div>
        </PopoverBody>
      </Popover>
      {errorAttachments && <small className="text-danger">{errorAttachments}</small>}
    </Fragment>
  );
};

export default CustomDropZone;
