import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { Button, Label } from 'reactstrap';
import { userTypes } from '../../enum/userTypes';
import { CampaignAttributes } from '../../interfaces/campaign';
import { DisputeStatus, Hop, HopDispute } from '../../interfaces/hop';
import { NotificationType } from '../../interfaces/notification';
import { PersonalInfo } from '../../interfaces/user';
import { campaignAttributes } from '../../lib/campaign';
import { resolveHopDisputeApiCall } from '../../redux/hop/apiCalls';
import { getHopDisputeObject, getHopObject } from '../../redux/hop/thunks';
import { deleteNotificationByObjectApiCall } from '../../redux/notification/apiCalls';
import { stateMappings } from '../../redux/stateMappings';
import CustomToolTip from '../CustomToolTip';
import InputFormGroup from '../inputFormGroup';

interface IProps {
  user: PersonalInfo;
  hop: Hop;
  campaignAttribute: CampaignAttributes;
  dispute: HopDispute | null;
  getHopObject: Function;
  getHopDisputeObject: Function;
}
const messageDispute =
  'This notice is to formally inform you that after analysis and review, the ITG team continues to have a reasonable basis to suspect that the call subject to the traceback is fraudulent, abusive, and/or unlawful.';
const ResolveDispute: React.FC<IProps> = ({
  user,
  hop,
  dispute,
  campaignAttribute,
  getHopObject,
  getHopDisputeObject
}) => {
  const { hash } = useLocation();
  const commentRef = useRef<HTMLDivElement | null>(null);
  const [message, setMessage] = useState(messageDispute);
  const [isResolving, setIsResolving] = useState(false);

  useEffect(() => {
    if (commentRef && commentRef.current && hash === '#dispute') {
      commentRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      setTimeout(() => {
        deleteNotificationByObjectApiCall(hop.hopId, NotificationType.NewDispute);
      }, 3000);
    }
  }, [hash, commentRef.current]);

  useEffect(() => {
    if (!dispute || dispute.hopId !== hop.hopId) getHopDisputeObject(hop.hopId);
  }, [hop.hopId]);

  if (!dispute) return null;
  const comment = (hop.comments || []).find((v) => v.commentId === dispute?.commentId);
  const replyComment = (hop.comments || []).find(
    (v) => comment && v.replyToCommentID === comment?.commentId
  );

  const resolve = async (status: DisputeStatus) => {
    if (isResolving) return;
    setIsResolving(true);
    try {
      await resolveHopDisputeApiCall(hop.hopId, status, message === messageDispute ? '' : message);
    } catch (error: any) {
      console.log(error);
    } finally {
      getHopObject(hop.hopId);
      setIsResolving(false);
    }
  };

  return (
    <div ref={commentRef}>
      <div className="row ps-2">
        <div className="col-12 col-md-7">
          <div className="row">
            <Label className="telecom-label p-1">Basis for Traceback</Label>
          </div>
          <div className="row">
            <div className="label-bold">Disputed</div>
          </div>
          <div className="row">
            <ul style={{ listStyleType: 'none' }}>
              {campaignAttributes.map(({ name, toolMessage, tag }, index) => {
                return (
                  campaignAttribute[tag as keyof CampaignAttributes] && (
                    <li key={index}>
                      <div className="d-flex flex-row pb-2">
                        <div>
                          <input
                            disabled
                            type="checkbox"
                            id={`custom-checkbox-${index}`}
                            name={'attributes'}
                            defaultChecked={dispute.attributes[tag as keyof CampaignAttributes]}
                          />
                        </div>
                        <div className="ms-2">
                          <CustomToolTip tooltipVisible message={toolMessage}>
                            <label className="text-dark">{name}</label>
                          </CustomToolTip>
                        </div>
                      </div>
                    </li>
                  )
                );
              })}
            </ul>
          </div>
        </div>
        <div className="col-12 col-md-5 mb-3">
          <div className="d-inline">
            <span className="label-bold me-2">Consent From Called Party:</span>
            {dispute.consentGiven && <span>Consent was given</span>}
          </div>
          <div>{comment?.contentText}</div>
          <div className="d-flex flex-wrap mt-2">
            <span className="span-cell bold me-2">Attachments: </span>
            {!!comment && comment?.attachments && comment?.attachments.length > 0
              ? comment?.attachments.map((attachment) => (
                  <a
                    key={comment.commentId + '-' + attachment.unique_id}
                    style={{ fontWeight: 'bold', color: '#0f72b1' }}
                    target="_blank"
                    href={`/api/attachments/${attachment.unique_id}`}
                  >
                    {attachment.fileName}
                  </a>
                ))
              : 'none'}
          </div>
        </div>
      </div>
      {user.roleType === userTypes.Admin && hop.status === 7 && (
        <Fragment>
          <div className="row d-flex align-content-center justify-content-center">
            <Label className="telecom-label">
              Customize message for marking Strike Exempt OR Rejection
            </Label>
          </div>
          <div className="row d-flex align-content-center justify-content-center">
            <InputFormGroup
              isTextarea
              inputName="emailMessage"
              inputId="emailMessage"
              inputClassName="input-comment"
              inputValue={message}
              inputOnChange={(e) => {
                if (e && e.currentTarget) setMessage(e.currentTarget.value);
              }}
              inputAutoComplete="off"
              containerClassName="col-md-10"
            />
          </div>
          <div className="d-flex align-content-center justify-content-center">
            <Button
              style={{ fontSize: '80%' }}
              color="primary"
              className="ms-2"
              disabled={isResolving}
              onClick={() => {
                resolve(DisputeStatus.AcceptedDispute);
              }}
            >
              Make Traceback Strike Exempt
            </Button>
            <span className="m-2">OR</span>
            <Button
              style={{ fontSize: '80%' }}
              color="primary"
              disabled={isResolving}
              onClick={() => {
                resolve(DisputeStatus.RejectedDispute);
              }}
            >
              Reject & Close Traceback
            </Button>
          </div>
        </Fragment>
      )}
      {dispute.status === DisputeStatus.RejectedDispute && replyComment && (
        <div className="row px-5 text-center">{replyComment.contentText}</div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    user: sm.user,
    dispute: sm.hop.hopDispute
  };
};

const mapActionsToProps = {
  getHopObject,
  getHopDisputeObject
};

export default connect(mapStateToProps, mapActionsToProps)(ResolveDispute);
