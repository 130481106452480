import { SerializedError } from '@reduxjs/toolkit';
import { convertToLocalTime } from 'date-fns-timezone';
import React, { Fragment, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { OnChangeValue } from 'react-select';
import { Button, Card, CardBody, CardHeader, Form } from 'reactstrap';
import Breadcrumbs from '../components/Breadcrumbs';
import CustomSelect, { SelectOption } from '../components/CustomSelect';
import DropZone from '../components/DropZone';
import InputError from '../components/inputError';
import InputFormGroup from '../components/inputFormGroup';
import RequestorSelect from '../components/requests/RequestorSelect';
import ZendeskWebWidget from '../components/shared/ZendeskWebWidget';
import { userTypes } from '../enum/userTypes';
import { RequestIncident } from '../interfaces/request';
import { validatePhoneNumber } from '../lib/phoneNumber';
import { validTimeRegex } from '../lib/regex';
import { getTimeFromString, isValidUrl, stringToDate } from '../lib/utilities';
import { createRequest } from '../redux/request/apiCalls';
import { selectUser } from '../redux/stateMappings';
import { useAppDispatch, useAppSelector } from '../redux/store';
import GeneralDatePicker from '../components/GeneralDatePicker';

const natureOfRequestOptions: SelectOption[] = [
  {
    label: 'Loss Due to Fraud - Calls relate to specific victim(s) who suffered loss',
    value: 'Loss Due to Fraud'
  },
  {
    label: 'Active Case - Gathering evidence to support current or planned litigation',
    value: 'Active Case'
  },
  { label: 'Investigation - Responsive to complaints from constituent(s)', value: 'Investigation' }
];

const liveOrArtificialOptions: SelectOption[] = [
  { label: 'Live', value: true },
  { label: 'Prerecorded or Artificial Voice', value: false }
];

const recordingTypeOptions: SelectOption[] = [
  {
    label: 'Recording corresponds to the specific call example listed',
    value: 'Recording corresponds to the specific call example listed'
  },
  {
    label: 'Recording is typical of calls received (but not specific to this call)',
    value: 'Recording is typical of calls received (but not specific to this call)'
  },
  { label: 'No recording provided', value: 'No recording provided' }
];

const calledPartyTypeOptions: SelectOption[] = [
  { value: 'Wireless', label: 'Wireless' },
  { value: 'Residential', label: 'Residential' },
  { value: 'Business', label: 'Business' },
  { value: 'Other', label: 'Other' },
  { value: 'Unknown', label: 'Unknown' }
];

const timezoneList: SelectOption[] = [
  { value: 'UTC', label: 'UTC' },
  { value: 'EST', label: 'Eastern (UTC-5)' },
  { value: 'CST6CDT', label: 'Central (UTC-6)' },
  { value: 'MST', label: 'Mountain (UTC-7)' },
  { value: 'PST8PDT', label: 'Pacific (UTC-8)' },
  { value: 'Etc/GMT-9', label: 'Alaska (UTC-9)' },
  { value: 'HST', label: 'Hawaii (UTC-10)' }
];

interface InputErrors {
  calledTN: string;
  callingTN: string;
  time: string;
  url: string;
}

const AddRequest: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);

  const [incidentName, setIncidentName] = useState('');
  const [natureOfRequest, setNatureOfRequest] = useState<SelectOption | null>(null);
  const [incidentDescription, setIncidentDescription] = useState('');
  const [suspectedViolation, setSuspectedViolation] = useState('');
  const [calledTN, setCalledTN] = useState('');
  const [callingTN, setCallingTN] = useState('');
  const [callDate, setCallDate] = useState<string>('');
  const [live, setLive] = useState<SelectOption | null>(null);
  const [time, setTime] = useState('');
  const [timezone, setTimezone] = useState<SelectOption | null>(null);
  const [recordingType, setRecordingType] = useState<SelectOption | null>(null);
  const [calledPartyType, setCalledPartyType] = useState<SelectOption | null>(null);
  const [transcript, setTranscript] = useState('');
  const [audioURL, setAudioURL] = useState('');
  const [attachments, setAttachments] = useState<any[]>([]);
  const [acknowledge, setAcknowledge] = useState(false);
  const [requestor, setRequestor] = useState<SelectOption | null>(null);
  const [submitError, setSubmitError] = useState('');

  const errors = useMemo(
    (): InputErrors => ({
      calledTN:
        calledTN && !validatePhoneNumber(calledTN) ? 'Please provide a valid called number.' : '',
      callingTN:
        callingTN && !validatePhoneNumber(callingTN) ? 'Please provide a valid called number.' : '',
      time: time && !time.match(validTimeRegex) ? 'Please provide a valid call time' : '',
      url: audioURL && !isValidUrl(audioURL) ? 'Please provide a valid URL' : ''
    }),
    [calledTN, callingTN, time, audioURL]
  );

  const addFile = (files: File[]) => {
    setAttachments((v) => {
      return [...v, ...files.filter((file) => !v.find((item) => item.name === file.name))];
    });
    setAudioURL('');
  };

  const removeFile = (file: any) => {
    setAttachments((v) => v.filter((item) => item.name !== file.name));
  };

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();

    const { name, value } = event.currentTarget;

    switch (name) {
      case 'incidentName': {
        setIncidentName(value);
        break;
      }
      case 'incidentDescription': {
        setIncidentDescription(value);
        break;
      }
      case 'suspectedViolation': {
        setSuspectedViolation(value);
        break;
      }
      case 'calledTN': {
        setCalledTN(value);
        break;
      }
      case 'callingTN': {
        setCallingTN(value);
        break;
      }
      case 'time': {
        setTime(value);
        break;
      }
      case 'transcript': {
        setTranscript(value);
        break;
      }
      case 'audioURL': {
        setAudioURL(value);
        break;
      }
      default:
        break;
    }
  };

  const submit = async () => {
    if (errors.calledTN || errors.callingTN || errors.time || errors.url) return;

    const timeInfo = getTimeFromString(time);
    const now = new Date();
    const call = stringToDate(callDate) || now;
    const d = callDate
      ? convertToLocalTime(
          new Date(
            call.getFullYear(),
            call.getMonth(),
            call.getDate(),
            timeInfo.hours,
            timeInfo.minutes,
            timeInfo.seconds,
            0
          ),
          { timeZone: timezone?.value }
        )
      : now;

    const request: RequestIncident = {
      id: 0,
      incidentCampaignId: 0,
      fraudThreshold: false,
      telemarketingThreshold: false,
      createDate: new Date().toISOString(),
      updateDate: new Date().toISOString(),
      tracedBack: false,
      userId: 0,
      status: '',
      userEmail: '',
      isCertified: true,

      requestorId: requestor?.value,
      requestorName: requestor?.label || '',
      incidentCampaignName: incidentName,
      natureOfRequest: natureOfRequest?.value,
      description: incidentDescription,
      suspectedLegalViolation: suspectedViolation,
      callTime: d.toISOString(),
      destinationPhoneNumber: calledTN,
      originatorPhoneNumber: callingTN,
      live: live?.value,
      recordingType: recordingType?.value,
      calledPartyType: calledPartyType?.value,
      transcriptBody: transcript,
      audioUrl: audioURL
    };

    let formData = new FormData();

    if (attachments && attachments.length) {
      attachments.forEach((attachment) => {
        formData.append('file', attachment);
      });
    }
    dispatch(createRequest({ incident: request, content: formData })).then((data) => {
      const error = (data as any).error as SerializedError;
      if (!error || !error.message) {
        navigate('/requests');
      } else {
        setSubmitError(error.message);
      }
    });
  };
  return (
    <Fragment>
      <Breadcrumbs
        title="Requests"
        destination="requests"
        detail={`Request Traceback`}
        modify={''}
        isAdmin={user.roleType === userTypes.Admin}
      />
      <Card className="telecom-card">
        <CardHeader className="telecom-card-header">Request Traceback</CardHeader>
        <CardBody className="hop-detail-body">
          <Form className={'form-traceback traceback-add'}>
            <div className="d-flex flex-row justify-content-center">
              <InputFormGroup
                containerClassName="col-6 ms-4"
                label="Incident Name"
                inputPlaceholder="Incident Name"
                isRequired
                inputName="incidentName"
                inputId="incidentName"
                inputValue={incidentName}
                inputOnChange={handleInputChange}
              />
              <div className="col-6 ms-4">
                <label className="telecom-label">
                  Nature of Request
                  <i className="fa fa-asterisk asterisk" />
                </label>
                <CustomSelect
                  typeLabel={'Nature of Request'}
                  getOptions={natureOfRequestOptions}
                  selectedOption={natureOfRequest}
                  setSelectedOption={(item: OnChangeValue<SelectOption, boolean>): void => {
                    setNatureOfRequest(item as SelectOption);
                  }}
                />
              </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
              <InputFormGroup
                containerClassName="col-6 ms-4"
                label="Incident Description"
                inputPlaceholder="Please Provide As Much Detail As Available"
                isRequired
                isTextarea
                inputName="incidentDescription"
                inputId="incidentDescription"
                inputValue={incidentDescription}
                inputOnChange={handleInputChange}
              />
              <InputFormGroup
                containerClassName="col-6 ms-4"
                label="Suspected Legal Violation(s)"
                inputPlaceholder="Please Provide As Much Detail As Available"
                isRequired
                isTextarea
                inputName="suspectedViolation"
                inputId="suspectedViolation"
                inputValue={suspectedViolation}
                inputOnChange={handleInputChange}
              />
            </div>
            <h5 className="p-0 traceback-sub-title mb-3">Call Details</h5>
            <div className="d-flex flex-row justify-content-center">
              <div className="col-6 ms-4">
                <InputFormGroup
                  inputName="calledTN"
                  inputId="calledTN"
                  label={
                    <Fragment>
                      <span className="fa fa-phone text-red me-2" />
                      Called Number
                    </Fragment>
                  }
                  inputClassName="input-traceback"
                  inputValue={calledTN}
                  inputPlaceholder="2025558387"
                  inputOnChange={handleInputChange}
                  inputAutoComplete="off"
                  isRequired
                  errorMessage={errors.calledTN}
                />
              </div>
              <div className="col-6 ms-4">
                <InputFormGroup
                  inputName="callingTN"
                  inputId="callingTN"
                  label={
                    <Fragment>
                      <span className="fa fa-phone text-green me-2" />
                      Calling Number
                    </Fragment>
                  }
                  inputClassName="input-traceback"
                  inputValue={callingTN}
                  inputPlaceholder="6175553380"
                  inputOnChange={handleInputChange}
                  inputAutoComplete="off"
                  isRequired
                  errorMessage={errors.callingTN}
                />
              </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
              <div className="col-6 ms-4">
                <label className="telecom-label">
                  Call Date
                  <i className="fa fa-asterisk asterisk" />
                </label>
                <GeneralDatePicker
                  startDate={callDate}
                  setStartDate={setCallDate}
                  placeholderTextStart="&#xf133; mm/dd/yyyy"
                  onlyStartDate
                />
              </div>
              <div className="col-6 ms-4">
                <label className="telecom-label">
                  Live or Prerecorded/Artificial
                  <i className="fa fa-asterisk asterisk" />
                </label>
                <CustomSelect
                  typeLabel={'Live or Prerecorded/Artificial'}
                  getOptions={liveOrArtificialOptions}
                  selectedOption={live}
                  setSelectedOption={(item: OnChangeValue<SelectOption, boolean>): void => {
                    setLive(item as SelectOption);
                  }}
                />
              </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
              <div className="col-6 mt-2 ms-2">
                <div className="d-flex flex-row justify-content-center">
                  <div className="col-5">
                    <InputFormGroup
                      label="Time"
                      inputPlaceholder="hh:mm:ss"
                      isRequired
                      inputIcon="clock"
                      isAppend
                      inputName="time"
                      inputId="time"
                      inputValue={time}
                      inputOnChange={handleInputChange}
                      errorMessage={errors.time}
                    />
                  </div>
                  <div className="col-6 ms-2">
                    <label className="telecom-label">
                      Timezone
                      <i className="fa fa-asterisk asterisk" />
                    </label>
                    <CustomSelect
                      typeLabel={'Timezone'}
                      getOptions={timezoneList}
                      selectedOption={timezone}
                      setSelectedOption={(item: OnChangeValue<SelectOption, boolean>): void => {
                        setTimezone(item as SelectOption);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 ms-5 mt-2">
                <label className="telecom-label">
                  Recording Type
                  <i className="fa fa-asterisk asterisk" />
                </label>
                <CustomSelect
                  typeLabel={'Recording Type'}
                  getOptions={recordingTypeOptions}
                  selectedOption={recordingType}
                  setSelectedOption={(item: OnChangeValue<SelectOption, boolean>): void => {
                    setRecordingType(item as SelectOption);
                  }}
                />
              </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
              <div className="col-6 ms-4">
                <label className="telecom-label">
                  Called Party Type
                  <i className="fa fa-asterisk asterisk" />
                </label>
                <CustomSelect
                  typeLabel={'Called Party Type'}
                  getOptions={calledPartyTypeOptions}
                  selectedOption={calledPartyType}
                  setSelectedOption={(item: OnChangeValue<SelectOption, boolean>): void => {
                    setCalledPartyType(item as SelectOption);
                  }}
                />
              </div>
              <div className="col-6 ms-4">
                <label className="telecom-label">
                  Requestor
                  <i className="fa fa-asterisk asterisk" />
                </label>
                <RequestorSelect
                  isSearchable
                  isClearable
                  option={requestor}
                  onChange={(item: OnChangeValue<SelectOption, boolean>): void => {
                    setRequestor(item as SelectOption);
                  }}
                />
              </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
              <InputFormGroup
                containerClassName="col-6 ms-4 mt-1"
                label="Transcript, or Message Content Summary"
                inputPlaceholder="Please Provide As Much Detail As Available"
                isRequired
                isTextarea
                inputName="transcript"
                inputId="transcript"
                inputValue={transcript}
                inputOnChange={handleInputChange}
              />
              <div className="col-6 ms-4 mt-1">
                <label className="label-bold">Audio URL</label>
                <InputFormGroup
                  inputClassName="input-traceback"
                  inputAutoComplete="off"
                  inputPlaceholder="https://example.com/sample.wav"
                  isReadonly={!!attachments.length}
                  inputName="audioURL"
                  inputId="audioURL"
                  inputValue={audioURL}
                  inputOnChange={handleInputChange}
                  errorMessage={errors.url}
                />
                <DropZone
                  noPopover
                  audiofile
                  attachments={attachments}
                  addFile={addFile}
                  removeFile={removeFile}
                />
              </div>
            </div>{' '}
            <div className="d-flex flex-row justify-content-center text-center">
              <input
                checked={acknowledge}
                onChange={() => {
                  setAcknowledge((v) => !v);
                }}
                type="checkbox"
                className="ms-3"
                style={{ height: 20, width: 20 }}
              />
              <label
                className="telecom-label"
                style={{ width: '65%' }}
                onClick={() => {
                  setAcknowledge((v) => !v);
                }}
              >
                I certify and represent that I have direct knowledge that the requested tracebacks
                relate to calls that are suspected of being in violation of state and/or federal
                law, and that I am authorized to make this Traceback Request on behalf of the
                Requesting Organization. I also understand that the ITG does not and cannot
                guarantee that it will conduct tracebacks for any or all of the campaigns submitted
                on this Form.
              </label>
            </div>
          </Form>
          <div className="d-flex justify-content-center mt-4">
            <Button
              className="btn-default telecom-btn me-2"
              color="light"
              onClick={() => {
                navigate('/requests');
              }}
            >
              Cancel
            </Button>
            <Button
              className="btn-default telecom-btn"
              onClick={() => submit()}
              disabled={
                !(
                  incidentName &&
                  natureOfRequest &&
                  incidentDescription &&
                  suspectedViolation &&
                  calledTN &&
                  callingTN &&
                  callDate &&
                  live &&
                  time &&
                  timezone &&
                  recordingType &&
                  calledPartyType &&
                  transcript &&
                  acknowledge &&
                  requestor
                ) ||
                !!errors.calledTN ||
                !!errors.callingTN ||
                !!errors.time ||
                !!errors.url
              }
            >
              Submit
            </Button>
          </div>
          {submitError && (
            <InputError className="telecom-input-error text-center fs-5">{submitError}</InputError>
          )}
        </CardBody>
      </Card>

      <ZendeskWebWidget />
    </Fragment>
  );
};
export default AddRequest;
