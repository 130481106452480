import { StirShakenResponse } from './stirShaken';

export interface IncidentTableRow {
  id: number;
  campaignId: number;
  campaignLabel: string;
  tracebackId: number;
  tracedBack: boolean;
  tracebackError: string;
  callResult: string;
  callTime: string;
  presentedLabel: string;
  transcriptBody: string;
  audioUrl: string;
  originatorPhoneNumber: string;
  originatorCarrier: string;
  destinationPhoneNumber: string;
  destinationCarrier: string;
  createDate: string;
  originatorCnam: string;
  stirShaken: StirShakenResponse;
  doNotCall?: string;
  tracebackCampaignName?: string;
  dno: boolean;
  dnoType?: string;
  incidentCampaignId: number;
}
export const emptyIncidentTableRow = (): IncidentTableRow => ({
  id: 0,
  campaignId: 0,
  campaignLabel: '',
  tracebackId: 0,
  tracedBack: false,
  tracebackError: '',
  callResult: '',
  callTime: '',
  presentedLabel: '',
  transcriptBody: '',
  audioUrl: '',
  originatorPhoneNumber: '',
  originatorCarrier: '',
  destinationPhoneNumber: '',
  destinationCarrier: '',
  createDate: '',
  originatorCnam: '',
  stirShaken: {
    status: 0,
    objectId: 0,
    compact: false,
    token: '',
    attest: '',
    dest: {},
    hopID: 0,
    hopSequence: 0,
    hopCompletedDate: '',
    iat: '',
    orig: '',
    origid: '',
    errorMessage: null,
    isCallSigner: false,
    issuer: undefined,
    signer: undefined,
    create_date: '',
    isIssuerValid: false,
    certificateErrors: null,
    signerIsDomestic: false,
    signerIsITGMember: false
  },
  dno: false,
  incidentCampaignId: 0
});

export interface IncidentCampaignTableObject {
  maxWeekOffset: number;
  newIncidents: number;
  closedTracebackPercentage: number;
  incidentTableRows: IncidentCampaignTableRow[];
}

export interface IncidentCampaignSource {
  name: string;
  sourceId: number;
  count: number;
  providerId: number;
}

export interface IncidentCampaignTableRow {
  campaignId: number;
  sourceName: string;
  tracebackCampaignId: number;
  tracebackCampaignName: string;
  campaignLabel: string;
  incidentCampaignId: number;
  incidentLabel: string;
  today: number;
  todayMinusOne: number;
  todayMinusTwo: number;
  todayMinusThree: number;
  todayMinusFour: number;
  todayMinusFive: number;
  todayMinusSix: number;
  todayMinusSeven: number;
  todayMinusEight: number;
  total: number;
  firstTracebackDate: string;
  mostRecentTracebackDate: string;
  totalTracedBack: number;
  callType: string;
  newCampaignIncidentWeek: number;
  dncNumber: number;
  dnoNumber: number;
  numberOriginatorPhone: number;
  requestorId: number;
  requestorName: string;
}

export interface IncidentCampaignPut {
  id: number;
  tracebackCampaignId: number;
}

export interface IncidentPhoneNumbers {
  id: number;
  incidentCampaignId: number;
  originatorPhoneNumber: string;
  destinationPhoneNumber: string;
  callTime: string;
}

export interface ExistingIncidents {
  incidentId: number;
  existingIncidents: ExistingIncident[];
}
export interface ExistingIncident {
  existingIncidentId: number;
  tracebackId?: number;
}

export interface PostTracebackIncidentRequest {
  incidents: IncidentPhoneNumbers[];
  tracebackCampaignId?: number;
  requestorId?: number;
  tracebackProviderId?: number;
}
