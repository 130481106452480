import React, { Fragment, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { Button, CardHeader, Col } from 'reactstrap';
import { DateFormat } from '../../enum/DateFormat';
import { Attachment, Comment } from '../../interfaces/comment';
import { defaultPagination } from '../../interfaces/pagination';
import {
  Filter,
  FilterElement,
  addCondition,
  addConditionGroup,
  newLeafFilterElement,
  simplifyFilterElement
} from '../../lib/FilterElement';
import { decodeQueryParams, getFromLocalStorage } from '../../lib/history-utils';
import { getClientFormattedDate } from '../../lib/utilities';
import { clearCommentsList, downloadCommentList } from '../../redux/comment/thunks';
import ProviderPanel from '../ProviderPanel';
import CommentsTable, {
  getCommentsBasicFilter,
  saveCommentParams,
  setCommentsFiltersFromHistory
} from './commentsTable';
import GeneralDatePicker from '../GeneralDatePicker';
import ProvidersSelect from '../ProvidersSelect';

const PAGENAME = 'commentsNotifications';

const columns: TableColumn<Comment>[] = [
  {
    width: '30px'
  },
  {
    name: 'Hop',
    selector: () => 'relatedHopId',
    sortable: true,
    grow: 1,
    cell: (comment: Comment) =>
      comment.relatedHopActive ? (
        <div className="table-cell blue center">
          <a href={`/hops/hop/${comment.relatedHopId}`}>{comment.relatedHopId}</a>
        </div>
      ) : (
        <div className="table-cell gray center">
          <span>{comment.relatedHopId}</span>
        </div>
      )
  },
  {
    name: 'Traceback',
    selector: () => 'hopTracebackId',
    sortable: true,
    grow: 1,
    cell: (comment: Comment) => (
      <div className="table-cell blue center">
        <a href={`/tracebacks/traceback/${comment.hopTracebackId}`}>{comment.hopTracebackId}</a>
      </div>
    )
  },
  {
    name: 'Notification Date (UTC)',
    selector: () => 'create_date',
    id: 'column-create_date',
    sortable: true,
    width: '210px',
    cell: (comment: Comment) => (
      <div className="table-cell gray">
        <span>
          {comment.notificationDate
            ? getClientFormattedDate(comment.notificationDate, DateFormat.MediumBothNoUTC)
            : 'TBD'}
        </span>
      </div>
    )
  },
  {
    name: 'Recipient Email Addresses',
    selector: () => 'recipientEmail',
    id: 'column-recipientEmail',
    sortable: false,
    grow: 4,
    cell: (comment: Comment) => <span className="table-cell gray">{comment.recipientEmail}</span>
  },
  {
    name: 'Provider',
    selector: () => 'recipientProviderName',
    id: 'column-recipientProviderName',
    sortable: true,
    grow: 2,
    cell: (comment: Comment) => (
      <div className="table-cell blue">
        <ProviderPanel provider={comment.recipientProvider} hyperlink />
      </div>
    )
  },
  {
    name: 'Email Type',
    selector: () => 'emailType',
    id: 'column-emailType',
    sortable: true,
    grow: 2,
    cell: (comment: Comment) => <span className="table-cell gray">{comment.emailType}</span>
  },
  {
    name: 'Attachment',
    selector: () => 'isAttchmt',
    id: 'column-isAttchmt',
    sortable: false,
    grow: 1,
    cell: (comment: Comment) => (
      <div className={'table-cell center'}>
        {comment.attachments &&
          comment.attachments.length > 0 &&
          comment.attachments.map((attachment: Attachment, index) => (
            <Fragment key={`${comment.commentId}-attachment-${index}`}>
              <a
                style={{ fontWeight: 'bold' }}
                target="_blank"
                rel="noopener noreferrer"
                href={`/api/attachments/${attachment.unique_id}`}
              >
                <i className={'fa fa-paperclip'} />
              </a>
            </Fragment>
          ))}
      </div>
    )
  }
];

const getFilter = ({ startDate, endDate, providerId, emailType }: any): FilterElement => {
  let filterElements = getCommentsBasicFilter({ startDate, endDate }, 'notification');
  if (providerId) {
    addCondition(
      filterElements,
      newLeafFilterElement('recipientProviderId', 'EQ', providerId.toString())
    );
  }
  if (emailType && emailType.length > 0) {
    addConditionGroup(
      'OR',
      filterElements,
      emailType
        .map((item: string): Filter | undefined => {
          switch (item) {
            case 'includeITGMember':
              return { key: item, value: 'ITG Member' };
            case 'includeReminder':
              return { key: item, value: 'Reminder' };
            case 'includeFormal':
              return { key: item, value: 'Formal' };
            case 'includeCooperating':
              return { key: item, value: 'Cooperating' };
            case 'includeNonResponsive':
              return { key: item, value: 'Non-Responsive' };
          }
          return undefined;
        })
        .filter((item: Filter | undefined) => !!item)
    );
  }

  return simplifyFilterElement(filterElements);
};

interface IProps {
  clearCommentsList: Function;
  downloadCommentList: Function;
}

const NotificationComments: React.FC<IProps> = ({ clearCommentsList, downloadCommentList }) => {
  const { search } = useLocation();
  const searchParams = getFromLocalStorage(PAGENAME) || search || '';
  const savedSearchDetails = decodeQueryParams(PAGENAME, searchParams);
  const filtersFromHistory = setCommentsFiltersFromHistory(savedSearchDetails);
  const [startDate, setStartDate] = useState<string>(filtersFromHistory.startDate || '');
  const [endDate, setEndDate] = useState<string>(filtersFromHistory.endDate || '');
  const [providerId, setProviderId] = useState<number | undefined>(
    filtersFromHistory.recipientProviderId
  );
  const [emailType, setEmailType] = useState<string[]>(filtersFromHistory.emailType || []);
  const [filterElements, setFilterElements] = useState(
    getFilter({ startDate, endDate, providerId, emailType })
  );
  const [pagination, setPagination] = useState(
    savedSearchDetails.paginationParams || {
      ...defaultPagination(),
      sort: 'callDate',
      order: 'desc'
    }
  );

  const handleCheck = (e: any, key: string) => {
    switch (key) {
      case 'providerId':
        setProviderId(Number(e.value));
        break;
      case 'emailType':
        setEmailType(
          emailType.includes(e.target.value)
            ? emailType.filter((item) => item !== e.target.value)
            : [...emailType, e.target.value]
        );
        break;
    }
  };

  const updateFilter = () => {
    setFilterElements(getFilter({ startDate, endDate, providerId, emailType }));
    setPagination({
      ...pagination,
      page: 1
    });
  };

  const clearFilters = () => {
    setStartDate('');
    setEndDate('');
    setProviderId(undefined);
    setEmailType([]);
    clearCommentsList();
    saveCommentParams(PAGENAME, 2, getFilter({}), pagination);
  };

  const downloadEnabled = (): boolean => !!(providerId && startDate && endDate);
  const downloadData = () => {
    downloadCommentList(pagination, filterElements);
  };

  useEffect(() => {
    saveCommentParams(PAGENAME, 2, filterElements, pagination);
  }, [filterElements, pagination]);

  return (
    <Fragment>
      <CardHeader className="card-header-hops">
        <div className=" pb-3 d-flex align-items-center justify-content-start flex-wrap p-0">
          <GeneralDatePicker
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            placeholderTextStart="&#xf133; startDate"
            placeholderTextEnd="&#xf133; endDate"
            containerClassname="col-md-2 pe-2"
            clearButtonTitle={'Clear'}
            isClearable
          />
          <Col lg="2" md="4" className="pe-2">
            <ProvidersSelect
              option={providerId}
              onChange={(option) => handleCheck(option, 'providerId')}
              isSearchable={true}
              addAllItem={true}
              includeInactive
            />
          </Col>
          <Col
            lg="4"
            className="d-flex flex-wrap justify-content-lg-center"
            style={{ maxWidth: '33.938rem' }}
          >
            <label className="form-label checkbox-label">
              ITG Member
              <input
                type="checkbox"
                value="includeITGMember"
                checked={emailType.includes('includeITGMember')}
                onChange={(option) => handleCheck(option, 'emailType')}
              />
              <span className="checkmark" />
            </label>
            <label className="form-label checkbox-label">
              Reminder
              <input
                type="checkbox"
                value="includeReminder"
                checked={emailType.includes('includeReminder')}
                onChange={(option) => handleCheck(option, 'emailType')}
              />
              <span className="checkmark" />
            </label>
            <label className="form-label checkbox-label">
              Formal
              <input
                type="checkbox"
                value="includeFormal"
                checked={emailType.includes('includeFormal')}
                onChange={(option) => handleCheck(option, 'emailType')}
              />
              <span className="checkmark" />
            </label>
            <label className="form-label checkbox-label">
              Cooperating
              <input
                type="checkbox"
                value="includeCooperating"
                checked={emailType.includes('includeCooperating')}
                onChange={(option) => handleCheck(option, 'emailType')}
              />
              <span className="checkmark" />
            </label>
            <label className="form-label checkbox-label">
              Non-responsive
              <input
                type="checkbox"
                value="includeNonResponsive"
                checked={emailType.includes('includeNonResponsive')}
                onChange={(option) => handleCheck(option, 'emailType')}
              />
              <span className="checkmark" />
            </label>
          </Col>
          <Col lg="4">
            <Button color="primary" size="sm" onClick={() => updateFilter()}>
              <i className="fa fa-search pe-2" />
              Search
            </Button>
          </Col>
        </div>

        <div className="d-flex justify-content-start align-items-center flex-wrap  p-0">
          <Col xl="2" className="d-flex ps-1">
            <button type="button" className="btn btn-link" onClick={clearFilters}>
              Clear Filters
            </button>
          </Col>
          <Col xl="3" className="d-flex ps-1">
            <Button
              className="telecom-btn me-2"
              color="primary"
              style={{
                height: '28px',
                padding: '5px',
                fontSize: '14px',
                width: '150px'
              }}
              disabled={!downloadEnabled()}
              onClick={downloadData}
            >
              <i className="fa fa-download" />
              {` Download Zip`}
            </Button>
          </Col>
        </div>
      </CardHeader>
      <CommentsTable
        columns={columns}
        filterElements={filterElements}
        pagination={pagination}
        setPagination={setPagination}
      />
    </Fragment>
  );
};

const mapActionsToProps = { clearCommentsList, downloadCommentList };

export default connect(null, mapActionsToProps)(NotificationComments);
