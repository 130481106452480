import React, { Fragment, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Button, Col, Label } from 'reactstrap';
import { ReportFormat, ReportPostData, ReportType } from '../../../interfaces/report';
import { getApiFormattedDate, getUtcEndDate, getUtcStartDate } from '../../../lib/utilities';
import { getReportObject } from '../../../redux/report/thunks';
import { SelectOption } from '../../CustomSelect';
import { visibleIncidentSources } from '../Incidents/utilities';
import GeneralDatePicker from '../../GeneralDatePicker';

interface IProps {
  getReportObject: Function;
}

const PartnerSourceReport: React.FC<IProps> = ({ getReportObject }) => {
  const [startDate, setStartDate] = useState<string>(getUtcStartDate({ months: 1 }));
  const [endDate, setEndDate] = useState<string>(getUtcEndDate());
  const [selectedSource, setSelectedSource] = useState<SelectOption>();
  const sources = useMemo<SelectOption[]>(
    () => visibleIncidentSources.map((v) => ({ label: v.name, value: v.sourceId })),
    [visibleIncidentSources]
  );

  const getReport = () => {
    let reportPostData = {
      startDate: getApiFormattedDate(startDate),
      endDate: getApiFormattedDate(endDate),
      sourceId: selectedSource?.value,
      sourceName: selectedSource?.label
    } as ReportPostData;
    getReportObject(reportPostData, ReportType.PartnerSourceReport, ReportFormat.Excel);
  };

  const handleCheck = (e: any, key: string) => {
    switch (key) {
      case 'source':
        setSelectedSource(e);
        break;
      case 'excel':
        getReport();
        break;
    }
  };

  return (
    <Fragment>
      <Label className="telecom-label ps-0">Date Range *</Label>
      <div className="d-flex flex-row mb-4">
        <GeneralDatePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          placeholderTextStart="&#xf133; mm/dd/yyyy"
          placeholderTextEnd="&#xf133; mm/dd/yyyy"
          clearButtonTitle={'Clear'}
          isClearable
          reportStyle
        />
      </div>
      <Label className="telecom-label ps-0">Traceback Source Partner *</Label>
      <div className="ps-0 me-0 mb-4">
        <Col className={'me-0 ps-0 col-md-4'}>
          <Select
            className={'customselect-small'}
            classNamePrefix={'customselect'}
            options={sources}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0
            })}
            onChange={(option: any) => handleCheck(option, 'source')}
            isSearchable
            placeholder="Select a Source"
          />
        </Col>
      </div>
      <div className="m-4" style={{ borderBottom: '1px solid #ccc' }} />
      <div className="d-flex justify-content-md-center">
        <Button
          className="telecom-btn me-2"
          color="primary"
          style={{
            height: '28px',
            padding: '5px',
            fontSize: '14px',
            width: '150px'
          }}
          disabled={!(selectedSource && startDate && endDate)}
          onClick={(option) => handleCheck(option, 'excel')}
        >
          Create Report
        </Button>
      </div>
    </Fragment>
  );
};

const mapActionsToProps = {
  getReportObject
};

export default connect(null, mapActionsToProps)(PartnerSourceReport);
