import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { DateFormat } from '../enum/DateFormat';
import { StirShakenStatus } from '../enum/StirShaken';
import { userTypes } from '../enum/userTypes';
import { Hop } from '../interfaces/hop';
import { StirShakenInfo, StirShakenResponse } from '../interfaces/stirShaken';
import { Traceback } from '../interfaces/traceback';
import { PersonalInfo } from '../interfaces/user';
import { phoneNumberEqual } from '../lib/phoneNumber';
import {
  certificateExpiredError,
  generateStirShakenRequestObj,
  getClientFormattedDate,
  getStirShakenExplanation,
  stirshakenErrors
} from '../lib/utilities';
import {
  deleteStirShakenForHopApiCall,
  setIsCallSignerForStirShakenApiCall,
  updateStirShakenForHopApiCall
} from '../redux/hop/apiCalls';
import { getHopObject } from '../redux/hop/thunks';
import { stateMappings } from '../redux/stateMappings';
import { getTracebackObject } from '../redux/traceback/thunks';
import CustomToolTip from './CustomToolTip';
import StirShakenModal from './modals/StirShakenModal';
import NotSignedByOrigin from './stirShaken/NotSignedByOrigin';

interface IProps {
  stirShaken: StirShakenResponse;
  traceback: Traceback;
  hop?: Hop;
  user: PersonalInfo;
  getHopObject: Function;
  getTracebackObject: Function;
}

const dateEquals = (d1: string, d2: string) =>
  getClientFormattedDate(d1, DateFormat.LongBoth) ===
  getClientFormattedDate(d2, DateFormat.LongBoth);

interface StriShakenAdminButtonsProps {
  stirShaken: StirShakenResponse;
  setIsDeleteToggle: Function;
  setIsCallSigner: Function;
}
const StriShakenAdminButtons: React.FC<StriShakenAdminButtonsProps> = ({
  stirShaken,
  setIsDeleteToggle,
  setIsCallSigner
}) => (
  <Fragment>
    <Button
      color="primary"
      className="h-auto d-inline-block me-2 mb-2"
      onClick={() => setIsDeleteToggle()}
    >
      Delete STIR/SHAKEN
    </Button>
    {stirShaken && stirShaken.status === 1 && !stirShaken.isCallSigner && (
      <Button
        color="primary"
        className="h-auto d-inline-block me-2 mb-2"
        onClick={() => setIsCallSigner()}
      >
        Set Is Call Signer
      </Button>
    )}
  </Fragment>
);

const StirShakenRow: React.FC<IProps> = ({
  stirShaken,
  traceback,
  hop,
  user,
  getHopObject,
  getTracebackObject
}) => {
  const [stirShakenInfo, setStirShakenInfo] = useState<StirShakenInfo>({
    toggleStirShaken: false,
    stirShakenToken: '',
    signedStirShaken: null,
    attestationRadio: '',
    destinationNumber: '',
    callingNumber: '',
    timeStamp: '',
    originationIdentifier: '',
    isCallSigner: false
  });
  const [deleteToggle, setDeleteToggle] = useState(false);
  const toggleStirShakenModal = () => {
    setStirShakenInfo((v) => ({
      ...v,
      toggleStirShaken: !v.toggleStirShaken,
      signedStirShaken: null,
      attestationRadio: ''
    }));
  };

  const submitCallSourceToggle = async () => {
    const req = generateStirShakenRequestObj(stirShakenInfo);
    if (hop?.hopId && req?.token) {
      await updateStirShakenForHopApiCall(hop?.hopId, req);
      getHopObject(hop?.hopId);
    }
  };
  const submitDeleteToggle = async () => {
    if (traceback && stirShaken && stirShaken?.hopSequence && stirShaken?.hopID) {
      await deleteStirShakenForHopApiCall(stirShaken.hopID);
      getTracebackObject(traceback.tracebackId);
    } else if (hop?.hopId) {
      await deleteStirShakenForHopApiCall(hop?.hopId);
      getHopObject(hop?.hopId);
    }
    setIsDeleteToggle();
  };

  const setIsCallSigner = async () => {
    if (traceback && stirShaken && stirShaken?.hopSequence && stirShaken?.hopID) {
      await setIsCallSignerForStirShakenApiCall(stirShaken.hopID);
      getTracebackObject(traceback.tracebackId);
    } else if (hop?.hopId) {
      await setIsCallSignerForStirShakenApiCall(hop?.hopId);
      getHopObject(hop?.hopId);
    }
  };
  const setIsDeleteToggle = () => {
    setDeleteToggle((v) => !v);
  };

  const isIssuerNotValid = stirShaken && stirShaken.issuer && !stirShaken.isIssuerValid;
  return (
    <Fragment>
      {stirShaken ? (
        <Fragment>
          {!stirShaken.hopSequence && traceback && hop && hop.stirShakenFlag == 'orgNotSigned' && (
            <NotSignedByOrigin />
          )}
          {!stirShaken.signer ? (
            stirShaken.status === StirShakenStatus.NoStirShaken ? (
              <Fragment>
                <h6 className="p-0 mb-3 text-underline">
                  {stirShaken.hopSequence || hop?.hopSequence
                    ? `Hop ${stirShaken.hopSequence || hop?.hopSequence}:`
                    : 'Traceback:'}
                </h6>
                <span className="me-4 my-1">No Stir/Shaken data </span>
                {stirShaken && user.roleType === userTypes.Admin && (
                  <StriShakenAdminButtons
                    stirShaken={stirShaken}
                    setIsDeleteToggle={setIsDeleteToggle}
                    setIsCallSigner={setIsCallSigner}
                  />
                )}
              </Fragment>
            ) : (
              <Fragment>
                <h6 className="p-0 mb-3 text-underline">
                  {stirShaken.hopSequence || hop?.hopSequence
                    ? `Hop ${stirShaken.hopSequence || hop?.hopSequence}:`
                    : 'Traceback:'}
                </h6>
                <div className="mb-3 ps-3 justify-content-between custom-flex-column-768">
                  <div>
                    <Label className="p-0 telecom-label">Attestation</Label>
                    {stirShaken.attest}
                  </div>
                  <div>
                    <Label className="p-0 telecom-label">IAT</Label>
                    <span
                      className={`${
                        dateEquals(stirShaken.iat, traceback.tracebackTime)
                          ? 'table-cell-gray'
                          : 'span-cell red'
                      }`}
                    >
                      {getClientFormattedDate(stirShaken.iat, DateFormat.LongBoth)}
                    </span>
                  </div>
                  <div>
                    <Label className="p-0 telecom-label">Signed Carrier</Label>
                    {stirShaken.origid}
                  </div>
                  <div>
                    <Label className="p-0 telecom-label">Dest #</Label>
                    {stirShaken.dest.tn &&
                      stirShaken.dest.tn.map((item: any, index: any) => (
                        <span
                          key={index}
                          className={`${
                            phoneNumberEqual(
                              item,
                              hop?.forwardedCall ? hop.forwardedCall : traceback.calledTN
                            )
                              ? 'table-cell-gray'
                              : 'span-cell red'
                          }`}
                        >
                          {item}{' '}
                        </span>
                      ))}
                    {stirShaken.dest.uri &&
                      stirShaken.dest.uri.map((item: any, index: any) => (
                        <span
                          key={index}
                          className={`${
                            phoneNumberEqual(
                              item,
                              hop?.forwardedCall ? hop.forwardedCall : traceback.calledTN
                            )
                              ? 'table-cell-gray'
                              : 'span-cell red'
                          }`}
                        >
                          {item}{' '}
                        </span>
                      ))}
                  </div>
                  <div>
                    <Label className=" p-0 telecom-label">Orig #</Label>
                    <span
                      className={`${
                        phoneNumberEqual(stirShaken.orig, traceback.callingTN)
                          ? 'table-cell-gray'
                          : 'span-cell red'
                      }`}
                    >
                      {stirShaken.orig}
                    </span>
                  </div>
                  <div>
                    <Label className="p-0 telecom-label">Data Input</Label>
                    <span className="table-cell-gray">
                      {getStirShakenExplanation(stirShaken.status)}
                    </span>
                  </div>
                  {stirShaken.isCallSigner && (
                    <div>
                      <Label className="p-0 telecom-label">Call signer</Label>
                      <span className="table-cell-gray">Yes</span>
                    </div>
                  )}
                  <div>
                    <Label className="p-0 telecom-label">Date Added</Label>
                    <span
                      className={`${
                        (
                          stirShaken.hopCompletedDate
                            ? dateEquals(stirShaken.create_date, stirShaken.hopCompletedDate)
                            : hop
                              ? dateEquals(stirShaken.create_date, hop.completed)
                              : true
                        )
                          ? 'table-cell-gray'
                          : 'span-cell red'
                      }`}
                    >
                      {getClientFormattedDate(stirShaken.create_date, DateFormat.LongBoth)}
                    </span>
                  </div>
                </div>
              </Fragment>
            )
          ) : (
            <Fragment>
              <div className="d-flex">
                <h6 className="p-0 mb-3 text-underline">
                  {stirShaken.hopSequence || hop?.hopSequence
                    ? `Hop ${stirShaken.hopSequence || hop?.hopSequence}:`
                    : 'Traceback:'}
                </h6>
                {(hop
                  ? stirShaken.objectId !== hop?.hopId
                  : stirShaken.objectId !== stirShaken.hopID) && (
                  <h6 className="ms-1">
                    Identified as signer by a downstream provider, signing information below
                  </h6>
                )}
              </div>
              <div className="row mb-2">
                <div className="col-6 col-md-4 pb-3">
                  <div className="mb-1">
                    <Label className="p-0 telecom-label">Attestation</Label>
                    {stirShaken.attest}
                  </div>
                  <div className="mb-1">
                    <Label className="p-0 telecom-label">IAT</Label>
                    <span
                      className={`${
                        dateEquals(stirShaken.iat, traceback.tracebackTime)
                          ? 'table-cell-gray'
                          : 'span-cell red'
                      }`}
                    >
                      {getClientFormattedDate(stirShaken.iat, DateFormat.LongBoth)}
                    </span>
                  </div>
                  <div className="mb-1">
                    <Label className="p-0 telecom-label">Dest #</Label>
                    <span>
                      {stirShaken.dest.tn &&
                        stirShaken.dest.tn.map((item: any, index: any) => (
                          <span
                            key={index}
                            className={`${
                              phoneNumberEqual(
                                item,
                                hop?.forwardedCall ? hop.forwardedCall : traceback.calledTN
                              )
                                ? 'table-cell-gray'
                                : 'span-cell red'
                            }`}
                          >
                            {item}{' '}
                          </span>
                        ))}
                      {stirShaken.dest.uri &&
                        stirShaken.dest.uri.map((item: any, index: any) => (
                          <span
                            key={index}
                            className={`${
                              phoneNumberEqual(
                                item,
                                hop?.forwardedCall ? hop.forwardedCall : traceback.calledTN
                              )
                                ? 'table-cell-gray'
                                : 'span-cell red'
                            }`}
                          >
                            {item}{' '}
                          </span>
                        ))}
                    </span>
                  </div>
                  <div className="mb-1">
                    <Label className="p-0 telecom-label">Orig #</Label>
                    <span
                      className={`${
                        phoneNumberEqual(stirShaken.orig, traceback.callingTN)
                          ? 'table-cell-gray'
                          : 'span-cell red'
                      }`}
                    >
                      {stirShaken.orig}
                    </span>
                  </div>
                  <div className="mb-1">
                    <Label className=" p-0 telecom-label">Data Input</Label>
                    <span className="table-cell-gray">
                      {getStirShakenExplanation(stirShaken.status)}
                    </span>
                  </div>
                </div>
                <div className="col-6 col-md-4 pb-3">
                  <div className="mb-1">
                    <Label className="p-0 telecom-label">Signer Organization</Label>
                    {stirShaken.signer.organization.join(', ')}
                  </div>
                  <div className="mb-1">
                    <Label className="p-0 telecom-label">Signer Organization Unit</Label>
                    {stirShaken.signer.organizationUnit.join(', ')}
                  </div>
                  <div className="mb-1">
                    <Label className=" p-0 telecom-label">Signer Common Name</Label>
                    {stirShaken.signer.commonName}
                  </div>
                  <div className="mb-1">
                    <Label className="p-0 telecom-label">Signed Carrier</Label>
                    {stirShaken.signer &&
                    stirShaken.signer.organization &&
                    stirShaken.signer.organization.length > 0
                      ? stirShaken.signer.organization.join(', ')
                      : stirShaken.origid}
                  </div>
                  {stirShaken.isCallSigner && (
                    <div className="mb-1">
                      <Label className="p-0 telecom-label">Call signer</Label>
                      <span className="table-cell-gray">Yes</span>
                    </div>
                  )}
                </div>
                <div className="col-6 col-md-4 pb-3">
                  <div className="mb-1">
                    <Label className="p-0 telecom-label">Issuer Organization</Label>
                    <span className={isIssuerNotValid ? 'span-cell red' : 'table-cell-gray'}>
                      {stirShaken.issuer?.organization.join(', ')}
                    </span>
                  </div>
                  <div className="mb-1">
                    <Label className="p-0 telecom-label">Issuer Organization Unit</Label>
                    <span className={isIssuerNotValid ? 'span-cell red' : 'table-cell-gray'}>
                      {stirShaken.issuer?.organizationUnit.join(', ')}
                    </span>
                  </div>
                  <div className="mb-1">
                    <Label className="p-0 telecom-label">Issuer Common Name</Label>
                    <span className={isIssuerNotValid ? 'span-cell red' : 'table-cell-gray'}>
                      {stirShaken.issuer?.commonName}
                    </span>
                  </div>
                  {stirShaken.signerProviderName && (
                    <div className="mb-1">
                      <Label className="p-0 telecom-label">Signer</Label>
                      {user?.roleType === userTypes.Admin ? (
                        <a href={`providers/provider/${stirShaken.signerProviderId}`}>
                          {stirShaken.signerProviderName}
                        </a>
                      ) : (
                        `${stirShaken.signerProviderName}`
                      )}
                    </div>
                  )}
                  <div className="mb-1">
                    <Label className="p-0 telecom-label">Date Added</Label>
                    <span
                      className={`${
                        (
                          stirShaken.hopCompletedDate
                            ? dateEquals(stirShaken.create_date, stirShaken.hopCompletedDate)
                            : hop
                              ? dateEquals(stirShaken.create_date, hop.completed)
                              : true
                        )
                          ? 'table-cell-gray'
                          : 'span-cell red'
                      }`}
                    >
                      {getClientFormattedDate(stirShaken.create_date, DateFormat.LongBoth)}
                    </span>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </Fragment>
      ) : (
        <div className="d-inline">
          <span>No Stir/Shaken data Available </span>
        </div>
      )}

      {stirShaken && (stirShaken.hopSequence || hop?.hopSequence) && (
        <Fragment>
          <div className="row me-2 ms-2">
            {stirShaken &&
              (stirShaken.errorMessage &&
              stirshakenErrors(stirShaken.errorMessage, user.roleType, true).valid ? (
                <CustomToolTip
                  tooltipVisible
                  message={
                    stirshakenErrors(stirShaken.errorMessage, user.roleType, true).explanation
                  }
                >
                  <div className="stirShakenError text-dark">{stirShaken.errorMessage}</div>
                </CustomToolTip>
              ) : isIssuerNotValid &&
                !stirShaken.certificateErrors?.includes(certificateExpiredError) ? (
                <CustomToolTip
                  tooltipVisible
                  message="The issuer identified in the STIR/SHAKEN header is not an approved certification authority"
                >
                  <div className="stirShakenError text-dark">Issuer Not Approved</div>
                </CustomToolTip>
              ) : (
                stirShaken.certificateErrors && (
                  <CustomToolTip tooltipVisible message={stirShaken.certificateErrors}>
                    <div className="stirShakenError text-dark">
                      {stirShaken.certificateErrors.startsWith(
                        'x509: Unable to verify that the certificate is valid for time of call'
                      )
                        ? 'Unable to validate certificate'
                        : 'Certificate Error'}{' '}
                    </div>
                  </CustomToolTip>
                )
              ))}
          </div>

          {stirShaken &&
            stirShaken.status != StirShakenStatus.NoStirShaken &&
            (!hop || stirShaken.objectId === hop?.hopId) &&
            user.roleType === userTypes.Admin && (
              <StriShakenAdminButtons
                stirShaken={stirShaken}
                setIsDeleteToggle={setIsDeleteToggle}
                setIsCallSigner={setIsCallSigner}
              />
            )}
        </Fragment>
      )}

      {hop &&
        ((!stirShaken && hop?.status !== 1) ||
          (stirShaken && stirShaken.status === 0 && stirShaken.objectId !== hop?.hopId)) && (
          <span>
            <Button
              color="primary"
              className="h-auto d-inline-block ms-2 mb-3"
              onClick={() => toggleStirShakenModal()}
            >
              Add STIR/SHAKEN header
            </Button>
          </span>
        )}

      {!stirShaken && traceback && hop && hop.stirShakenFlag == 'orgNotSigned' && (
        <span className="ms-4 my-1">{<NotSignedByOrigin />}</span>
      )}

      <Modal
        centered
        isOpen={deleteToggle}
        className="submit-confirm-modal"
        toggle={setIsDeleteToggle}
      >
        <ModalHeader toggle={setIsDeleteToggle} />
        <ModalBody>
          <h5 className="traceback-sub-title">Are you sure you want to delete this?</h5>
        </ModalBody>
        <ModalFooter className="m-auto">
          <Row>
            <Button className="btn-default telecom-btn" color="light" onClick={setIsDeleteToggle}>
              Cancel
            </Button>
            <Button
              className="telecom-btn red"
              onClick={async () => {
                await submitDeleteToggle();
              }}
            >
              Proceed
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
      <StirShakenModal
        stirShakenInfo={stirShakenInfo}
        setStirShakenInfo={setStirShakenInfo}
        submitConfirmModalToggle={submitCallSourceToggle}
        forceStirShakenHeader
      />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    user: sm.user
  };
};

const mapActionsToProps = {
  getHopObject,
  getTracebackObject
};

export default connect(mapStateToProps, mapActionsToProps)(StirShakenRow);
