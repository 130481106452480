import React, { FC, Fragment, SetStateAction, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import {
  ExistingIncident,
  ExistingIncidents,
  IncidentCampaignTableRow,
  IncidentPhoneNumbers,
  IncidentTableRow,
  PostTracebackIncidentRequest
} from '../../../interfaces/incident';
import { Pagination, defaultPagination } from '../../../interfaces/pagination';
import { FilterElement, newLeafFilterElement } from '../../../lib/FilterElement';
import { checkInternationalPhoneNumber } from '../../../lib/phoneNumber';
import { checkDuplicatesBetweenSelected } from '../../../lib/utilities';
import {
  getDestinationCarrierOptionsList,
  getOriginatorCNamOptionsList,
  getOriginatorCarrierOptionsList
} from '../../../redux/appinfo/thunks';
import { checkExistingIncidentsApiCall } from '../../../redux/incident/apiCalls';
import {
  getIncidentCampaignSummaryObject,
  getIncidentsList,
  postTracebackIncidentList,
  updateIncidentCampaign
} from '../../../redux/incident/thunks';
import { stateMappings } from '../../../redux/stateMappings';
import CampaignsSelect from '../../CampaignsSelect';
import { SelectOption } from '../../CustomSelect';
import GeneralDatePicker from '../../GeneralDatePicker';
import RequestorPopover from '../../shared/RequestorPopover';
import IncidentCampaignsSearchTable from './IncidentCampaignsSearchTable';
import { getIncidentCampaignSearchFilter } from './utilities';

interface IProps {
  selectedIncidentCampaign: IncidentCampaignTableRow;
  incidentsMap: any;
  originatorCnamOptions: string[];
  originatorCarrierOptions: string[];
  destinationCarrierOptions: string[];
  getIncidentCampaignSummaryObject: Function;
  updateIncidentCampaign: Function;
  postTracebackIncidentList: Function;
  getIncidentsList: Function;
  getOriginatorCNamOptionsList: Function;
  getOriginatorCarrierOptionsList: Function;
  getDestinationCarrierOptionsList: Function;
}

const asSelectOption = (entry: string): SelectOption => ({ value: entry, label: entry });

const IncidentCampaignsSearch: FC<IProps> = ({
  incidentsMap,
  selectedIncidentCampaign,
  originatorCnamOptions,
  originatorCarrierOptions,
  destinationCarrierOptions,
  getIncidentCampaignSummaryObject,
  updateIncidentCampaign,
  postTracebackIncidentList,
  getIncidentsList,
  getOriginatorCNamOptionsList,
  getOriginatorCarrierOptionsList,
  getDestinationCarrierOptionsList
}) => {
  const [selectedTracebackCampaign, setSelectedTracebackCampaign] = useState<SelectOption | null>({
    label: selectedIncidentCampaign.tracebackCampaignName,
    value: selectedIncidentCampaign.tracebackCampaignId
  });
  const [selectedRequestor, setSelectedRequestor] = useState<SelectOption | null>({
    label: selectedIncidentCampaign.requestorName,
    value: selectedIncidentCampaign.requestorId
  });
  const [showChange, setShowChange] = useState(false);
  const [incidents, setIncidents] = useState<IncidentTableRow[]>([]);
  const [tracebackCampaignId, setTracebackCampaignId] = useState<number | null>(null);
  const [requestorId, setRequestorId] = useState<SelectOption | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [callerTnValue, setCallerTnValue] = useState('');
  const [selectedCallerId, setSelectedCallerId] = useState('');
  const [selectedCallerCarrier, setSelectedCallerCarrier] = useState('');
  const [callDateValue, setCallDateValue] = useState<string>('');
  const [callTimeValue, setCallTimeValue] = useState<string>(''); // this doesn't work on current version
  const [calledTnValue, setCalledTnValue] = useState('');
  const [selectedCalledCarrier, setSelectedCalledCarrier] = useState('');
  const [transcriptFilter, setTranscriptFilter] = useState('');
  const [voiceMailValue, setVoiceMailValue] = useState(false);
  const [tracedBackValue, setTracedBackValue] = useState(false);
  const [selected, setSelected] = useState<IncidentPhoneNumbers[]>([]);
  const [dnc, setDnc] = useState(false);
  const [dno, setDno] = useState(false);
  const [pagination, setPagination] = useState<Pagination>({
    ...defaultPagination(),
    sort: 'id',
    order: 'desc'
  });
  const [differentCampaign, setDifferentCampaign] = useState(false);
  const [differentRequestor, setDifferentRequestor] = useState(false);

  const [filter, setFilter] = useState<FilterElement>(
    getIncidentCampaignSearchFilter(
      selectedIncidentCampaign.incidentCampaignId,
      selectedCallerId,
      selectedCallerCarrier,
      selectedCalledCarrier,
      callerTnValue,
      calledTnValue,
      voiceMailValue,
      tracedBackValue,
      transcriptFilter,
      callDateValue,
      callTimeValue,
      dnc,
      dno
    )
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [existingIncidentModal, setExistingIncidentModal] = useState(false);
  const [existingIncidents, setExistIncidents] = useState<ExistingIncidents[]>([]);
  const [selectedIncident, setSelectedIncident] = useState<PostTracebackIncidentRequest | null>(
    null
  );
  const [selectedDuplicates, setSelectedDuplicates] = useState<string[]>([]);

  useEffect(() => {
    getOriginatorCNamOptionsList(selectedIncidentCampaign.incidentCampaignId);
    getOriginatorCarrierOptionsList(selectedIncidentCampaign.incidentCampaignId);
    getDestinationCarrierOptionsList(selectedIncidentCampaign.incidentCampaignId);
  }, []);

  useEffect(() => {
    getIncidentsList(pagination, filter, selectedIncidentCampaign.incidentCampaignId);
    window.scrollTo(0, 0);
  }, [pagination, filter]);

  const toggleAll = (state: boolean) => {
    let newSelected: IncidentPhoneNumbers[] = [];
    if (incidents && incidents.length > 0 && state) {
      newSelected = incidents.map(
        (incident): IncidentPhoneNumbers => ({
          id: incident.id,
          incidentCampaignId: incident.incidentCampaignId,
          originatorPhoneNumber: incident.originatorPhoneNumber,
          destinationPhoneNumber: incident.destinationPhoneNumber,
          callTime: incident.callTime
        })
      );
    }
    setSelected(newSelected);
  };

  useEffect(() => {
    if (incidentsMap.size)
      setIncidents(incidentsMap.get(selectedIncidentCampaign.incidentCampaignId));
    toggleAll(false);
  }, [incidentsMap]);

  useEffect(() => {
    if (!differentCampaign) setTracebackCampaignId(null);
  }, [differentCampaign]);
  useEffect(() => {
    if (!differentRequestor) setRequestorId(null);
  }, [differentRequestor]);

  const handleSelectedTracebackCampaignChange = (event: any) => {
    setSelectedTracebackCampaign(event);
  };

  const handleSave = async (event: any) => {
    event.preventDefault();
    const res = await updateIncidentCampaign(
      selectedIncidentCampaign.incidentCampaignId,
      selectedTracebackCampaign?.value,
      selectedRequestor?.value
    );
    if (res === true) {
      getIncidentCampaignSummaryObject(selectedIncidentCampaign.incidentCampaignId);
      setShowChange(true);
      setEditMode(false);
    }
  };
  const verifyIncidentsInternationPhoneNumbers = () => {
    return selected.filter(
      (value: IncidentPhoneNumbers) =>
        checkInternationalPhoneNumber(value.destinationPhoneNumber || '') ||
        checkInternationalPhoneNumber(value.originatorPhoneNumber || '')
    );
  };

  const toggleRow = (
    id: number,
    incidentCampaignId: number,
    originatorPhoneNumber: string,
    destinationPhoneNumber: string,
    callTime: string
  ) => {
    if (selected.find((value) => value.id === id))
      setSelected(selected.filter((value) => value.id !== id));
    else
      setSelected([
        ...selected,
        { id, originatorPhoneNumber, destinationPhoneNumber, callTime, incidentCampaignId }
      ]);
  };

  const toggleDifferentCampaign = () => {
    setDifferentCampaign((value) => !value);
  };
  const toggleDifferentRequestor = () => {
    setDifferentRequestor((value) => !value);
  };

  const updateFilter = (deselect?: boolean) => {
    setPagination({ ...pagination, page: 1 });
    setFilter(
      getIncidentCampaignSearchFilter(
        selectedIncidentCampaign.incidentCampaignId,
        selectedCallerId,
        selectedCallerCarrier,
        selectedCalledCarrier,
        callerTnValue,
        calledTnValue,
        voiceMailValue,
        tracedBackValue,
        transcriptFilter,
        callDateValue,
        callTimeValue,
        dnc,
        dno
      )
    );
    if (deselect) toggleAll(false);
  };

  const prepareSelectedRequest = (): PostTracebackIncidentRequest => ({
    incidents: selected,
    tracebackCampaignId: tracebackCampaignId || undefined,
    requestorId: requestorId?.value
  });

  const handleAddToTracebacks = (data: PostTracebackIncidentRequest) => {
    if (
      selectedIncidentCampaign.campaignId &&
      selectedIncidentCampaign.campaignId > 0 &&
      data.incidents.length > 0
    ) {
      postTracebackIncidentList(data).then(() => {
        updateFilter(true);
      });
      if (selectedIncident) setSelectedIncident(null);
    }
  };

  const handleClearFilter = (event: any) => {
    event.preventDefault();

    setSelectedCallerId('');
    setSelectedCallerCarrier('');
    setSelectedCalledCarrier('');
    setCallDateValue('');
    setCallTimeValue('');
    setCallerTnValue('');
    setCalledTnValue('');
    setVoiceMailValue(false);
    setTracedBackValue(false);
    setDnc(false);
    setDno(false);
    setTranscriptFilter('');
    setPagination({ ...pagination, page: 1 });
    setFilter(
      newLeafFilterElement('id', 'EQ', String(selectedIncidentCampaign.incidentCampaignId))
    );
  };

  const toggleEditMode = () => {
    if (editMode) {
      setShowChange(false);
      setSelectedTracebackCampaign({
        label: selectedIncidentCampaign.tracebackCampaignName,
        value: selectedIncidentCampaign.tracebackCampaignId
      });
      setSelectedRequestor({
        label: selectedIncidentCampaign.requestorName,
        value: selectedIncidentCampaign.requestorId
      });
    }
    setEditMode(!editMode);
  };

  const toggleExistingIncidentModal = () => {
    setExistingIncidentModal((v) => !v);
    if (selectedIncident) setSelectedIncident(null);
  };
  const createTracebacks = async () => {
    const checkData = selectedIncident ? selectedIncident.incidents : selected;
    try {
      const {
        data: { data }
      } = await checkExistingIncidentsApiCall(checkData);
      let duplicates: string[] = [];
      if (!selectedIncident) {
        duplicates = checkDuplicatesBetweenSelected(checkData);
      }
      if ((data && data.length > 0) || duplicates.length) {
        setSelectedDuplicates(duplicates);
        setExistIncidents(data || []);
        setExistingIncidentModal(true);
      } else if (selectedIncident) {
        handleAddToTracebacks(selectedIncident);
      } else
        verifyIncidentsInternationPhoneNumbers().length
          ? setIsModalOpen(true)
          : handleAddToTracebacks(prepareSelectedRequest());
    } catch (error) {}
  };

  useEffect(() => {
    if (selectedIncident) createTracebacks();
  }, [selectedIncident]);

  return (
    <Fragment>
      <div className="mb-1 d-flex justify-content-start">
        <h1 className="incident-campaign-header ms-4">{selectedIncidentCampaign.campaignLabel}</h1>
        <div className="incident-campaign-subheader mt-1 d-flex flex-row">
          <div className="ms-4 d-flex flex-column">
            <span>
              <span className="incident-campaign-subheader-label">Traceback Campaign: </span>{' '}
              {selectedIncidentCampaign.tracebackCampaignName &&
              selectedIncidentCampaign.tracebackCampaignName.length > 0
                ? selectedIncidentCampaign.tracebackCampaignName
                : showChange && selectedTracebackCampaign && !!selectedTracebackCampaign.label
                  ? selectedTracebackCampaign.label
                  : 'Not mapped'}
            </span>
            {editMode && (
              <div className="d-flex flex-row align-items-center pt-2">
                <span>Choose campaign: </span>
                <div className="mx-2" style={{ minWidth: '176px' }}>
                  <CampaignsSelect
                    value={selectedTracebackCampaign}
                    onChange={handleSelectedTracebackCampaignChange}
                    selectClass="customselect-large"
                    placeholder="Select..."
                  />
                </div>
              </div>
            )}
          </div>
          <div className="ms-4 d-flex flex-column">
            <span>
              <span className="incident-campaign-subheader-label">Traceback Requestor: </span>{' '}
              {selectedIncidentCampaign.requestorName &&
              selectedIncidentCampaign.requestorName.length > 0
                ? selectedIncidentCampaign.requestorName
                : showChange && selectedRequestor && !!selectedRequestor.label
                  ? selectedRequestor.label
                  : 'Not mapped'}
            </span>
            {editMode && (
              <div className="d-flex flex-row align-items-center ">
                <RequestorPopover
                  values={!selectedRequestor?.value ? [] : [selectedRequestor]}
                  setValues={(value: SetStateAction<SelectOption[]>) => {
                    if (typeof value === 'function') {
                      setSelectedRequestor((v) => {
                        const vals = value(!v?.value ? [] : [v]);
                        return vals && vals.length
                          ? {
                              value: vals && vals.length ? vals[0].value : 0,
                              label: vals && vals.length ? vals[0].label : ''
                            }
                          : null;
                      });
                    } else {
                      setSelectedRequestor(
                        value && value.length
                          ? {
                              value: value && value.length ? value[0].value : 0,
                              label: value && value.length ? value[0].label : ''
                            }
                          : null
                      );
                    }
                  }}
                  radioType={true}
                  popoverHeaderMessage="Select requestor"
                />
              </div>
            )}
          </div>
          <div className="ms-2 d-flex flex-column justify-content-between">
            <span
              className="fa fa-pencil "
              style={{ cursor: 'pointer' }}
              onClick={toggleEditMode}
            />
            {editMode && (
              <Button color="primary mb-2" onClick={handleSave}>
                Save
              </Button>
            )}
          </div>
          {selectedIncidentCampaign.callType && selectedIncidentCampaign.callType.length > 0 && (
            <span className="ms-4">
              <span className="incident-campaign-subheader-label">Call Type: </span>
              {selectedIncidentCampaign.callType}
            </span>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-2">
          <label className="incident-campaign-filter-label">Caller TN</label>
        </div>
        <div className="col-md-2">
          <label className="incident-campaign-filter-label">CallerID</label>
        </div>
        <div className="col-md-2">
          <label className="incident-campaign-filter-label">Caller Carrier</label>
        </div>
        <div className="col-md-2">
          <label className="incident-campaign-filter-label">Call Date</label>
        </div>
        <div className="col-md-2">
          {callDateValue && <label className="incident-campaign-filter-label">Call Time</label>}
        </div>
      </div>
      <div className="row">
        <div className="col-md-2">
          <FormGroup>
            <div className="input-group">
              <input
                onChange={(event: React.FormEvent<HTMLInputElement>) => {
                  event.preventDefault();
                  setCallerTnValue(event.currentTarget.value);
                }}
                className="input-comment form-control"
                name="callerTn"
                id="callerTn"
                value={callerTnValue}
              />
            </div>
          </FormGroup>
        </div>
        <div className="col-md-2">
          <FormGroup>
            <Select
              className="customselect-small"
              classNamePrefix="customselect"
              value={asSelectOption(selectedCallerId)}
              options={originatorCnamOptions.map(asSelectOption)}
              onChange={(e: any) => {
                setSelectedCallerId(e.value);
              }}
              isSearchable={false}
            />
          </FormGroup>
        </div>
        <div className="col-md-2">
          <FormGroup>
            <Select
              className="customselect-small"
              classNamePrefix="customselect"
              value={asSelectOption(selectedCallerCarrier)}
              options={originatorCarrierOptions.map(asSelectOption)}
              onChange={(e: any) => {
                setSelectedCallerCarrier(e.value);
              }}
              isSearchable={false}
            />
          </FormGroup>
        </div>
        <GeneralDatePicker
          startDate={callDateValue}
          setStartDate={setCallDateValue}
          placeholderTextStart="&#xf133; mm/dd/yyyy"
          containerClassname="col-md-2"
          onlyStartDate
        />
        <div className="col-md-2">
          {callDateValue && (
            <Fragment>
              <input
                type="time"
                className="input-comment form-control"
                onChange={(e: any) => {
                  setCallTimeValue(e.currentTarget.value);
                }}
              />
            </Fragment>
          )}
        </div>
      </div>
      <Row>
        <Col md="2">
          <label className="incident-campaign-filter-label">Called TN</label>
          <FormGroup>
            <div className="input-group">
              <input
                onChange={(event: React.FormEvent<HTMLInputElement>) => {
                  event.preventDefault();
                  setCalledTnValue(event.currentTarget.value);
                }}
                value={calledTnValue}
                className="input-comment form-control"
                name="calledTn"
                id="calledTn"
              />
            </div>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <label className="incident-campaign-filter-label">Called Carrier</label>
            <Select
              className="customselect-small"
              classNamePrefix="customselect"
              value={asSelectOption(selectedCalledCarrier)}
              options={destinationCarrierOptions.map(asSelectOption)}
              onChange={(e: any) => {
                setSelectedCalledCarrier(e.value);
              }}
              isSearchable={false}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <label className="incident-campaign-filter-label">Transcript Body</label>
          <FormGroup>
            <div className="input-group">
              <input
                onChange={(event: React.FormEvent<HTMLInputElement>) => {
                  event.preventDefault();
                  setTranscriptFilter(event.currentTarget.value);
                }}
                value={transcriptFilter}
                className="input-comment form-control"
                placeholder="Search Transcript Body"
                name="transcript-filter"
                id="transcript-filter"
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="2">
          <label className="form-label tile-label" style={{ fontSize: '15px' }}>
            Voicemail
            <input
              type="checkbox"
              onChange={(e) => {
                setVoiceMailValue(e.currentTarget.checked);
              }}
              checked={voiceMailValue}
            />
            <span className="checkmark tile-checkmark-incidents" />
          </label>
        </Col>
        <Col md="2">
          <label className="form-label tile-label" style={{ fontSize: '15px' }}>
            Traceback Created
            <input
              type="checkbox"
              onChange={(e) => {
                setTracedBackValue(e.currentTarget.checked);
              }}
              checked={tracedBackValue}
            />
            <span className="checkmark tile-checkmark-incidents" />
          </label>
        </Col>
        <Col md="2">
          <label className="form-label tile-label" style={{ fontSize: '15px' }}>
            DNC
            <input
              type="checkbox"
              onChange={(e) => {
                setDnc(e.currentTarget.checked);
              }}
              checked={dnc}
            />
            <span className="checkmark tile-checkmark-incidents" />
          </label>
        </Col>
        <Col md="2">
          <label className="form-label tile-label" style={{ fontSize: '15px' }}>
            DNO
            <input
              type="checkbox"
              onChange={(e) => {
                setDno(e.currentTarget.checked);
              }}
              checked={dno}
            />
            <span className="checkmark tile-checkmark-incidents" />
          </label>
        </Col>
      </Row>

      <Row className="mb-2 mt-4 custom-flex-column-1200">
        <div className="d-flex col-lg-10 justify-content-xl-start align-items-xl-center custom-flex-column-1200">
          {selectedIncidentCampaign.campaignId && selectedIncidentCampaign.campaignId > 0 && (
            <Button
              color="primary"
              className="ms-2"
              size="sm"
              onClick={() =>
                incidents && selected && toggleAll(selected.length !== incidents.length)
              }
            >
              {incidents && selected && selected.length === incidents.length
                ? 'De-Select All'
                : 'Select All'}
            </Button>
          )}
          <Button
            className="ms-2"
            size="sm"
            color="primary"
            disabled={
              !(selectedIncidentCampaign.campaignId && selectedIncidentCampaign.campaignId > 0) &&
              selected.length == 0
            }
            onClick={async () => await createTracebacks()}
          >
            Add to Tracebacks
          </Button>
          {differentCampaign && (
            <div style={{ minWidth: '15rem' }} className="ps-2">
              <CampaignsSelect
                value={tracebackCampaignId}
                onChange={(e: any) => setTracebackCampaignId(e?.value || null)}
                placeholder="Select Campaign"
                isClearable
              />
            </div>
          )}

          <label className="form-label tile-label ms-2" style={{ fontSize: '15px' }}>
            Select a Different Campaign
            <input
              type="checkbox"
              onChange={(e) => {
                toggleDifferentCampaign();
              }}
              checked={differentCampaign}
            />
            <span className="checkmark tile-checkmark-incidents" />
          </label>
          {differentRequestor && (
            <div
              style={{ minWidth: '15rem' }}
              className="ps-2 d-flex flex-row justify-content-end align-items-center"
            >
              <RequestorPopover
                values={requestorId ? [requestorId] : []}
                setValues={(value: SetStateAction<SelectOption[]>) => {
                  if (typeof value === 'function') {
                    setRequestorId((v) => {
                      const vals = value(!v?.value ? [] : [v]);
                      return vals && vals.length
                        ? {
                            value: vals && vals.length ? vals[0].value : 0,
                            label: vals && vals.length ? vals[0].label : ''
                          }
                        : null;
                    });
                  } else {
                    setRequestorId(
                      value && value.length
                        ? {
                            value: value && value.length ? value[0].value : 0,
                            label: value && value.length ? value[0].label : ''
                          }
                        : null
                    );
                  }
                }}
                popoverHeaderMessage={'Select a requestor'}
                radioType={true}
              />
            </div>
          )}

          <label className="form-label tile-label ms-2" style={{ fontSize: '15px' }}>
            Select a Different Requestor
            <input
              type="checkbox"
              onChange={(e) => {
                toggleDifferentRequestor();
              }}
              checked={differentRequestor}
            />
            <span className="checkmark tile-checkmark-incidents" />
          </label>
        </div>

        <div className="d-flex col-xl-2 justify-content-xl-end align-items-center">
          <a href="#" className="me-3" onClick={handleClearFilter}>
            Clear Filters
          </a>
          <Button
            size="sm"
            color="primary"
            onClick={() => {
              updateFilter();
            }}
          >
            Search
          </Button>
        </div>
      </Row>

      <IncidentCampaignsSearchTable
        data={selectedIncidentCampaign}
        pagination={pagination}
        setPagination={setPagination}
        selected={selected}
        toggleRow={toggleRow}
        campaignSelected={selectedIncidentCampaign.campaignId > 0}
        setSelectedIncident={setSelectedIncident}
      />
      <Modal
        className="modal-template"
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen((v) => !v)}
        centered
      >
        <ModalHeader>
          <p>There is a list of incidents that have international phone numbers:</p>
        </ModalHeader>
        <ModalBody style={{ overflow: 'scroll', maxHeight: '400px' }}>
          <ul>
            {verifyIncidentsInternationPhoneNumbers().map((value) => (
              <li>
                Incident {value.id}{' '}
                <ul>
                  {checkInternationalPhoneNumber(value.destinationPhoneNumber) && (
                    <li>Destination phone number: {value.destinationPhoneNumber}</li>
                  )}
                  {checkInternationalPhoneNumber(value.originatorPhoneNumber) && (
                    <li>Originator phone number: {value.originatorPhoneNumber}</li>
                  )}
                </ul>
              </li>
            ))}
          </ul>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <div className="d-flex">
            <Button
              className="btn-default telecom-btn me-2"
              color="light"
              onClick={() => setIsModalOpen((v) => !v)}
            >
              Cancel
            </Button>
            <Button
              className="telecom-btn ms-2"
              onClick={() => {
                setIsModalOpen((v) => !v);
                handleAddToTracebacks(prepareSelectedRequest());
              }}
            >
              Add to traceback
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        className="modal-template"
        isOpen={existingIncidentModal}
        toggle={() => toggleExistingIncidentModal()}
        centered
      >
        <ModalBody style={{ overflow: 'scroll', maxHeight: '500px' }}>
          <ul>
            {existingIncidents.map((value) => (
              <li>
                Incident #{value.incidentId} already exists:
                {value.existingIncidents.map((incident: ExistingIncident) => (
                  <ul>
                    {`incident #${incident.existingIncidentId} with traceback `}
                    <a href={`tracebacks/traceback/${incident.tracebackId}`}>
                      {incident?.tracebackId}
                    </a>
                  </ul>
                ))}
              </li>
            ))}
          </ul>
          <ul>
            {selectedDuplicates.map((s: string) => (
              <li>{`Incidents ${s} are duplicates.`}</li>
            ))}
          </ul>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <div className="d-flex">
            <Button
              className="btn-default telecom-btn"
              color="light"
              onClick={() => {
                toggleExistingIncidentModal();
              }}
            >
              Cancel
            </Button>
            <Button
              className="telecom-btn ms-2"
              onClick={() => {
                setExistingIncidentModal((v) => !v);
                if (selectedIncident) {
                  handleAddToTracebacks(selectedIncident);
                } else
                  verifyIncidentsInternationPhoneNumbers().length
                    ? setIsModalOpen(true)
                    : handleAddToTracebacks(prepareSelectedRequest());
              }}
            >
              Submit Anyway
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    incidentsMap: new Map<number, IncidentCampaignTableRow[]>(sm.incident.incidents),
    originatorCnamOptions: sm.appinfo.originatorCNamOptions || [],
    originatorCarrierOptions: sm.appinfo.originatorCarrierOptions || [],
    destinationCarrierOptions: sm.appinfo.destinationCarrierOptions || []
  };
};

const mapActionsToProps = {
  getIncidentsList,
  getOriginatorCNamOptionsList,
  getOriginatorCarrierOptionsList,
  getDestinationCarrierOptionsList,
  postTracebackIncidentList,
  getIncidentCampaignSummaryObject,
  updateIncidentCampaign
};

export default connect(mapStateToProps, mapActionsToProps)(IncidentCampaignsSearch);
