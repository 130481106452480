import { AppInfo } from './actionEnum';

import { emptyConfiguration } from '../../interfaces/configuration';

const defaultState: any = {
  configuration: emptyConfiguration(),
  loading: 0,
  error: ''
};

const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case AppInfo.GET_CLIENT_CONFIGURATION:
      return { ...state, configuration: action.payload.data };

    case AppInfo.GET_REPUTATION_INFO:
      return {
        ...state,
        reputationCategories: action.payload.data,
        reputationColors: action.payload.data
      };

    case AppInfo.GET_CAMPAIGN_NAMES:
      return { ...state, campaignNames: action.payload.data };

    case AppInfo.GET_USER_NAMES:
      return { ...state, userNames: action.payload.data };

    case AppInfo.GET_ORIGINATOR_CNAM_OPTIONS:
      return { ...state, originatorCNamOptions: action.payload.data };

    case AppInfo.GET_ORIGINATOR_CARRIER_OPTIONS:
      return { ...state, originatorCarrierOptions: action.payload.data };

    case AppInfo.GET_DESTINATION_CARRIER_OPTIONS:
      return { ...state, destinationCarrierOptions: action.payload.data };

    case AppInfo.GET_CRONJOBS_STATS:
      return { ...state, cronJobsStats: action.payload.data };
    case AppInfo.SET_APPINFO_LOADING_STATUS:
      if (action.payload) return { ...state, loading: state.loading + 1 };
      else return { ...state, loading: state.loading === 0 ? state.loading : state.loading - 1 };

    case AppInfo.SET_APPINFO__ERROR_MESSAGE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};

export default reducer;
