import React, { Fragment, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { DebounceInput } from 'react-debounce-input';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { Button, CardHeader, Col, Row } from 'reactstrap';
import { DateFormat } from '../../enum/DateFormat';
import { userTypes } from '../../enum/userTypes';
import { Attachment, Comment, NotificationNewComment } from '../../interfaces/comment';
import { defaultPagination } from '../../interfaces/pagination';
import { PersonalInfo } from '../../interfaces/user';
import {
  FilterElement,
  addCondition,
  newConditionalFilterElement,
  newLeafFilterElement,
  simplifyFilterElement
} from '../../lib/FilterElement';
import { decodeQueryParams, getFromLocalStorage } from '../../lib/history-utils';
import { getClientFormattedDate } from '../../lib/utilities';
import { clearCommentsList, getHopsNotifications } from '../../redux/comment/thunks';
import { deleteNotification } from '../../redux/notification/thunks';
import { stateMappings } from '../../redux/stateMappings';
import ProviderPanel from '../ProviderPanel';
import ProvidersSelect from '../ProvidersSelect';
import CommentsTable, {
  getCommentsBasicFilter,
  saveCommentParams,
  setCommentsFiltersFromHistory
} from './commentsTable';
import { parseCommentText } from './BasicHopComment';
import GeneralDatePicker from '../GeneralDatePicker';

const PAGENAME = 'commentsHops';

const columns = (
  isAdmin: boolean,
  newHopsCommentsNotificationList: NotificationNewComment[],
  handleCheckRead: (notification?: NotificationNewComment) => void
): TableColumn<Comment>[] => [
  {
    width: '30px'
  },
  {
    name: 'Created Date (UTC)',
    selector: () => 'create_date',
    id: 'column-create_date',
    sortable: true,
    width: '150px',
    cell: (comment: Comment) => (
      <div className="table-cell gray">
        <span>
          {comment.create_date
            ? getClientFormattedDate(comment.create_date, DateFormat.MediumBothNoUTC)
            : 'None'}
        </span>
      </div>
    )
  },
  {
    name: 'Hop',
    selector: () => 'relatedHopId',
    id: 'column-relatedHopId',
    sortable: true,
    grow: 1,
    cell: (comment: Comment) =>
      comment.relatedHopActive ? (
        <div className="table-cell blue center">
          <a href={`/hops/hop/${comment.relatedHopId}`}>{comment.relatedHopId}</a>
        </div>
      ) : (
        <div className="table-cell gray center">
          <span>{comment.relatedHopId}</span>
        </div>
      )
  },
  {
    name: 'Traceback',
    selector: () => 'hopTracebackId',
    sortable: true,
    grow: 1,
    cell: (comment: Comment) =>
      isAdmin ? (
        <div className="table-cell blue center">
          <a href={`/tracebacks/traceback/${comment.hopTracebackId}`}>{comment.hopTracebackId}</a>
        </div>
      ) : (
        <div className="table-cell center">{comment.hopTracebackId}</div>
      )
  },
  {
    name: 'Traceforward',
    selector: () => 'tfhopTraceforwardId',
    sortable: true,
    grow: 2,
    cell: (comment: Comment) =>
      isAdmin ? (
        <div className="table-cell blue center">
          <a href={`/traceforwards/traceforward/${comment.tfhopTraceforwardId}`}>
            {comment.tfhopTraceforwardId}
          </a>
        </div>
      ) : (
        <div className="table-cell center">{comment.tfhopTraceforwardId}</div>
      )
  },
  {
    name: 'TfHop',
    selector: () => 'relatedTfHopId',
    sortable: true,
    grow: 1,
    cell: (comment: Comment) =>
      comment.relatedTfHopActive ? (
        <div className="table-cell blue center">
          <a href={`/tfhops/tfhop/${comment.relatedTfHopId}`}>{comment.relatedTfHopId}</a>
        </div>
      ) : (
        <div className="table-cell gray center">
          <span>{comment.relatedTfHopId}</span>
        </div>
      )
  },
  {
    name: 'Provider',
    selector: () => 'userProviderName',
    id: 'column-userProviderName',
    sortable: true,
    grow: 3,
    cell: (comment: Comment) => (
      <div>
        <div className="table-cell blue">
          <ProviderPanel provider={comment.userProvider} hyperlink={isAdmin} hideITG />
        </div>
        {comment.ccName && (
          <div className="text-secondary">
            <i
              className={`${
                comment.directedTo === 'downstream'
                  ? comment.replyCase
                    ? 'fa-solid fa-arrow-up'
                    : 'fa-solid fa-arrow-down'
                  : comment.replyCase
                    ? 'fa-solid fa-arrow-down'
                    : 'fa-solid fa-arrow-up'
              } me-1`}
            />
            {comment.ccName}
          </div>
        )}
      </div>
    )
  },
  {
    name: 'User',
    selector: () => 'userName',
    id: 'column-userName',
    sortable: true,
    grow: 2,
    cell: (comment: Comment) => <span className="table-cell gray">{comment.userName}</span>
  },
  {
    name: 'Comments',
    selector: () => 'contentText',
    id: 'column-contentText',
    sortable: false,
    grow: 4,
    cell: (comment: Comment) => (
      <span className="table-cell gray">{parseCommentText(comment.contentText)}</span>
    )
  },
  {
    name: 'Attachment',
    selector: () => 'isAttchmt',
    id: 'column-isAttchmt',
    sortable: false,
    grow: 1,
    cell: (comment: Comment) => (
      <div className={'table-cell center'}>
        {comment.attachments &&
          comment.attachments.length > 0 &&
          comment.attachments.map((attachment: Attachment) => (
            <Fragment key={attachment.commentId + '-' + attachment.unique_id}>
              <a
                style={{ fontWeight: 'bold' }}
                target="_blank"
                rel="noopener noreferrer"
                href={`/api/attachments/${attachment.unique_id}`}
              >
                <i className={'fa fa-paperclip'} />
              </a>
            </Fragment>
          ))}
      </div>
    )
  },
  {
    name: 'Read',
    sortable: false,
    omit: !isAdmin,
    grow: 1,
    cell: (comment: Comment) => (
      <div className="table-cell center">
        <input
          type="checkbox"
          id="read_comment"
          name="read"
          checked={
            !newHopsCommentsNotificationList.some((item) => item.objectId === comment.commentId)
          }
          className="checkbox"
          disabled={
            !newHopsCommentsNotificationList.some((item) => item.objectId === comment.commentId)
          }
          onChange={() =>
            handleCheckRead(
              newHopsCommentsNotificationList.filter(
                (item) => item.objectId === comment.commentId
              )[0]
            )
          }
        />
      </div>
    )
  }
];

const getFilter = ({
  startDate,
  endDate,
  providerId,
  searchKey,
  onlyTraceforward
}: any): FilterElement => {
  let filterElements = getCommentsBasicFilter(
    { startDate, endDate },
    onlyTraceforward ? 'tfhop' : ''
  );

  if (providerId) {
    if (!onlyTraceforward) {
      let providerElements = newConditionalFilterElement('OR');

      addCondition(
        providerElements,
        newLeafFilterElement('hopProviderId', 'EQ', providerId.toString())
      );

      addCondition(
        providerElements,
        newLeafFilterElement('tfhopProviderId', 'EQ', providerId.toString())
      );
      addCondition(filterElements, simplifyFilterElement(providerElements));
    } else {
      addCondition(
        filterElements,
        newLeafFilterElement('tfhopProviderId', 'EQ', providerId.toString())
      );
    }
  }

  if (searchKey) {
    addCondition(filterElements, newLeafFilterElement('searchKey', 'EQ', searchKey));
  }
  if (onlyTraceforward) {
    addCondition(
      filterElements,
      newLeafFilterElement('onlyTraceforward', 'EQ', onlyTraceforward.toString())
    );
  }
  return simplifyFilterElement(filterElements);
};

interface IProps {
  clearCommentsList: Function;
  user: PersonalInfo;
  deleteNotification: Function;
  getHopsNotifications: Function;
  newHopsCommentsNotificationList: NotificationNewComment[];
}

const ProvidersComments: React.FC<IProps> = ({
  clearCommentsList,
  user,
  newHopsCommentsNotificationList,
  deleteNotification,
  getHopsNotifications
}) => {
  const { search } = useLocation();
  const searchParams = getFromLocalStorage(PAGENAME) || search || '';
  const savedSearchDetails = decodeQueryParams(PAGENAME, searchParams);
  const filtersFromHistory = setCommentsFiltersFromHistory(savedSearchDetails);
  const [startDate, setStartDate] = useState<string>(filtersFromHistory.startDate || '');
  const [endDate, setEndDate] = useState<string>(filtersFromHistory.endDate || '');
  const [providerId, setProviderId] = useState<number | undefined>(
    filtersFromHistory.hopProviderId
  );
  const [searchKey, setSearchKey] = useState<string | undefined>(filtersFromHistory.searchKey);
  const [onlyTraceforward, setOnlyTraceforward] = useState<boolean>(
    !!filtersFromHistory.onlyTraceforward
  );
  const [filterElements, setFilterElements] = useState(
    getFilter({ startDate, endDate, providerId, searchKey, onlyTraceforward })
  );
  const [pagination, setPagination] = useState(
    savedSearchDetails.paginationParams || {
      ...defaultPagination(),
      sort: 'create_date',
      order: 'desc'
    }
  );

  useEffect(() => {
    if (!newHopsCommentsNotificationList.length && user.roleType === userTypes.Admin)
      getHopsNotifications();
  }, []);

  const handleCheck = (e: any, key: string) => {
    switch (key) {
      case 'providerId':
        setProviderId(Number(e.value));
        break;
      case 'searchKey':
        setSearchKey(e.target.value);
        break;
    }
  };

  const updateFilter = () => {
    setFilterElements(getFilter({ startDate, endDate, providerId, searchKey, onlyTraceforward }));
    setPagination({
      ...pagination,
      page: 1
    });
  };

  const clearFilters = () => {
    setStartDate('');
    setEndDate('');
    setProviderId(undefined);
    setOnlyTraceforward(false);
    setSearchKey('');
    clearCommentsList();
    saveCommentParams(PAGENAME, 1, getFilter({}), pagination);
  };

  useEffect(() => {
    saveCommentParams(PAGENAME, 1, filterElements, pagination);
  }, [filterElements, pagination]);

  const handleCheckRead = async (notification?: NotificationNewComment) => {
    if (notification) {
      await deleteNotification(notification.id);
      await getHopsNotifications();
    }
  };
  const conditionalRowStyles = [
    {
      when: (comment: Comment) =>
        newHopsCommentsNotificationList.some(
          (item) => item.objectId === comment.commentId && item.closeComment
        ),
      style: () => ({
        backgroundColor: '#fff5c5'
      })
    }
  ];
  return (
    <Fragment>
      <CardHeader className="card-header-hops">
        <div className="d-flex align-items-center flex-row flex-wrap p-0">
          <GeneralDatePicker
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            placeholderTextStart="&#xf133; startDate"
            placeholderTextEnd="&#xf133; endDate"
            containerClassname="pb-2 pb-md-0 me-2 col-2"
            clearButtonTitle={'Clear'}
            isClearable
          />
          {user.roleType === userTypes.Admin && (
            <div className="me-2 col-2">
              <ProvidersSelect
                onChange={(option) => handleCheck(option, 'providerId')}
                isSearchable
                addAllItem
                option={providerId}
                includeInactive
              />
            </div>
          )}
          <div className="me-2 col-2">
            <DebounceInput
              type={'text'}
              debounceTimeout={1000}
              className={'comments-search-with-border'}
              placeholder={'Search Comments'}
              onChange={(option) => handleCheck(option, 'searchKey')}
              value={searchKey}
            />
          </div>
          <div className="d-flex col">
            <input
              type="checkbox"
              id="traceforward"
              name="traceforward"
              checked={onlyTraceforward}
              className="checkbox"
              onChange={(e) => setOnlyTraceforward(e.currentTarget.checked)}
            />
            <label htmlFor="traceforward" className="label-bold ms-2">
              Traceforward
            </label>
          </div>
          <div className="pb-2 pb-md-0 me-2 col-1">
            <Button color="primary" size="sm" onClick={() => updateFilter()}>
              <i className="fa fa-search pe-2" />
              Search
            </Button>
          </div>
          <div className="ps-1">
            <button type="button" className="btn btn-link" onClick={clearFilters}>
              Clear Filters
            </button>
          </div>
        </div>
      </CardHeader>
      <Row>
        <Col>
          <CommentsTable
            columns={columns(
              user.roleType === userTypes.Admin,
              newHopsCommentsNotificationList,
              handleCheckRead
            )}
            filterElements={filterElements}
            pagination={pagination}
            setPagination={setPagination}
            conditionalRowStyles={conditionalRowStyles}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    user: sm.user,
    newHopsCommentsNotificationList: sm.comment.newHopsCommentsNotificationList
  };
};

const mapActionsToProps = {
  clearCommentsList,
  deleteNotification,
  getHopsNotifications
};

export default connect(mapStateToProps, mapActionsToProps)(ProvidersComments);
