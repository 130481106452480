import React, { FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { OnChangeValue } from 'react-select';
import { languageList, languageListWithNonEnglish } from '../../lib/languageSelect';
import { getCampaignLanguages } from '../../redux/campaign/thunks';
import { stateMappings } from '../../redux/stateMappings';
import CustomPicky from '../CustomPicky';
import CustomSelect, { SelectOption } from '../CustomSelect';

interface IProps {
  value: number | number[] | SelectOption | SelectOption[] | undefined | null;
  onChange: (value: OnChangeValue<SelectOption, boolean>) => void;
  addAllItem?: boolean;
  isMulti?: boolean;
  selectClass?: string;
  isClearable?: boolean;
  inputId?: string;
  placeholder?: string;
  isSearchable?: boolean;
  numberDisplayed?: number;
  maxMenuHeight?: number;
  typeLabel?: string;
  addNonEnglish?: boolean;
  languages: string[];
  getCampaignLanguages: Function;
  onlySelectedLanguages?: boolean;
}

const LanguageSelect: FC<IProps> = ({
  value,
  onChange,
  addAllItem,
  selectClass,
  isClearable,
  inputId,
  placeholder,
  isSearchable,
  isMulti,
  numberDisplayed,
  maxMenuHeight,
  typeLabel,
  addNonEnglish,
  languages,
  getCampaignLanguages,
  onlySelectedLanguages
}) => {
  const languagesList = useMemo(() => {
    if (onlySelectedLanguages) {
      const languagesSelected = languages.map((language: string) => ({
        value: languageList.find((l: SelectOption) => l.label === language)?.value,
        label: language
      }));
      if (languagesSelected && languagesSelected.length) {
        return addNonEnglish
          ? [{ value: -1, label: 'Non-English' }, ...languagesSelected]
          : languagesSelected;
      }
    }
    return addNonEnglish ? languageListWithNonEnglish : languageList;
  }, [languageList, languageListWithNonEnglish, addNonEnglish, languages]);

  useEffect(() => {
    if (onlySelectedLanguages) getCampaignLanguages();
  }, []);

  return isMulti ? (
    <CustomPicky
      selectedOption={value}
      setSelectedOption={onChange}
      getOptions={languagesList}
      className={selectClass ? selectClass : 'customselect-small'}
      isSearchable={isSearchable}
      isClearable={isClearable}
      addAllItem={addAllItem}
      typeLabel={typeLabel ? typeLabel : 'All Languages'}
      inputId={inputId}
      placeholder={placeholder}
      numberDisplayed={numberDisplayed ? numberDisplayed : 3}
      maxMenuHeight={maxMenuHeight}
    />
  ) : (
    <CustomSelect
      selectedOption={value}
      setSelectedOption={onChange}
      getOptions={languagesList}
      className={selectClass ? selectClass : 'customselect-small'}
      isSearchable={isSearchable}
      isClearable={isClearable}
      addAllItem={addAllItem}
      typeLabel={typeLabel ? typeLabel : 'All Languages'}
      inputId={inputId}
      placeholder={placeholder}
    />
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    languages: sm.campaign.languages
  };
};

const mapActionsToProps = { getCampaignLanguages };

export default connect(mapStateToProps, mapActionsToProps)(LanguageSelect);
