import { AppInfo } from './actionEnum';

export const getClientConfiguration = (response: any) => ({
  type: AppInfo.GET_CLIENT_CONFIGURATION,
  payload: response
});

export const getCampaignNames = (response: any) => ({
  type: AppInfo.GET_CAMPAIGN_NAMES,
  payload: response
});

export const getUserNames = (response: any) => ({
  type: AppInfo.GET_USER_NAMES,
  payload: response
});

export const getOriginatorCNamOptions = (response: any) => ({
  type: AppInfo.GET_ORIGINATOR_CNAM_OPTIONS,
  payload: response
});

export const getOriginatorCarrierOptions = (response: any) => ({
  type: AppInfo.GET_ORIGINATOR_CARRIER_OPTIONS,
  payload: response
});

export const getDestinationCarrierOptions = (response: any) => ({
  type: AppInfo.GET_DESTINATION_CARRIER_OPTIONS,
  payload: response
});

export const getCronJobsStatsData = (response: any) => ({
  type: AppInfo.GET_CRONJOBS_STATS,
  payload: response
});

export const setLoadingStatus = (status: boolean) => ({
  type: AppInfo.SET_APPINFO_LOADING_STATUS,
  payload: status
});

export const setErrorMessage = (message: string) => ({
  type: AppInfo.SET_APPINFO__ERROR_MESSAGE,
  payload: message
});
