import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Label } from 'reactstrap';
import { ReportFormat, ReportType } from '../../../interfaces/report';
import { getApiFormattedDate, getUtcEndDate, getUtcStartDate } from '../../../lib/utilities';
import { getReportObject } from '../../../redux/report/thunks';
import GeneralDatePicker from '../../GeneralDatePicker';

interface IProps {
  getReportObject: Function;
}

const QuarterlyReport: React.FC<IProps> = ({ getReportObject }) => {
  const [startDate, setStartDate] = useState<string>(getUtcStartDate({ months: 1 }));
  const [endDate, setEndDate] = useState<string>(getUtcEndDate());

  const getReport = () => {
    getReportObject(
      {
        startDate: getApiFormattedDate(startDate),
        endDate: getApiFormattedDate(endDate)
      },
      ReportType.QuarterlyReport,
      ReportFormat.Excel
    );
  };

  return (
    <Fragment>
      <Label className="telecom-label ps-0">Date Range *</Label>
      <div className="d-flex flex-row mb-4">
        <GeneralDatePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          placeholderTextStart="&#xf133; mm/dd/yyyy"
          placeholderTextEnd="&#xf133; mm/dd/yyyy"
          clearButtonTitle={'Clear'}
          isClearable
          reportStyle
        />
      </div>
      <div className="m-4" style={{ borderBottom: '1px solid #ccc' }} />
      <div className="d-flex justify-content-md-center">
        <Button
          className="telecom-btn me-2"
          color="primary"
          style={{
            height: '28px',
            padding: '5px',
            fontSize: '14px',
            width: '150px'
          }}
          onClick={getReport}
        >
          {`Create Report `}
        </Button>
      </div>
    </Fragment>
  );
};

const mapActionsToProps = {
  getReportObject
};

export default connect(null, mapActionsToProps)(QuarterlyReport);
