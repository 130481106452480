import React, { Fragment } from 'react';
import { TableColumn } from 'react-data-table-component';
import ProviderPanel from '../../components/ProviderPanel';
import { tbStatus } from '../../components/stateConverter';
import { DateFormat } from '../../enum/DateFormat';
import { Traceforward } from '../../interfaces/traceforward';
import { getClientFormattedDate, getDuration, getElapsedTime } from '../utilities';

const rmdDate = '2022-09-28 23:59:59';

export const traceforwardsColumns: TableColumn<Traceforward>[] = [
  {
    name: 'Tf #',
    selector: () => 'id',
    sortable: true,
    grow: 1,
    cell: (traceforward: Traceforward) => (
      <div
        className="table-cell blue"
        onClick={() => (window.location.href = `/traceforwards/traceforward/${traceforward.id}`)}
      >
        <a href={`/traceforwards/traceforward/${traceforward.id}`}>{traceforward.id}</a>
      </div>
    )
  },
  {
    name: 'Campaign',
    selector: () => 'campaignName',
    sortable: true,
    grow: 2,
    cell: (traceforward: Traceforward) => (
      <div
        className="table-cell blue"
        onClick={() => (window.location.href = `/traceforwards/traceforward/${traceforward.id}`)}
      >
        <a href={`/campaigns/campaign/${traceforward.campaignId}`}>
          {traceforward.campaignName || 'None'}
        </a>
      </div>
    )
  },
  {
    name: 'Created',
    selector: () => 'create_date',
    sortable: true,
    grow: 1,
    cell: (traceforward: Traceforward) => (
      <span
        className="table-cell gray"
        onClick={() => (window.location.href = `/traceforwards/traceforward/${traceforward.id}`)}
      >
        {traceforward.create_date
          ? getClientFormattedDate(traceforward.create_date, DateFormat.ShortDate)
          : 'None'}
      </span>
    )
  },
  {
    name: 'Last Updated',
    selector: () => 'update_date',
    sortable: true,
    grow: 1,
    cell: (traceforward: Traceforward) => {
      return (
        <span className={`table-cell ${traceforward.update_date ? 'blue' : 'gray'}`}>
          <div
            className="table-cell blue"
            onClick={() =>
              (window.location.href = `/traceforwards/traceforward/${traceforward.id}`)
            }
          >
            {traceforward.update_date ? (
              <a href={`/tfhops/tfhop/${traceforward.lastTfHopId}`}>
                {getDuration(traceforward.update_date)}
              </a>
            ) : (
              <Fragment>{'None'}</Fragment>
            )}
          </div>
        </span>
      );
    }
  },
  {
    name: 'Time Elapsed',
    selector: () => 'elapsed',
    sortable: true,
    grow: 1,
    cell: (traceforward: Traceforward) => (
      <div
        className="table-cell gray center"
        onClick={() => (window.location.href = `/traceforwards/traceforward/${traceforward.id}`)}
      >
        <span>{traceforward.elapsed ? getElapsedTime(traceforward.elapsed) : 'None'}</span>
      </div>
    )
  },
  {
    name: 'Status',
    selector: () => 'status',
    sortable: true,
    grow: 1,
    cell: (traceforward: Traceforward) => (
      <div
        className="table-cell gray center"
        onClick={() => (window.location.href = `/traceforwards/traceforward/${traceforward.id}`)}
      >
        <span
          style={{
            fontWeight: traceforward.status > 0 && traceforward.status < 6 ? 600 : 'inherit'
          }}
        >
          {tbStatus(traceforward.status)}
        </span>
      </div>
    )
  },
  {
    name: 'Last Provider',
    selector: () => 'lastProviderName',
    sortable: true,
    grow: 2,
    cell: (traceforward: Traceforward) => (
      <ProviderPanel includeReputation={true} provider={traceforward.lastProvider} hyperlink />
    )
  },
  {
    name: 'Hops',
    selector: () => 'numberOfHops',
    sortable: true,
    grow: 1,
    cell: (traceforward: Traceforward) => (
      <div
        className={`table-cell ${traceforward.numberOfTfHops ? 'blue center' : 'gray center'}`}
        onClick={() => (window.location.href = `/traceforwards/traceforward/${traceforward.id}`)}
      >
        {traceforward.numberOfTfHops ? (
          <a href={`/tfhops?traceforwardId=${traceforward.id}`}>{traceforward.numberOfTfHops}</a>
        ) : (
          <span>{traceforward.numberOfTfHops}</span>
        )}
      </div>
    )
  }
];
