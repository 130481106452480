import React from 'react';
import { TableColumn } from 'react-data-table-component';
import ColumnTooltip from '../../components/ColumnTooltip';
import ProviderPanel from '../../components/ProviderPanel';
import { Notes } from '../../components/shared/Notes';
import { DateFormat } from '../../enum/DateFormat';
import { TfHop } from '../../interfaces/tfhop';
import { getClientFormattedDate, getElapsedTime } from '../utilities';

export const tfhopsColumns = (
  includeLinks: boolean,
  isTraceforward: boolean,
  isProvider: boolean,
  statuses: { [key: number]: string }
): TableColumn<TfHop>[] => [
  {
    name: 'Hop',
    selector: () => 'tfhopId',
    id: 'column-tfhopId',
    sortable: false,
    width: '80px',
    cell: (hop: TfHop) => (
      <div
        className="table-cell blue center"
        onClick={() => (window.location.href = `/tfhops/tfhop/${hop.tfhopId}`)}
      >
        <a href={`/tfhops/tfhop/${hop.tfhopId}`}>{hop.tfhopSequence}</a>
      </div>
    )
  },
  {
    name: 'Notes',
    selector: () => 'notes',
    id: 'column-notes',
    sortable: false,
    width: '150px',
    cell: (hop: TfHop) => (
      <div className="table-cell center">
        <a href={`/tfhops/tfhop/${hop.tfhopId}`}>
          <Notes types={hop.notes} />
        </a>
      </div>
    )
  },
  {
    name: 'Traceforward',
    selector: () => 'traceforwardId',
    id: 'column-traceforwardId',
    sortable: true,
    width: '100px',
    omit: isTraceforward || isProvider,
    cell: (hop: TfHop) => (
      <div
        className="table-cell blue center"
        onClick={() => (window.location.href = `/tfhops/tfhop/${hop.tfhopId}`)}
      >
        <a href={`/traceforwards/traceforward/${hop.traceforwardId}`}>{hop.traceforwardId}</a>
      </div>
    )
  },
  {
    name: ' Traceforward ',
    selector: () => 'traceforwardId',
    id: 'column-traceforwardId2',
    sortable: true,
    width: '100px',
    omit: !isProvider,
    cell: (hop: TfHop) => <div className="table-cell gray center">{hop.traceforwardId}</div>
  },
  {
    name: 'Campaign',
    selector: () => 'campaignName',
    id: 'column-campaignName',
    sortable: true,
    grow: 3,
    omit: isTraceforward || isProvider,
    cell: (hop: TfHop) => (
      <div
        className="table-cell blue"
        onClick={() => (window.location.href = `/tfhops/tfhop/${hop.tfhopId}`)}
      >
        <a href={`/campaigns/campaign/${hop.campaignId}`}>{hop.campaignName}</a>
      </div>
    )
  },
  {
    name: ' Campaign ',
    selector: () => 'campaignName',
    id: 'column-campaignName2',
    sortable: true,
    grow: 3,
    omit: !isProvider,
    cell: (hop: TfHop) => <div className="table-cell gray">{hop.campaignName}</div>
  },
  {
    name: 'Provider',
    selector: () => 'hopProviderName',
    id: 'column-hopProviderName',
    sortable: true,
    grow: 3,
    omit: isProvider,
    cell: (hop: TfHop) => (
      <ProviderPanel includeReputation hyperlink={includeLinks} provider={hop.tfhopProvider} />
    )
  },
  {
    name: 'Received Call From',
    selector: () => 'upstreamProviderName',
    id: 'column-upstreamProviderName',
    sortable: true,
    grow: 3,
    omit: !isProvider,
    cell: (hop: TfHop) => (
      <ProviderPanel includeReputation hyperlink={includeLinks} provider={hop.upstreamProvider} />
    )
  },
  {
    name: 'Sent Call To',
    selector: () => 'downstreamProviderName',
    id: 'column-downstreamProviderName',
    sortable: true,
    grow: 3,
    omit: isTraceforward,
    cell: (hop: TfHop) => (
      <ProviderPanel includeReputation hyperlink={includeLinks} provider={hop.downstreamProvider} />
    )
  },
  {
    name: 'Completed',
    selector: () => 'completed',
    id: 'column-completed',
    sortable: true,
    width: '150px',
    cell: (hop: any) => {
      if (hop.completed) {
        const hopStatus = statuses[hop.status];
        if (
          hopStatus === 'OPEN' ||
          hopStatus === 'PENDING APPROVAL' ||
          hopStatus === 'PENDING DISPUTE'
        ) {
          return (
            <div
              className="table-cell blue bold center"
              onClick={() => (window.location.href = `/tfhops/tfhop/${hop.tfhopId}`)}
            >
              <a href={`/tfhops/tfhop/${hop.tfhopId}`}>{hopStatus}</a>
            </div>
          );
        }
        return (
          <div className="table-cell center">
            {getClientFormattedDate(hop.completed, DateFormat.ShortDate)}
          </div>
        );
      }
      return null;
    }
  },
  {
    name: 'Elapsed',
    selector: () => 'elapsed',
    id: 'column-elapsed',
    sortable: true,
    grow: 1,
    cell: (hop: TfHop) => HopElapsedColumn(hop)
  }
];

export const HopElapsedColumn = (hop: TfHop) =>
  hop.elapsedPoe ? (
    <ColumnTooltip tooltipVisible={hop.elapsedPoe} columnType="elapsed">
      <label className={'text-' + (hop.elapsedPoe ? 'red font-weight-bold' : 'dark')}>
        {getElapsedTime(hop.elapsed)}
      </label>
    </ColumnTooltip>
  ) : (
    <span className="table-cell gray center">
      {hop.elapsed ? getElapsedTime(hop.elapsed) : 'None'}
    </span>
  );
