import React, { Fragment, useState } from 'react';
import { Button } from 'reactstrap';
import { DateFormat } from '../../enum/DateFormat';
import { userTypes } from '../../enum/userTypes';
import { Hop } from '../../interfaces/hop';
import { Traceback } from '../../interfaces/traceback';
import { PersonalInfo } from '../../interfaces/user';
import { getClientFormattedDate, getElapsedTime } from '../../lib/utilities';
import ColumnTooltip from '../ColumnTooltip';
import { HoverCardArrow, HoverCardContent, HoverCardRoot, HoverCardTrigger } from '../HoverCard';

interface IProps {
  hop: Hop;
  traceback: Traceback;
  user: PersonalInfo;
  statuses: { [key: number]: string };
  updateHopObject: Function;
}

const HopDetailsRow: React.FC<IProps> = ({ hop, traceback, user, statuses, updateHopObject }) => {
  // Open Hops
  const isOpen = hop.status === 1;
  const hopStatus = statuses ? statuses[hop.status] : '';

  const [releaseTip, setReleaseTip] = useState(!localStorage.getItem('releaseTip'));

  const claimHop = () => {
    updateHopObject({
      hopId: hop.hopId,
      delegatedTo: user.email
    });
  };

  const releaseHop = () => {
    updateHopObject({
      hopId: hop.hopId,
      delegatedTo: 'Pending'
    });
  };

  const handleReleaseTooltipBtnClick = () => {
    localStorage.setItem('releaseTip', 'SET');
    setReleaseTip(false);
  };

  return (
    <Fragment>
      <h5 className="p-0 traceback-sub-title mb-3">Hop Details</h5>
      <div className="hop-details">
        <div className="ml-2">
          <label className="telecom-label">Status</label>
          {hopStatus === 'DELETED' && <span className="span-cell red bold">{hopStatus}</span>}
          {hopStatus === 'NOT FOUND' && <span className="span-cell pink bold">{hopStatus}</span>}
          {hopStatus === 'COMPLETED' && <span className="span-cell green bold">{hopStatus}</span>}
          {hopStatus === 'NO RESPONSE' && (
            <span className="span-cell light-blue bold center">{hopStatus}</span>
          )}
          {hopStatus === 'OPEN' && <span className="span-cell blue bold">{hopStatus}</span>}
          {hopStatus === 'PENDING APPROVAL' && (
            <Fragment>
              {user.roleType === userTypes.Admin ? (
                <span className="span-cell bold center" style={{ color: '#209FAB' }}>
                  {hopStatus}
                </span>
              ) : (
                <span className="span-cell green bold center">COMPLETED</span>
              )}
            </Fragment>
          )}
          {hopStatus === 'PENDING DISPUTE' && (
            <span className="span-cell blue bold center">{hopStatus}</span>
          )}
        </div>
        <div>
          <label className="telecom-label">Hops To Date</label>
          {user.roleType === userTypes.Admin ? (
            <a
              href={`/hops?tracebackId=${hop.tracebackId}`}
              className="span-cell blue text-nowrap"
              style={{
                fontWeight: 'bold',
                paddingLeft: '40px'
              }}
            >
              {traceback.numberOfHops}
            </a>
          ) : (
            <span
              className="span-cell blue text-nowrap"
              style={{
                fontWeight: 'bold',
                paddingLeft: '40px'
              }}
            >
              {traceback.numberOfHops}
            </span>
          )}
        </div>
        <div style={{ cursor: 'default' }}>
          {!isOpen ? (
            <span>
              <label className="telecom-label">Information Provided By</label>
              <span>{hop.delegatedTo}</span>
            </span>
          ) : (
            <span>
              <label className="telecom-label">Information Provided By</label>
              {hop.delegatedTo ? hop.delegatedTo : `Pending`}
              <span style={{ color: '#0f72b1', cursor: 'pointer' }}>
                <span onClick={() => claimHop()}>&emsp;Claim</span>
                <span> | </span>
                <HoverCardRoot openDelay={150}>
                  <HoverCardTrigger asChild>
                    <span onClick={() => releaseHop()}>Release</span>
                  </HoverCardTrigger>
                  {releaseTip && (
                    <HoverCardContent sideOffset={5} className="hoverCardGovWidth">
                      This Hop will automatically be claimed by you if you fill in the source,
                      below. Manually claiming this Hop allows you to let other members of your
                      organization know that you are already researching the record, allowing you to
                      communicate without duplication effort. Release allows you to disassociate
                      yourself from the research.
                      <div className="d-flex justify-content-end">
                        <Button
                          color="primary"
                          onClick={handleReleaseTooltipBtnClick}
                          style={{ marginTop: '12px', marginRight: '16px' }}
                        >
                          Got it
                        </Button>
                      </div>
                      <HoverCardArrow />
                    </HoverCardContent>
                  )}
                </HoverCardRoot>
              </span>
            </span>
          )}
        </div>
        <div>
          <label className="telecom-label">Response Time</label>
          {hop.fccResponse ? (
            <ColumnTooltip tooltipVisible={hop.fccResponse} columnType="hopElapsed">
              <label className={'text-red font-weight-bold'}>
                {getElapsedTime(hop.elapsed)}
                <i className="fa fa-clock ms-1" />
              </label>
            </ColumnTooltip>
          ) : (
            <span className={'span-cell grey'}>{getElapsedTime(hop.elapsed)}</span>
          )}
        </div>
        <div>
          <label className="telecom-label">Date & Time Notified</label>
          <span className="span-cell gray">
            {getClientFormattedDate(hop.notified, DateFormat.MediumBoth)}
          </span>
        </div>
        {hop.resetDate && (
          <div>
            <label className="telecom-label">Date & Time of Reset</label>
            <span className="span-cell gray">
              {getClientFormattedDate(hop.resetDate, DateFormat.MediumBoth)}
            </span>
          </div>
        )}
        {hop.status === 2 && (
          <div>
            <label className="telecom-label">Date & Time Completed</label>
            {getClientFormattedDate(hop.completed, DateFormat.MediumBoth)}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default HopDetailsRow;
