import React, { FC } from 'react';
import DatePicker from 'react-datepicker';
import { formatEndDate, formatStartDate, stringToDate, utcNow } from '../lib/utilities';

interface IProps {
  datePickerClassname?: string;
  startDate: string;
  endDate?: string;
  setStartDate: (date: string) => void;
  setEndDate?: (date: string) => void;
  defaultStartDate?: string;
  defaultEndDate?: string;
  containerClassname?: string;
  placeholderTextStart?: string;
  placeholderTextEnd?: string;
  isClearable?: boolean;
  clearButtonTitle?: string;
  showYearDropdown?: boolean;
  scrollableYearDropdown?: boolean;
  yearDropdown?: boolean;
  onlyStartDate?: boolean;
  reportStyle?: boolean;
  setError?: () => void;
  dateFormat?: string;
  showMonthYearPicker?: boolean;
}
const GeneralDatePicker: FC<IProps> = ({
  datePickerClassname,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  containerClassname,
  defaultStartDate,
  defaultEndDate,
  placeholderTextStart,
  placeholderTextEnd,
  isClearable,
  clearButtonTitle,
  showYearDropdown,
  scrollableYearDropdown,
  yearDropdown,
  onlyStartDate,
  reportStyle,
  setError,
  dateFormat,
  showMonthYearPicker
}) => {
  const handleStartDate = (date: Date | null) => {
    if (date) {
      setStartDate(formatStartDate(date));
      setError && setError();
    } else {
      setStartDate(defaultStartDate || '');
    }
  };
  const handleEndDate = (date: Date | null) =>
    date ? setEndDate?.(formatEndDate(date)) : setEndDate?.(defaultEndDate || '');
  return (
    <>
      <div className={containerClassname}>
        <DatePicker
          className={datePickerClassname}
          maxDate={endDate ? stringToDate(endDate) : utcNow()}
          selected={stringToDate(startDate)}
          onChange={handleStartDate}
          placeholderText={placeholderTextStart}
          clearButtonTitle={clearButtonTitle}
          isClearable={isClearable}
          showYearDropdown={showYearDropdown}
          yearDropdownItemNumber={yearDropdown ? 15 : 0}
          scrollableYearDropdown={scrollableYearDropdown}
          dateFormat={dateFormat}
          showMonthYearPicker={showMonthYearPicker}
        />
      </div>
      {reportStyle && <span className="ms-2 me-2">-</span>}
      {!onlyStartDate && (
        <div className={containerClassname}>
          <DatePicker
            className={datePickerClassname}
            maxDate={utcNow()}
            selected={stringToDate(endDate)}
            minDate={stringToDate(startDate)}
            onChange={handleEndDate}
            placeholderText={placeholderTextEnd}
            clearButtonTitle={clearButtonTitle}
            isClearable={isClearable}
            showYearDropdown={showYearDropdown}
            yearDropdownItemNumber={yearDropdown ? 15 : 0}
            scrollableYearDropdown={scrollableYearDropdown}
            dateFormat={dateFormat}
            showMonthYearPicker={showMonthYearPicker}
          />
        </div>
      )}
    </>
  );
};

export default GeneralDatePicker;
