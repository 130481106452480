import React, { FC, Fragment, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { CardTitle } from 'reactstrap';
import { ChartFilterElementsForProviderSummary } from '../../interfaces/charts';
import { ProviderStirShakenStats } from '../../interfaces/provider';
import { getProviderStirShakenStatsObject } from '../../redux/provider/thunks';
import { stateMappings } from '../../redux/stateMappings';
import CustomToolTip from '../CustomToolTip';

interface IProps {
  providerStirShakenStats: ProviderStirShakenStats;
  getProviderStirShakenStatsObject: Function;
  providerId: number;
  chartFilters: ChartFilterElementsForProviderSummary;
}

const ProviderStirShakenStatsChart: FC<IProps> = ({
  providerId,
  chartFilters,
  providerStirShakenStats,
  getProviderStirShakenStatsObject
}) => {
  const stirShakenSignerPercentage = useMemo(
    () =>
      providerStirShakenStats.totalTracebacks
        ? (
            (providerStirShakenStats.stirShakenSigner / providerStirShakenStats.totalTracebacks) *
            100
          ).toFixed(1) + '%'
        : '0.0%',
    [providerStirShakenStats.totalTracebacks, providerStirShakenStats.stirShakenSigner]
  );
  const intermediateStirShakenSignerPercentage = useMemo(
    () =>
      providerStirShakenStats.totalTracebacks
        ? (
            (providerStirShakenStats.intermediateStirshakenSigner /
              providerStirShakenStats.totalTracebacks) *
            100
          ).toFixed(1) + '%'
        : '0.0%',
    [providerStirShakenStats.totalTracebacks, providerStirShakenStats.intermediateStirshakenSigner]
  );
  useEffect(() => {
    getProviderStirShakenStatsObject(chartFilters, providerId);
  }, [
    providerId,
    chartFilters.labelId,
    chartFilters.note,
    chartFilters.termState,
    chartFilters.startDate,
    chartFilters.endDate
  ]);
  return (
    <Fragment>
      {providerStirShakenStats.totalTracebacks != 0 && (
        <div className="row p-5">
          <div className="col-4">
            <CardTitle tag="h5">STIR/SHAKEN Tracebacks</CardTitle>
            <CardTitle tag="h5">Identified as Origin & Call Signer</CardTitle>
            <CardTitle className="text-secondary" tag="h3">
              {stirShakenSignerPercentage}
            </CardTitle>
          </div>
          <div className="col-4">
            <CardTitle tag="h5">STIR/SHAKEN Tracebacks</CardTitle>
            <CardTitle tag="h5">Identified as Intermediate Signer</CardTitle>
            <CardTitle className="text-secondary" tag="h3">
              {intermediateStirShakenSignerPercentage}
            </CardTitle>
          </div>
          <div className="col-4">
            <CardTitle tag="h5">STIR/SHAKEN Errors</CardTitle>
            <div>
              {`Crypto EDCA Signing Error (${providerStirShakenStats.errorSigningCount})`}
              <CustomToolTip
                tooltipVisible
                message="Issues with header uploaded for a traceback prevented it from being processed."
              >
                <i className="fa fa-info-circle ms-1 mt-1"></i>
              </CustomToolTip>
            </div>
            <div>
              {`Issuer not approved (${providerStirShakenStats.issuerErrorCount})`}
              <CustomToolTip
                tooltipVisible
                message="The Issuer certificate could not be verified because it is signed by an unknown authority or is either expired or not yet valid."
              >
                <i className="fa fa-info-circle ms-1 mt-1"></i>
              </CustomToolTip>
            </div>
            <div>
              {`Identified as signer when not in Traceback (${providerStirShakenStats.notInCallPathCount})`}
              <CustomToolTip
                tooltipVisible
                message="Number of Tracebacks where you are identified as call signer but are NOT present in the call path."
              >
                <i className="fa fa-info-circle ms-1 mt-1"></i>
              </CustomToolTip>
            </div>
            <div>
              {`Identified signer not in call path (${providerStirShakenStats.signedNotInCallPathCount})`}
              <CustomToolTip
                tooltipVisible
                message="Number of Tracebacks where the STIR/SHAKEN header uploaded identified a signer that was NOT found in the call path."
              >
                <i className="fa fa-info-circle ms-1 mt-1"></i>
              </CustomToolTip>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    providerStirShakenStats: sm.provider.providerStirShakenStats
  };
};
const mapActionsToProps = {
  getProviderStirShakenStatsObject
};

export default connect(mapStateToProps, mapActionsToProps)(ProviderStirShakenStatsChart);
