import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Label } from 'reactstrap';
import { ReportFormat, ReportType } from '../../../interfaces/report';
import { validateIpAddress } from '../../../lib/regex';
import { getApiFormattedDate, getUtcEndDate, getUtcStartDate } from '../../../lib/utilities';
import { getReportObject } from '../../../redux/report/thunks';
import ProvidersSelect from '../../ProvidersSelect';
import InputFormGroup from '../../inputFormGroup';
import GeneralDatePicker from '../../GeneralDatePicker';

interface IProps {
  getReportObject: Function;
}

const IPReport: React.FC<IProps> = ({ getReportObject }) => {
  const [startDate, setStartDate] = useState<string>(getUtcStartDate({ months: 1 }));
  const [endDate, setEndDate] = useState<string>(getUtcEndDate());
  const [selectedProvider, setSelectedProvider] = useState<number>(0);
  const [ipAddress, setIpAddress] = useState<string>('');
  const isIPAddressValid = !ipAddress ? true : validateIpAddress(ipAddress);

  const getReport = () => {
    getReportObject(
      {
        startDate: getApiFormattedDate(startDate),
        endDate: getApiFormattedDate(endDate),
        providerId: selectedProvider && selectedProvider !== -1 ? selectedProvider : undefined,
        origin: ipAddress,
        userRole: selectedProvider === -1 ? 'gov' : undefined
      },
      ReportType.IPReport,
      ReportFormat.Excel
    );
  };

  const handleCheck = (e: any, key: string) => {
    switch (key) {
      case 'providerId':
        setSelectedProvider(e.value);
        break;
      case 'ipAddress':
        setIpAddress(e.target.value);
        break;
      case 'excel':
        if (!isIPAddressValid) return;
        getReport();
        break;
    }
  };

  return (
    <Fragment>
      <Label className="telecom-label ps-0">Date Range *</Label>
      <div className="d-flex flex-row mb-4">
        <GeneralDatePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          placeholderTextStart="&#xf133; mm/dd/yyyy"
          placeholderTextEnd="&#xf133; mm/dd/yyyy"
          clearButtonTitle={'Clear'}
          isClearable
          reportStyle
        />
        <ProvidersSelect
          className="customselect-small col-2 ps-2 pe-2"
          onChange={(option) => handleCheck(option, 'providerId')}
          option={selectedProvider}
          addAllItem={true}
          addGovProvider
          includeInactive
        />
        <InputFormGroup
          containerClassName="col-3"
          inputClassName="csd-search"
          inputPlaceholder="Enter IP Address"
          inputValue={ipAddress}
          inputOnChange={(option) => handleCheck(option, 'ipAddress')}
          valid={isIPAddressValid && !!ipAddress}
          invalid={!isIPAddressValid}
          errorMessage={!isIPAddressValid ? 'This is not a valid IP Address ' : ''}
        />
      </div>
      <div className="m-4" style={{ borderBottom: '1px solid #ccc' }} />
      <div className="d-flex justify-content-md-center">
        <Button
          className="telecom-btn me-2"
          color="primary"
          style={{
            height: '28px',
            padding: '5px',
            fontSize: '14px',
            width: '150px'
          }}
          disabled={!((selectedProvider || ipAddress) && isIPAddressValid && startDate && endDate)}
          onClick={(option) => handleCheck(option, 'excel')}
        >
          {`Create Report `}
        </Button>
      </div>
    </Fragment>
  );
};

const mapActionsToProps = {
  getReportObject
};

export default connect(null, mapActionsToProps)(IPReport);
