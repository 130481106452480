import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { DateFormat } from '../../enum/DateFormat';
import { userTypes } from '../../enum/userTypes';
import { NewProviderDetail } from '../../interfaces/provider';
import { PersonalInfo } from '../../interfaces/user';
import { getClientFormattedDate } from '../utilities';

export const newProviderColumns = (user: PersonalInfo): TableColumn<any>[] => [
  {
    name: 'Date Added',
    selector: () => 'dateAdded',
    sortable: true,
    grow: 3,
    cell: (provider: NewProviderDetail) => (
      <div className="table-cell center me-2">
        <span>{getClientFormattedDate(provider.dateAdded, DateFormat.ShortDate)}</span>
      </div>
    )
  },
  {
    name: 'New Provider',
    selector: () => 'providerName',
    sortable: true,
    grow: 3,
    cell: (provider: NewProviderDetail) => (
      <div className="table-cell center">
        <a
          className="span-cell blue"
          href={
            user.roleType === userTypes.GovUser
              ? `/providers/provider/summaries/${provider.providerId}`
              : `/providers/provider/${provider.providerId}`
          }
        >
          {provider.providerName}
        </a>
      </div>
    )
  },
  {
    name: 'Country',
    selector: () => 'country',
    sortable: false,
    grow: 3,
    cell: (provider: NewProviderDetail) => (
      <div className="table-cell center">
        <span>{provider.country}</span>
      </div>
    )
  },
  {
    name: 'RMD',
    selector: () => 'providerRmd',
    sortable: false,
    grow: 3,
    cell: (provider: NewProviderDetail) => (
      <div className="table-cell center">
        <a
          className="span-cell ellipses blue me-2"
          href={`https://fccprod.servicenowservices.com/rmd?id=rmd_form&table=x_g_fmc_rmd_robocall_mitigation_database&sys_id=${provider.rmdSysId}&view=sp`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {provider.providerRmd}
        </a>
      </div>
    )
  },
  {
    name: 'RMD Declaration',
    selector: () => 'rmdDeclaration',
    sortable: false,
    grow: 3,
    cell: (provider: NewProviderDetail) => (
      <div className="table-cell center">
        <span>{provider.rmdDeclaration}</span>
      </div>
    )
  },
  {
    name: 'First Identified By',
    selector: () => 'identifiedBy',
    sortable: true,
    grow: 3,
    cell: (provider: NewProviderDetail) => (
      <div className="table-cell center">
        {provider.identifierId !== 6 && provider.identifierId !== 0 ? (
          <a
            className="span-cell blue"
            href={
              user.roleType === userTypes.GovUser
                ? `/providers/provider/summaries/${provider.identifierId}`
                : `/providers/provider/${provider.identifierId}`
            }
          >
            {provider.identifiedBy}
          </a>
        ) : (
          <span>{provider.identifiedBy}</span>
        )}
      </div>
    )
  }
];
