import { CampaignAttributes, emptyCampaignAttributes } from './campaign';
import { Provider, emptyProvider } from './provider';
import { Requestor } from './traceback';

export interface Traceforward {
  active: boolean;
  audioName: string;
  audioUrl: string;
  calledTN: string;
  callerName: string;
  callingTN: string;
  campaignId: number;
  campaignName: string;
  comments: [];
  completed: string;
  create_date: string;
  createdBy: string;
  creatorProvider: string;
  elapsed: number;
  lastTfHopId: number;
  lastProvider: Provider;
  numberOfTfHops: number;
  providerId: number;
  status: number;
  strikeExempt: boolean;
  timeZone: string;
  id: number;
  traceforwardTime: string;
  update_date: string;
  updateFlag: number;
  requestors: Requestor[];
  source: string;
  campaignAttributes: CampaignAttributes;
}

export interface TraceforwardPostData {
  audioName: string;
  audioUrl: string;
  audioUrlPath: string;
  calledTN: string;
  callingTN: string;
  campaignId: number;
  campaignName: string;
  create_date: string;
  createdBy: string;
  traceforwardTime: string;
}

export const emptyTraceforward = (): Traceforward => ({
  active: false,
  audioName: '',
  audioUrl: '',
  calledTN: '',
  callerName: '',
  callingTN: '',
  campaignId: 0,
  campaignName: '',
  comments: [],
  completed: '',
  create_date: '',
  createdBy: '',
  creatorProvider: '',
  elapsed: 0,
  lastTfHopId: 0,
  lastProvider: emptyProvider(),
  numberOfTfHops: 0,
  providerId: 0,
  status: 0,
  strikeExempt: false,
  timeZone: '',
  id: 0,
  traceforwardTime: '',
  update_date: '',
  updateFlag: 0,
  requestors: [],
  source: '',
  campaignAttributes: emptyCampaignAttributes()
});
