import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { userTypes } from '../../enum/userTypes';
import { DirectedToValue } from '../../interfaces/comment';
import { PersonalInfo } from '../../interfaces/user';
import { addCommentObject } from '../../redux/comment/thunks';
import { getHopObject } from '../../redux/hop/thunks';
import { stateMappings } from '../../redux/stateMappings';
import DropZone from '../DropZone';
import InputError from '../inputError';
import InputFormGroup from '../inputFormGroup';

export interface ReplyToInfo {
  commentID: number;
  hopID: number;
  providerName: string;
  directedTo?: DirectedToValue;
}
interface IProps {
  hopID: number;
  replyTo?: ReplyToInfo;
  isCallSourcePopulated: boolean;
  hopProviderName: string;
  upstreamProviderName: string;
  downstreamProviderName: string;
  getHopObject: Function;
  user: PersonalInfo;
  uniqueId?: string;
  forceDirectedTo?: DirectedToValue;
}

export interface DirectedValues {
  value: DirectedToValue;
  label: string;
  add?: boolean; // used internally to determine if we need to add them or not
}

export const directedToValues = (
  userType: userTypes,
  hopProviderName: string,
  upstreamProviderName: string,
  downstreamProviderName: string,
  addOldUpstreamComments?: boolean,
  addOldDownstreamComments?: boolean
): DirectedValues[] =>
  [
    {
      value: 'admin' as DirectedToValue,
      label: `${hopProviderName} & ITG Administrators`,
      add: true
    },
    {
      value: 'downstream' as DirectedToValue,
      label: `${hopProviderName} → ${downstreamProviderName} & ITG Administrators`,
      add: downstreamProviderName !== ''
    },
    {
      value: 'upstream' as DirectedToValue,
      label: `${hopProviderName} ← ${upstreamProviderName} & ITG Administrators`,
      add: upstreamProviderName !== ''
    },
    {
      value: 'adminOnly' as DirectedToValue,
      label: 'Internal Admin',
      add: userType === userTypes.Admin
    },
    {
      value: 'oldUpstream' as DirectedToValue,
      label: `Old Upstream Provider & ITG Administrators`,
      add: addOldUpstreamComments
    },
    {
      value: 'oldDownstream' as DirectedToValue,
      label: `Old Downstream Provider & ITG Administrators`,
      add: addOldDownstreamComments
    }
  ].filter((v: DirectedValues) => v.add);

const AddHopComment: React.FC<IProps> = ({
  hopID,
  replyTo,
  isCallSourcePopulated,
  hopProviderName,
  upstreamProviderName,
  downstreamProviderName,
  getHopObject,
  user,
  uniqueId,
  forceDirectedTo
}) => {
  const navigate = useNavigate();
  const [relatedComment, setRelatedComment] = useState('');
  const [commentErrors, setCommentErrors] = useState('');
  const [attachments, setAttachments] = useState<File[]>([]);
  const [isModalComment, setIsModalComment] = useState(false);
  const [directedTo, setDirectedTo] = useState('');

  useEffect(() => {
    setCommentErrors(
      relatedComment && relatedComment.length < 6 ? 'Comment must have more than 5 characters.' : ''
    );
  }, [relatedComment]);

  const handleRelatedCommentInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) setRelatedComment(e.currentTarget.value);
  };

  const addFile = (files: File[]) => {
    setAttachments([
      ...attachments,
      ...files.filter((file) => !attachments.find((item) => item.name === file.name))
    ]);
  };

  const removeFile = (file: File) => {
    setAttachments(attachments.filter((item) => item.name !== file.name));
  };

  const submitComments = () => {
    if (
      !relatedComment ||
      relatedComment.length < 6 ||
      (!replyTo && !directedTo && !forceDirectedTo)
    )
      return;

    addCommentObject(
      replyTo
        ? {
            commentType: 'hop',
            contentText: relatedComment,
            relatedObjectId: replyTo.hopID,
            directedTo: replyTo.directedTo,
            replyToCommentID: replyTo.commentID
          }
        : {
            commentType: 'hop',
            contentText: relatedComment,
            relatedObjectId: hopID,
            directedTo: forceDirectedTo ? forceDirectedTo : directedTo
          },
      attachments
    ).then(() => {
      getHopObject(hopID);
      setIsModalComment(false);
      setRelatedComment('');
      setAttachments([]);
      if (forceDirectedTo) navigate('/hops');
    });
  };

  const commentToggle = () => {
    setIsModalComment((v) => !v);
  };

  return (
    <Fragment>
      <div className="flex-column">
        <div className="d-flex flex-row flex-wrap">
          <div className="hop-comment-input">
            <Label className="telecom-label p-0">
              {replyTo
                ? `Reply to ${replyTo.providerName ? replyTo.providerName : ''} `
                : 'New Comment'}
            </Label>
            <InputFormGroup
              isReadonly={false}
              isTextarea
              inputName="relatedComment"
              inputId="relatedComment"
              inputClassName="input-hop"
              inputValue={relatedComment}
              inputOnChange={handleRelatedCommentInputChange}
              inputPlaceholder="This section is only intended for submitting ancillary information. Please submit your traceback response using the form above. Thank you."
              inputAutoComplete="off"
            />
            {commentErrors && (
              <InputError className="telecom-input-error">{commentErrors}</InputError>
            )}
          </div>
          <div className="hop-comment-upload">
            <FormGroup>
              <Label className="telecom-label p-0">Attachments</Label>
              <DropZone
                uniqueId={uniqueId}
                attachments={attachments}
                removeFile={removeFile}
                addFile={addFile}
              />
            </FormGroup>
          </div>
        </div>
        {!replyTo && !forceDirectedTo && (
          <div className="hop-comment-send">
            {directedToValues(
              user.roleType,
              hopProviderName,
              upstreamProviderName,
              downstreamProviderName
            ).map((v) => {
              if (v.value !== 'oldUpstream') {
                return (
                  <div className="form-check form-check-inline" key={'addHop-' + v.value}>
                    <input
                      id={v.value}
                      type="radio"
                      name="commentType"
                      value={v.value}
                      className="form-check-input"
                      onChange={() => setDirectedTo(v.value)}
                    />
                    <label className="form-check-label" htmlFor={v.value}>
                      to {v.label}
                    </label>
                  </div>
                );
              }
            })}
          </div>
        )}
        <div className="d-flex justify-content-center">
          <Button
            className="telecom-btn"
            style={{ minWidth: '130px' }}
            onClick={() => (isCallSourcePopulated ? commentToggle() : submitComments())}
            disabled={
              !relatedComment ||
              relatedComment.length < 6 ||
              (!replyTo && !directedTo && !forceDirectedTo)
            }
          >
            Submit {replyTo ? 'Reply' : 'Comment'}
          </Button>
        </div>
      </div>

      <Modal
        centered
        className="submit-confirm-modal"
        isOpen={isModalComment}
        toggle={commentToggle}
      >
        <ModalBody>
          <h5 className="traceback-sub-title">
            This will only submit the comment provided. The Traceback Response will need to be
            submitted separately
          </h5>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <Button className="btn-default telecom-btn" color="light" onClick={commentToggle}>
            Cancel
          </Button>
          <Button className="telecom-btn red" onClick={() => submitComments()}>
            Proceed
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return { user: sm.user };
};

const mapActionsToProps = {
  getHopObject
};

export default connect(mapStateToProps, mapActionsToProps)(AddHopComment);
