import { SelectOption } from '../../../CustomSelect';

export const reportTypes: SelectOption[] = [
  {
    label: 'Campaign',
    value: 'CampaignReport'
  },
  {
    label: 'Provider',
    value: 'ProviderReport'
  },
  {
    label: 'Status',
    value: 'ReputationReport'
  },
  {
    label: 'Subpoena',
    value: 'SubpoenaReport'
  },
  {
    label: 'Query',
    value: 'QueryReport'
  },
  {
    label: 'Partner',
    value: 'PartnerReport'
  },
  {
    label: 'Rollup',
    value: 'RollupReport'
  },
  {
    label: 'SixtyDays',
    value: 'SixtyDaysReport'
  },
  {
    label: 'IP',
    value: 'IPReport'
  },
  {
    label: 'Quarterly',
    value: 'QuarterlyReport'
  },
  {
    label: 'Partner Source',
    value: 'PartnerSourceReport'
  },
  {
    label: 'TfSubpoena',
    value: 'TfSubpoenaReport'
  }
];

export const statusTypes: SelectOption[] = [
  {
    label: 'Done',
    value: 'done'
  },
  {
    label: 'In Progress',
    value: 'inProgress'
  },
  {
    label: 'With Errors',
    value: 'error'
  },
  {
    label: 'In Queue',
    value: 'queue'
  }
];
