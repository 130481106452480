import { Comment } from './comment';
import { NewHopProvider, NoteType } from './hop';
import { Provider } from './provider';
import { StirShakenResponse } from './stirShaken';

export interface TfHop {
  active: boolean;
  added: any;
  address: string;
  campaignId: number;
  campaignName: string;
  city: string;
  comments: Comment[];
  relatedComments: Comment[];
  completed: any;
  contactEmail: string;
  contactEmailValidation: any;
  contactName: string;
  contactPhone: string;
  country: string;
  create_date: any;
  customer: string;
  customerDetail: string;
  delegatedTo: string;
  description: string;
  downstreamTfHopId: number;
  downstreamProvider: Provider;
  elapsed: number;
  explanation: any;
  tfhopId: number;
  tfhopProvider: Provider;
  tfhopSequence: number;
  isCallOrig: boolean;
  isDomestic: boolean;
  isProviderITGMember: boolean;
  isUSPointEntry: boolean;
  locked: boolean;
  newProvider: NewHopProvider;
  nfNr: any;
  nfnrReason: any;
  noFoundExplanation: any;
  noInfoExplanation: any;
  notes: NoteType[];
  downstreamNotes: NoteType[];
  notified: any;
  providerId: number;
  state: string;
  status: number;
  tag: any;
  traceforwardId: number;
  update_date: any;
  updateFlag: any;
  upstreamTfHopId: number;
  upstreamProvider: Provider;
  zipCode: string;
  forwardedCall: string;
  ipAddress: string;
  customerType: string;
  averageTraffic: string;
  actionTaken: string;
  refuseReason: string;
  attachmentUrl: string;
  stirShaken: StirShakenResponse;
  labelId?: number;
  elapsedPoe: boolean;
  isCampaignLive: boolean;
}

export const EqualTfHops = (h1: TfHop, h2: TfHop) => {
  if (JSON.stringify(h1) === JSON.stringify(h2)) {
    return true;
  }
  h1.elapsed = h2.elapsed;
  h1.comments = h2.comments;
  h1.relatedComments = h2.relatedComments;
  h1.stirShaken = h2.stirShaken;
  return JSON.stringify(h1) === JSON.stringify(h2);
};
