import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Label, Row } from 'reactstrap';
import { Configuration, ReputationThreshold } from '../../../interfaces/configuration';
import { ProviderSummaryRow } from '../../../interfaces/provider';
import {
  ReportFormat,
  ReportPostData,
  ReportType,
  ReputationReportObject
} from '../../../interfaces/report';
import { getApiFormattedDate, getUtcStartDate } from '../../../lib/utilities';
import { getClientConfigurationInfo } from '../../../redux/appinfo/thunks';
import { getReportObject } from '../../../redux/report/thunks';
import { stateMappings } from '../../../redux/stateMappings';
import GeneralDatePicker from '../../GeneralDatePicker';

const TBEmailFormats = [
  {
    value: 0,
    label: 'None'
  },
  {
    value: 1,
    label: 'Formal'
  },
  {
    value: 2,
    label: 'Cooperating'
  },
  {
    value: 3,
    label: 'ITG Member'
  },
  {
    value: 4,
    label: 'Non-responsive'
  }
];

interface IProps {
  reportName: string;
  narrativeIntro: string;
  configuration: Configuration;
  report: ReputationReportObject;
  getReportObject: Function;
  getClientConfigurationInfo: Function;
}

const ReputationReport: React.FC<IProps> = ({
  reportName,
  narrativeIntro,
  configuration,
  report,
  getReportObject,
  getClientConfigurationInfo
}) => {
  const [reputationThresholds, setReputationThresholds] = useState<ReputationThreshold[]>([]);
  const [reportDate, setReportDate] = useState<string>(getUtcStartDate({ months: 1 }));
  const [markConfidential, setMarkConfidential] = useState(false);
  const [showData, setShowData] = useState(false);
  useEffect(() => {
    getClientConfigurationInfo();
  }, []);

  useEffect(() => {
    if (configuration.reputationThresholds && configuration.reputationThresholds.length > 0)
      setReputationThresholds(configuration.reputationThresholds.reverse());
  }, [configuration.reputationThresholds]);

  const getReport = (format: ReportFormat) => {
    getReportObject(
      {
        reportDate: getApiFormattedDate(reportDate),
        markConfidential,
        name: reportName,
        narrativeIntro
      } as ReportPostData,
      ReportType.ReputationReport,
      format
    );
  };

  const handleChange = (e: any, key: string) => {
    switch (key) {
      case 'markConfidential':
        setMarkConfidential((v) => !v);
        break;
      case 'json':
        setShowData(true);
        getReport(ReportFormat.Json);
        break;
      case 'word':
        getReport(ReportFormat.Word);
        break;
      case 'excel':
        getReport(ReportFormat.Excel);
        break;
    }
  };
  const providerSummaries = report.ProviderSummaries;

  const getNumberSummariesForThreshold = (color: string) => {
    return providerSummaries.filter(
      (providerSummary: ProviderSummaryRow) => providerSummary.provider?.reputation.color === color
    ).length;
  };

  return (
    <Fragment>
      <div className="row mb-4">
        <div className="telecom-label ps-3">Select Status Date</div>
        <GeneralDatePicker
          startDate={reportDate}
          setStartDate={setReportDate}
          containerClassname="col-md-2"
          onlyStartDate
        />
      </div>
      <div className="d-flex justify-content-md-center">
        <Button
          className="telecom-btn me-2"
          color="primary"
          style={{
            height: '28px',
            padding: '5px',
            fontSize: '14px',
            width: '150px'
          }}
          onClick={(option) => handleChange(option, 'json')}
        >
          {`Display Report`}
        </Button>
        <Button
          className="telecom-btn me-2"
          color="primary"
          style={{
            height: '28px',
            padding: '5px',
            fontSize: '14px',
            width: '150px'
          }}
          onClick={(option) => handleChange(option, 'word')}
        >
          {`Create Word Doc`}
        </Button>
        <Button
          className="telecom-btn me-2"
          color="primary"
          style={{
            height: '28px',
            padding: '5px',
            fontSize: '14px',
            width: '150px'
          }}
          onClick={(option) => handleChange(option, 'excel')}
        >
          {`Create .XLS`}
        </Button>
      </div>
      {showData &&
        providerSummaries &&
        providerSummaries.length > 0 &&
        reputationThresholds &&
        reputationThresholds.map((threshold: ReputationThreshold) =>
          getNumberSummariesForThreshold(threshold.symbolColor) ? (
            <Fragment key={`threshold-${threshold.lowerThreshold}`}>
              <Row className="me-4 ms-4 mt-4">
                <Col md="3">
                  <Row>
                    <Label className="traceback-label">{threshold.name}</Label>
                  </Row>
                </Col>
                {getRemainingHeaders()}
              </Row>

              {providerSummaries &&
                providerSummaries.length > 0 &&
                providerSummaries.map((providerSummaryRow: ProviderSummaryRow) => (
                  <Fragment key={`provider-${providerSummaryRow.provider?.providerId}`}>
                    {providerSummaryRow.provider?.reputation &&
                      providerSummaryRow.provider.reputation.color === threshold.symbolColor && (
                        <div style={{ width: '100%' }}>
                          <Row className="me-4 ms-4">
                            <Col md="3">
                              <Row>
                                <Label className="half-margin-left">
                                  {providerSummaryRow.provider.name}
                                </Label>
                              </Row>
                            </Col>
                            <Col>
                              <Row>
                                <Label className="half-margin-left">
                                  {providerSummaryRow.summary.numOfCampaigns}
                                </Label>
                              </Row>
                            </Col>
                            <Col>
                              <Row>
                                <Label className="half-margin-left">
                                  {providerSummaryRow.summary.numOfOriginHops}
                                </Label>
                              </Row>
                            </Col>
                            <Col>
                              <Row>
                                <Label className="half-margin-left">
                                  {providerSummaryRow.summary.numOfFPDHops}
                                </Label>
                              </Row>
                            </Col>
                            <Col>
                              <Row>
                                <Label className="half-margin-left">
                                  {providerSummaryRow.summary.numOfUSPoEHops}
                                </Label>
                              </Row>
                            </Col>
                            <Col>
                              <Row>
                                <Label className="half-margin-left">
                                  {providerSummaryRow.summary.numOfNoResponseHops}
                                </Label>
                              </Row>
                            </Col>
                            <Col>
                              <Row>
                                <Label className="half-margin-left">
                                  {providerSummaryRow.summary.numOfNotFoundHops}
                                </Label>
                              </Row>
                            </Col>
                            <Col>
                              <Row>
                                <Label className="half-margin-left">
                                  {TBEmailFormats[providerSummaryRow.provider.tbEmailFormat]
                                    ? TBEmailFormats[providerSummaryRow.provider.tbEmailFormat]
                                        .label
                                    : 'None'}
                                </Label>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      )}
                  </Fragment>
                ))}
            </Fragment>
          ) : null
        )}
    </Fragment>
  );
};

const getRemainingHeaders = () => (
  <Fragment>
    <Col>
      <Row>
        <Label className="traceback-label">Campaigns</Label>
      </Row>
    </Col>
    <Col>
      <Row>
        <Label className="traceback-label">Origin</Label>
      </Row>
    </Col>
    <Col>
      <Row>
        <Label className="traceback-label">FPD</Label>
      </Row>
    </Col>
    <Col>
      <Row>
        <Label className="traceback-label">PoE</Label>
      </Row>
    </Col>
    <Col>
      <Row>
        <Label className="traceback-label">No Response</Label>
      </Row>
    </Col>
    <Col>
      <Row>
        <Label className="traceback-label">Not Found</Label>
      </Row>
    </Col>
    <Col>
      <Row>
        <Label className="traceback-label">Email Format</Label>
      </Row>
    </Col>
  </Fragment>
);

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    report: sm.report.report,
    configuration: sm.appinfo.configuration
  };
};

const mapActionsToProps = {
  getReportObject,
  getClientConfigurationInfo
};

export default connect(mapStateToProps, mapActionsToProps)(ReputationReport);
