import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Label } from 'reactstrap';
import ProvidersSelect from '../../../components/ProvidersSelect';
import {
  ReportFormat,
  ReportPostData,
  ReportType,
  TracebackList,
  TracebackReportResponse
} from '../../../interfaces/report';
import { getApiFormattedDate, getUtcEndDate, getUtcStartDate } from '../../../lib/utilities';
import { getReportObject } from '../../../redux/report/thunks';
import { stateMappings } from '../../../redux/stateMappings';
import { SelectOption } from '../../CustomSelect';
import TracebackTile from './TracebackTile';
import GeneralDatePicker from '../../GeneralDatePicker';

interface IProps {
  reportName: string;
  narrativeIntro: string;
  report: TracebackReportResponse;
  getReportObject: Function;
}

const ProviderReport: React.FC<IProps> = ({
  reportName,
  narrativeIntro,
  report,
  getReportObject: getReportObject2
}) => {
  const [startDate, setStartDate] = useState<string>(getUtcStartDate({ months: 1 }));
  const [endDate, setEndDate] = useState<string>(getUtcEndDate());
  const [provider, setProvider] = useState<SelectOption>();
  const [markConfidential, setMarkConfidential] = useState(true);
  const [includeFullCallPath, setIncludeFullCallPath] = useState(true);
  const [obscurePhoneNumbers, setObscurePhoneNumbers] = useState(false);
  const [removeComments, setRemoveComments] = useState(false);

  const getReportObject = (format: ReportFormat) => {
    if (provider)
      getReportObject2(
        {
          startDate: getApiFormattedDate(startDate),
          endDate: getApiFormattedDate(endDate),
          includeFullCallPath,
          markConfidential,
          name: reportName,
          narrativeIntro,
          obscurePhoneNumbers,
          providerId: provider?.value,
          removeComments
        } as ReportPostData,
        ReportType.ProviderReport,
        format
      );
  };
  const handleCheck = (e: any, key: string) => {
    switch (key) {
      case 'provider':
        setProvider(e);
        break;
      case 'includeFullCallPath':
        setIncludeFullCallPath((v) => !v);
        break;
      case 'obscurePhoneNumbers':
        setObscurePhoneNumbers((v) => !v);
        break;
      case 'removeComments':
        setRemoveComments((v) => !v);
        break;
      case 'markConfidential':
        setMarkConfidential((v) => !v);
        break;
      case 'json':
        getReportObject(ReportFormat.Json);
        break;
      case 'word':
        getReportObject(ReportFormat.Word);
        break;
      case 'excel':
        getReportObject(ReportFormat.Excel);
        break;
    }
  };
  return (
    <Fragment>
      <Label className="telecom-label ps-0">Provide The Date Range *</Label>
      <div className="d-flex flex-row mb-4">
        <GeneralDatePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          placeholderTextStart="&#xf133; mm/dd/yyyy"
          placeholderTextEnd="&#xf133; mm/dd/yyyy"
          clearButtonTitle={'Clear'}
          isClearable
          reportStyle
        />
      </div>
      <div className="telecom-label ps-0">Select a Provider *</div>
      <div className="ps-0 mb-4 col-2">
        <ProvidersSelect
          onChange={(option) => handleCheck(option, 'provider')}
          isSearchable
          option={provider ? provider.value : 0}
          placeholder="Select a Provider..."
          includeInactive
        />
      </div>
      <div className="me-0 mb-4 d-flex">
        <label className="form-label tile-label">
          Include Full Traceback Call Paths
          <input
            type="checkbox"
            onChange={(option) => handleCheck(option, 'includeFullCallPath')}
            checked={includeFullCallPath}
          />
          <span className="checkmark tile-checkmark" />
        </label>
        <label className="form-label tile-label" style={{ fontSize: '15px' }}>
          Partially Obscure Phone Numbers
          <input
            type="checkbox"
            onChange={(option) => handleCheck(option, 'obscurePhoneNumbers')}
            checked={obscurePhoneNumbers}
          />
          <span className="checkmark tile-checkmark" />
        </label>
        <label className="form-label tile-label" style={{ fontSize: '15px' }}>
          Without Per-Hop Comments
          <input
            type="checkbox"
            onChange={(option) => handleCheck(option, 'removeComments')}
            checked={removeComments}
          />
          <span className="checkmark tile-checkmark" />
        </label>
        <label className="form-label tile-label" style={{ fontSize: '15px' }}>
          Mark as Confidential
          <input
            type="checkbox"
            onChange={(option) => handleCheck(option, 'markConfidential')}
            checked={markConfidential}
          />
          <span className="checkmark tile-checkmark" />
        </label>
      </div>
      <div className="d-flex justify-content-md-center">
        {report ? (
          <Button
            className="telecom-btn me-2"
            color="primary"
            style={{
              height: '28px',
              padding: '5px',
              fontSize: '14px',
              width: '150px'
            }}
            onClick={(option) => handleCheck(option, 'json')}
          >
            {`Update Report Display`}
          </Button>
        ) : (
          <Button
            className="telecom-btn me-2"
            color="primary"
            disabled={!provider}
            style={{
              height: '28px',
              padding: '5px',
              fontSize: '14px',
              width: '150px'
            }}
            onClick={(option) => handleCheck(option, 'json')}
          >
            {`Display Report`}
          </Button>
        )}
        <Button
          className="telecom-btn me-2"
          color="primary"
          disabled={!provider}
          style={{
            height: '28px',
            padding: '5px',
            fontSize: '14px',
            width: '150px'
          }}
          onClick={(option) => handleCheck(option, 'word')}
        >
          {`Create Word Doc`}
        </Button>
        <Button
          className="telecom-btn me-2"
          color="primary"
          disabled={!provider}
          style={{
            height: '28px',
            padding: '5px',
            fontSize: '14px',
            width: '150px'
          }}
          onClick={(option) => handleCheck(option, 'excel')}
        >
          {`Create .XLS`}
        </Button>
      </div>
      <div className="m-4" style={{ borderBottom: '1px solid #ccc' }} />
      <div>
        {provider?.value &&
          report &&
          report.TracebackList &&
          report.TracebackList.map((tracebackList: TracebackList) => {
            return (
              <div key={`traceback-${tracebackList.traceback.tracebackId}`}>
                <TracebackTile
                  campaignFirstSeen={tracebackList.campaignCreateDate}
                  mostRecentTraceback={
                    tracebackList.TracebackWindow.mostRecentTracebackDateInWindow
                  }
                  traceback={tracebackList.traceback}
                  hops={tracebackList.hops}
                  callingParty={tracebackList.callingParty}
                />
              </div>
            );
          })}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return { report: sm.report.report };
};

const mapActionsToProps = { getReportObject };

export default connect(mapStateToProps, mapActionsToProps)(ProviderReport);
