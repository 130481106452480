import React, { Fragment } from 'react';
import { userTypes } from '../../enum/userTypes';
import { TfHop } from '../../interfaces/tfhop';
import { Traceforward } from '../../interfaces/traceforward';
import { PersonalInfo } from '../../interfaces/user';
import ProviderPanel from '../ProviderPanel';

interface IProps {
  tfhop: TfHop;
  traceforward: Traceforward;
  user: PersonalInfo;
}

const TfHopNavigationRow: React.FC<IProps> = ({ tfhop, traceforward, user }) => {
  // Open Hops
  const isOpen = tfhop.status === 1;
  // Exclude Open or Deleted Hops
  const isCompleted = tfhop.status !== 1 && tfhop.status !== 5;
  return (
    <div className="d-flex flex-row justify-content-between mb-3 ">
      <div className="col">
        {isOpen || (!isOpen && tfhop.downstreamProvider) ? (
          <Fragment>
            <label className="telecom-label ms-2">Call Sent To</label>
            <div className="d-flex aling-items-left">
              {!tfhop.downstreamProvider && isOpen ? (
                <span className="span-cell blue bold me-2 text-nowrap">PENDING</span>
              ) : traceforward.lastTfHopId === tfhop.downstreamTfHopId ? null : null}
              {tfhop.downstreamProvider &&
                tfhop.downstreamProvider.providerId !== 0 &&
                (user.roleType === userTypes.Admin ? (
                  <Fragment>
                    <a
                      href={`/tfhops/tfhop/${tfhop.downstreamTfHopId}`}
                      className="span-cell blue me-2 ms-2 text-nowrap"
                    >
                      TFHOP{` `}
                      {tfhop.tfhopSequence + 1}
                    </a>
                    <ProviderPanel
                      provider={tfhop.downstreamProvider}
                      includeReputation
                      hyperlink
                    />
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="me-2 ms-2 text-nowrap">
                      HOP{` `}
                      {tfhop.tfhopSequence + 1}
                    </div>
                    <ProviderPanel provider={tfhop.downstreamProvider} includeReputation />
                  </Fragment>
                ))}
            </div>
          </Fragment>
        ) : (
          ''
        )}
      </div>
      <div className="col">
        <label className="telecom-label text-center">This Hop's Provider</label>
        <div className="d-flex justify-content-center">
          {/*Terminating | Originating | Intermediate*/}
          {tfhop.tfhopSequence === 1
            ? null
            : !tfhop.upstreamProvider &&
                isCompleted &&
                tfhop.tfhopSequence !== 1 &&
                tfhop.status !== 6
              ? null
              : null}
          <span className="pe-2 ms-2 text-nowrap">HOP {tfhop.tfhopSequence}</span>
          {user.roleType === userTypes.Admin ? (
            <ProviderPanel provider={tfhop.tfhopProvider} hyperlink includeReputation />
          ) : (
            <ProviderPanel
              provider={tfhop.tfhopProvider}
              linkOverride={`/providers/provider/summaries/${tfhop.tfhopProvider.providerId}`}
              includeReputation
            />
          )}
        </div>
      </div>
      <div className="col">
        {tfhop.tfhopSequence !== 1 && (
          <Fragment>
            <label className="telecom-label text-end ms-2">Call Received From</label>
            <div style={{ display: 'flex', alignItems: 'right', justifyContent: 'flex-end' }}>
              {tfhop.tfhopSequence !== 1 &&
                (user.roleType === userTypes.Admin ? (
                  <Fragment>
                    <a
                      href={`/tfhops/tfhop/${tfhop.upstreamTfHopId}`}
                      className="span-cell blue me-2 ms-2 text-nowrap"
                    >
                      HOP{` `}
                      {tfhop.tfhopSequence - 1}
                    </a>
                    <ProviderPanel provider={tfhop.upstreamProvider} hyperlink includeReputation />
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="me-2 ms-2">
                      HOP{` `}
                      {tfhop.tfhopSequence - 1}
                    </div>
                    <ProviderPanel provider={tfhop.upstreamProvider} includeReputation />
                  </Fragment>
                ))}
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default TfHopNavigationRow;
