export enum DateFormat {
  ShortDate = 'MMM d, yyyy',
  ShortBoth = "yyyy-MM-dd HH:mm 'UTC'",
  MediumBoth = "MMM d, yyyy HH:mm 'UTC'",
  LongBoth = "yyyy-MM-dd HH:mm 'UTC'",
  MediumBothNoUTC = 'MMM d, yyyy HH:mm',
  Report = 'yyyyMMddHHmmss',
  DBDate = 'yyyy-MM-dd HH:mm:ss',
  StartMonthDate = 'yyyy-MM-01 HH:mm:ss',
  YearDate = 'yyyy',
  MonthDate = 'MMMM',
  ShortDay = 'MMM d',
  MonthYear = 'MM/yyyy'
}
