import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Pagination, defaultPagination } from '../../../../interfaces/pagination';
import { ReportArchiveObj, ReportType } from '../../../../interfaces/report';
import { getApiFormattedDate, getUtcEndDate, getUtcStartDate } from '../../../../lib/utilities';
import { getCampaignNamesApiCall } from '../../../../redux/appinfo/apiCalls';
import { getProviderNameList } from '../../../../redux/provider/thunks';
import { getInProgressReportArchivesIDs } from '../../../../redux/report/apiCalls';
import { downloadReportArchiveApi, getReportArchiveList } from '../../../../redux/report/thunks';
import { stateMappings } from '../../../../redux/stateMappings';
import CustomDataTable from '../../../CustomDataTable';
import CustomSelect, { SelectOption } from '../../../CustomSelect';
import GeneralDatePicker from '../../../GeneralDatePicker';
import { ProviderItem } from '../../../ProvidersSelect';
import ExpandableReport from './ExpandableRow';
import columns from './columns';
import { reportTypes, statusTypes } from './data';

interface IProps {
  reports: ReportArchiveObj[];
  paginationTotalRows: number;
  getReportArchiveList: Function;
  downloadReportArchiveApi: Function;
  providerNameList: ProviderItem[];
  getProviderNameList: Function;
}

const ReportsArchive: React.FC<IProps> = ({
  reports,
  paginationTotalRows,
  getReportArchiveList,
  downloadReportArchiveApi,
  providerNameList,
  getProviderNameList
}) => {
  const [startDate, setStartDate] = useState<string>(getUtcStartDate({ days: 29 }));
  const [endDate, setEndDate] = useState<string>(getUtcEndDate());
  const [status, setStatus] = useState('');
  const [type, setType] = useState<ReportType | undefined>();
  const [onlyGeneratedByMe, setOnlyGeneratedByMe] = useState(true);

  const [paginationParams, setPaginationParams] = useState({
    ...defaultPagination(),
    sort: 'create_date',
    order: 'desc'
  });

  const [campaignsNameList, setCampaignsNameList] = useState<SelectOption[]>([]);

  const [, setInProgressTimeOut] = useState<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    if (!providerNameList || providerNameList.length == 0) getProviderNameList();
  }, [providerNameList]);

  useEffect(() => {
    getCampaignNamesApiCall()
      .then((result: any) => {
        setCampaignsNameList(
          result.data.data.map((v: any) => ({ label: v.name, value: v.campaignId }))
        );
      })
      .catch((error: any) => {
        console.log('Error:', error);
      });
    return () => {
      setInProgressTimeOut((v) => {
        if (v !== null) clearInterval(v);
        return null;
      });
    };
  }, []);

  const getData = () =>
    getReportArchiveList(paginationParams, {
      startDate: getApiFormattedDate(startDate),
      endDate: getApiFormattedDate(endDate),
      status,
      onlyGeneratedByMe,
      type
    });

  useEffect(() => {
    if (reports) {
      setInProgressTimeOut((v) => {
        if (v !== null) clearTimeout(v);
        const reportsInProgress = reports.filter((v) => v.status === 'inProgress');
        if (reportsInProgress.length > 0)
          return setInterval(async () => {
            try {
              const inProgressData = await getInProgressReportArchivesIDs();
              const inProgress = inProgressData.data || [];
              const currentInProgress = reportsInProgress.filter((v) =>
                inProgress.some((i: number) => v.id === i)
              );
              if (currentInProgress.length != reportsInProgress.length) getData();
            } catch (error: any) {
              console.log(error);
            }
          }, 10000);
        return null;
      });
    }
  }, [reports]);
  useEffect(() => {
    getData();
  }, [startDate, endDate, status, type, paginationParams, onlyGeneratedByMe]);

  const updatePagination = (params: Pagination) => {
    setPaginationParams({ ...paginationParams, ...params });
  };

  return (
    <Fragment>
      <div className="row mt-4">
        <div className="col-md-2">
          <div className="telecom-label ps-0">Start Date </div>
        </div>
        <div className="col-md-2">
          <div className="telecom-label ps-0">End Date </div>
        </div>
        <div className="col-md-2">
          <div className="telecom-label ps-0">Report Type </div>
        </div>
        <div className="col-md-2">
          <div className="telecom-label ps-0">Report Status </div>
        </div>
      </div>
      <div className="row mb-3">
        <GeneralDatePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          defaultStartDate={getUtcStartDate({ days: 29 })}
          defaultEndDate={getUtcEndDate()}
          placeholderTextStart="&#xf133; startDate"
          placeholderTextEnd="&#xf133; endDate"
          containerClassname="col-md-2"
          clearButtonTitle={'Clear'}
          isClearable
          showYearDropdown
          yearDropdown
          scrollableYearDropdown
        />
        <div className="col-md-2">
          <CustomSelect
            selectedOption={type}
            setSelectedOption={(e: any) => setType(e ? e.value : null)}
            className={'customselect-small'}
            getOptions={reportTypes}
            placeholder={'Select Report Type'}
            typeLabel={''}
            isClearable
          />
        </div>
        <div className="col-md-2">
          <CustomSelect
            selectedOption={status}
            setSelectedOption={(e: any) => setStatus(e ? e.value : null)}
            className={'customselect-small'}
            getOptions={statusTypes}
            placeholder={'Select Report status'}
            typeLabel={''}
            isClearable
          />
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-start">
            <input
              type="checkbox"
              id="low_volume"
              name="low_volume"
              className="mt-1 ps-2"
              checked={onlyGeneratedByMe}
              onChange={() => setOnlyGeneratedByMe((v) => !v)}
            />
            <label htmlFor="low_volume" className="label-bold ps-2">
              Generated By Me
            </label>
          </div>
        </div>
      </div>
      <CustomDataTable
        tableData={reports}
        columns={columns(downloadReportArchiveApi, getData)}
        defaultSortFieldId={paginationParams.sort}
        defaultSortAsc={false}
        defaultPage={paginationParams.page}
        defaultPageSize={paginationParams.pageSize}
        pagination={true}
        paginationTotalRows={paginationTotalRows}
        useExpandableRows={true}
        useExpandableRowsComponent={({ data }) => (
          <ExpandableReport
            data={data}
            providerNameList={providerNameList}
            campaignsNameList={campaignsNameList}
          />
        )}
        updatePaginationParams={updatePagination}
      />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    paginationTotalRows: sm.report.archivedReportsMeta.TotalCount,
    reports: sm.report.archivedReportsList,
    providerNameList: sm.provider.providerNames
  };
};

const mapActionsToProps = {
  downloadReportArchiveApi,
  getReportArchiveList,
  getProviderNameList
};

export default connect(mapStateToProps, mapActionsToProps)(ReportsArchive);
