export enum AppInfo {
  GET_CLIENT_CONFIGURATION = 'GET_CLIENT_CONFIGURATION',
  GET_REPUTATION_INFO = 'GET_REPUTATION_INFO',
  GET_CAMPAIGN_NAMES = 'GET_CAMPAIGN_NAMES',
  GET_USER_NAMES = 'GET_USER_NAMES',
  GET_ORIGINATOR_CNAM_OPTIONS = 'GET_ORIGINATOR_CNAM_OPTIONS',
  GET_ORIGINATOR_CARRIER_OPTIONS = 'GET_ORIGINATOR_CARRIER_OPTIONS',
  GET_DESTINATION_CARRIER_OPTIONS = 'GET_DESTINATION_CARRIER_OPTIONS',
  SET_APPINFO_LOADING_STATUS = 'SET_APPINFO_LOADING_STATUS',
  SET_APPINFO__ERROR_MESSAGE = 'SET_APPINFO__ERROR_MESSAGE',
  GET_CRONJOBS_STATS = 'GET_CRONJOBS_STATS'
}
