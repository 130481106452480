import React, { FC } from 'react';
import { TableColumn } from 'react-data-table-component';
import ColumnTooltip from '../../components/ColumnTooltip';
import ProviderPanel from '../../components/ProviderPanel';
import { Notes } from '../../components/shared/Notes';
import SignerHopCellContent from '../../components/stirShaken/SignerHopCellContent';
import { DateFormat } from '../../enum/DateFormat';
import { Hop } from '../../interfaces/hop';
import { getClientFormattedDate, getElapsedTime } from '../../lib/utilities';

export const hopsColumns = (
  includeLinks: boolean,
  isTraceback: boolean,
  isProvider: boolean,
  statuses: { [key: number]: string }
): TableColumn<Hop>[] => [
  {
    name: 'Hop',
    selector: () => 'hopId',
    id: 'column-hopId',
    sortable: false,
    width: '80px',
    cell: (hop: Hop) => (
      <div
        className="table-cell blue center"
        onClick={() => (window.location.href = `/hops/hop/${hop.hopId}`)}
      >
        <a href={`/hops/hop/${hop.hopId}`}>{hop.hopSequence}</a>
      </div>
    )
  },
  {
    name: 'Notes',
    selector: () => 'notes',
    id: 'column-notes',
    sortable: false,
    width: '150px',
    cell: (hop: Hop) => (
      <div className="table-cell center">
        <a href={`/hops/hop/${hop.hopId}`}>
          <Notes types={hop.notes} />
        </a>
      </div>
    )
  },
  {
    name: 'Traceback',
    selector: () => 'tracebackId',
    id: 'column-tracebackId',
    sortable: true,
    width: '100px',
    omit: isTraceback || isProvider,
    cell: (hop: Hop) => (
      <div
        className="table-cell blue center"
        onClick={() => (window.location.href = `/hops/hop/${hop.hopId}`)}
      >
        <a href={`/tracebacks/traceback/${hop.tracebackId}`}>{hop.tracebackId}</a>
      </div>
    )
  },
  {
    name: ' Traceback ',
    selector: () => 'tracebackId',
    id: 'column-tracebackId2',
    sortable: true,
    width: '100px',
    omit: !isProvider,
    cell: (hop: Hop) => <div className="table-cell gray center">{hop.tracebackId}</div>
  },
  {
    name: 'Campaign',
    selector: () => 'campaignName',
    id: 'column-campaignName',
    sortable: true,
    grow: 3,
    omit: isTraceback || isProvider,
    cell: (hop: Hop) => (
      <div
        className="table-cell blue"
        onClick={() => (window.location.href = `/hops/hop/${hop.hopId}`)}
      >
        <a href={`/campaigns/campaign/${hop.campaignId}`}>{hop.campaignName}</a>
      </div>
    )
  },
  {
    name: ' Campaign ',
    selector: () => 'campaignName',
    id: 'column-campaignName2',
    sortable: true,
    grow: 3,
    omit: !isProvider,
    cell: (hop: Hop) => <div className="table-cell center gray">{hop.campaignName}</div>
  },
  {
    name: 'Provider',
    selector: () => 'hopProviderName',
    id: 'column-hopProviderName',
    sortable: true,
    grow: 3,
    omit: isProvider,
    cell: (hop: Hop) => (
      <ProviderPanel
        includeReputation
        hyperlink={includeLinks}
        provider={hop.hopProvider}
        rmdFlag={hop.rmdFlag}
      />
    )
  },
  {
    name: 'Received Call From',
    selector: () => 'upstreamProviderName',
    id: 'column-upstreamProviderName',
    sortable: true,
    grow: 3,
    omit: isTraceback,
    cell: (hop: Hop) => (
      <div className="tabel cell">
        <Notes types={hop.upstreamNotes} />
        <ProviderPanel
          includeReputation
          hyperlink={includeLinks}
          provider={hop.upstreamProvider}
          rmdFlag={hop.upstreamRMDFlag}
        />
      </div>
    )
  },
  {
    name: 'Signer',
    selector: () => 'signerProviderName',
    id: 'column-signerProviderName',
    sortable: true,
    grow: 3,
    cell: (hop: Hop) => <SignerHopCellContent hop={hop} includeLinks={includeLinks} />
  },
  {
    name: 'Attestation',
    selector: () => 'attest',
    id: 'column-attest',
    sortable: false,
    grow: 1,
    cell: (hop: Hop) => (
      <div className="table-cell center">
        {hop.stirShaken && <span>{hop.stirShaken.attest}</span>}
      </div>
    )
  },
  {
    name: 'Sent Call To',
    selector: () => 'downstreamProviderName',
    id: 'column-downstreamProviderName',
    sortable: true,
    grow: 3,
    omit: !isProvider,
    cell: (hop: Hop) => (
      <ProviderPanel
        includeReputation
        hyperlink={includeLinks}
        provider={hop.downstreamProvider}
        rmdFlag={hop.downstreamRMDFlag}
      />
    )
  },
  {
    name: 'Completed',
    selector: () => 'completed',
    id: 'column-completed',
    sortable: true,
    width: '150px',
    cell: (hop: any) => {
      if (hop.completed) {
        const hopStatus = statuses[hop.status];
        if (
          hopStatus === 'OPEN' ||
          hopStatus === 'PENDING APPROVAL' ||
          hopStatus === 'PENDING DISPUTE'
        ) {
          return (
            <div
              className="table-cell blue bold center"
              onClick={() => (window.location.href = `/hops/hop/${hop.hopId}`)}
            >
              <a href={`/hops/hop/${hop.hopId}`}>{hopStatus}</a>
            </div>
          );
        }
        return (
          <div className="table-cell center">
            {getClientFormattedDate(hop.completed, DateFormat.ShortDate)}
          </div>
        );
      }
      return null;
    }
  },
  {
    name: 'Response Time',
    selector: () => 'elapsed',
    id: 'column-elapsed',
    sortable: true,
    grow: 2,
    cell: (hop: Hop) => <HopElapsedColumn hop={hop} />
  }
];

export const HopElapsedColumn: FC<{ hop: Hop }> = ({ hop }) => (
  <div className="table-cell center">
    {hop.fccResponse ? (
      <ColumnTooltip tooltipVisible={hop.fccResponse} columnType="hopElapsed">
        <label className={'text-red font-weight-bold'}>
          {getElapsedTime(hop.elapsed)}
          <i className="fa fa-clock ms-1" />
        </label>
      </ColumnTooltip>
    ) : (
      <span className="table-cell gray center">
        {hop.elapsed ? getElapsedTime(hop.elapsed) : 'None'}
      </span>
    )}
  </div>
);
