import { sub } from 'date-fns';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Label } from 'reactstrap';
import { InsightFinalScoreResult } from '../../../interfaces/insights';
import { Pagination, defaultPagination } from '../../../interfaces/pagination';
import {
  decodeQueryParams,
  encodeQueryParams,
  getFromLocalStorage,
  saveToLocalStorage
} from '../../../lib/history-utils';
import { getInsights } from '../../../redux/insights/thunks';
import { stateMappings } from '../../../redux/stateMappings';
import CustomDataTable from '../../CustomDataTable';
import ProvidersSelect from '../../ProvidersSelect';
import { columns } from './columns';
import InsightsChart from '../../Charts/InsightsChart';
import { utcNow } from '../../../lib/utilities';

interface IProps {
  insights: InsightFinalScoreResult[];
  paginationTotalRows: number;
  getInsights: Function;
}

const PAGENAME = 'insights';

const Insights: FC<IProps> = ({
  insights,
  paginationTotalRows,
  getInsights: getInsightsSigners
}) => {
  const searchParams = getFromLocalStorage(PAGENAME) || '';
  const savedSearchDetails = decodeQueryParams(PAGENAME, searchParams);

  const [offset, setOffset] = useState(0);
  const [isITG, setIsITG] = useState(!!savedSearchDetails.filterElements?.isITG);
  const [selectedProvider, setSelectedProvider] = useState<number>();
  const [paginationParams, setPaginationParams] = useState<Pagination>(
    savedSearchDetails.paginationParams || {
      ...defaultPagination(),
      sort: 'd.id'
    }
  );

  const columnNames = useMemo(() => {
    if (!insights || insights.length === 0 || !insights[0]) return [];
    return insights[0].quarters.map((v) => v.name);
  }, [insights, offset]);

  const handleCheckBox = () => setIsITG((v) => !v);
  const updatePagination = (params: Pagination) => {
    if (
      paginationParams.page === params.page &&
      paginationParams.order === params.order &&
      paginationParams.sort === params.sort &&
      paginationParams.pageSize === params.pageSize
    ) {
      return;
    }
    setPaginationParams({ ...paginationParams, ...params });
  };

  useEffect(() => {
    const defaultSearchParams: Pagination = { ...paginationParams, page: 1 };
    const filterElements = { offset, isITG, providerID: selectedProvider };
    const searchParams = encodeQueryParams(
      PAGENAME,
      { paginationParams: defaultSearchParams, filterElements },
      {}
    );
    saveToLocalStorage(PAGENAME, searchParams);
    getInsightsSigners(paginationParams, filterElements);
  }, [paginationParams, isITG, offset, selectedProvider]);

  const getNextOffset = () => {
    if (sub(utcNow(), { months: 3 * offset }).getUTCFullYear() > 2018) {
      setOffset((v) => v + 10);
      setPaginationParams((v) => ({ ...v, sort: 'd.id' }));
    }
  };

  const getCurrentOffset = () => {
    setOffset(0);
    setPaginationParams((v) => ({ ...v, sort: 'd.id' }));
  };

  const getPrevOffset = () => {
    setOffset((v) => Math.max(v - 10, 0));
    setPaginationParams((v) => ({ ...v, sort: 'd.id' }));
  };

  return (
    <Card>
      <CardHeader>
        <Label className="telecom-label mb-3" style={{ fontSize: '20px' }}>
          Insights
        </Label>
        <InsightsChart />
        <div className="d-flex justify-content-between ps-5 pe-5">
          <div className="row">
            <Col style={{ minWidth: '150px', maxWidth: '300px' }}>
              <ProvidersSelect
                onChange={(e: any) => {
                  setSelectedProvider(e?.value || 0);
                }}
                option={selectedProvider}
                addAllItem={true}
                includeInactive
              />
            </Col>
            <Col>
              <label className="form-label tile-label" style={{ fontSize: '15px' }}>
                ITG
                <input type="checkbox" onChange={() => handleCheckBox()} checked={isITG} />
                <span className="checkmark tile-checkmark" />
              </label>
            </Col>
          </div>
          <div className="d-flex justify-content-center text-large">
            <span>
              <span
                style={{ cursor: offset ? 'pointer' : '' }}
                onClick={getPrevOffset}
                className={!offset ? 'mx-1 text-light-grey text-xlarge' : 'mx-1 text-xlarge'}
              >
                &lt;
              </span>
              <span
                style={{ cursor: offset ? 'pointer' : '' }}
                onClick={getCurrentOffset}
                className={!offset ? 'mx-1 text-light-grey text-xlarge' : 'mx-1 text-xlarge'}
              >
                &#9673;
              </span>
              <span
                style={{
                  cursor:
                    sub(utcNow(), { months: 3 * offset }).getUTCFullYear() > 2018 ? 'pointer' : ''
                }}
                onClick={getNextOffset}
                className={
                  sub(utcNow(), { months: 3 * offset }).getUTCFullYear() > 2018
                    ? 'mx-1 text-xlarge'
                    : 'mx-1 text-light-grey text-xlarge'
                }
              >
                &gt;
              </span>
            </span>
          </div>
        </div>
      </CardHeader>
      <CardBody className="card-body-traceback">
        <CustomDataTable
          columns={columns(columnNames)}
          defaultSortFieldId={paginationParams.sort}
          defaultSortAsc={paginationParams.order === 'asc'}
          defaultPage={paginationParams.page}
          defaultPageSize={paginationParams.pageSize}
          pagination={true}
          paginationTotalRows={paginationTotalRows}
          tableData={insights}
          updatePaginationParams={updatePagination}
        />
      </CardBody>
    </Card>
  );
};
const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    insights: sm.insight.insights,
    paginationTotalRows: sm.insight.meta.TotalCount
  };
};
const mapActionsToProps = { getInsights };

export default connect(mapStateToProps, mapActionsToProps)(Insights);
