//This file holds the API calls that hit the /user route for DRY purposes
import { ApiRequest } from '../../lib/apiRequest';

export const getClientConfigurationApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `/AppInfo/System/configuration`
  });

export const getCampaignNamesApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `/AppInfo/System/CampaignNames`
  });

export const getOriginatorCNamOptionsApiCall = async (incidentCampaignId: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `/AppInfo/System/originator-cnam-options/${incidentCampaignId}`
  });

export const getOriginatorCarrierOptionsApiCall = async (incidentCampaignId: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `/AppInfo/System/originator-carrier-options/${incidentCampaignId}`
  });

export const getDestinationCarrierOptionsApiCall = async (incidentCampaignId: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `/AppInfo/System/destination-carrier-options/${incidentCampaignId}`
  });

export const getCronJobsStatsApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `/tasks`
  });

export const executeCronJob = async (taskName: string) =>
  ApiRequest.request({
    method: 'PUT',
    url: `/tasks/${taskName}`
  });
