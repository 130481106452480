import React, { Fragment, useState } from 'react';
import { DateFormat } from '../../enum/DateFormat';
import { StirShakenResponse } from '../../interfaces/stirShaken';
import { Traceback } from '../../interfaces/traceback';
import { PersonalInfo } from '../../interfaces/user';
import { DnoToolTip } from '../../lib/dno';
import { getClientFormattedDate, getElapsedTime } from '../../lib/utilities';
import CustomToolTip from '../CustomToolTip';
import BasisForTraceback from '../HopDetail/BasisForTraceback';
import StirShakenRow from '../StirShakenRow';
import CommentComponent from '../shared/CommentComponent';

interface IProps {
  traceback: Traceback;
  tracebackStatuses?: { [key: number]: string };
  user: PersonalInfo;
}

const TracebackView: React.FC<IProps> = ({ traceback, tracebackStatuses, user }) => {
  const { campaignName, tracebackTime, status, elapsed, numberOfHops, source, hopsStirShaken } =
    traceback;
  const [isStirShakenVisible, setIsStirShakenVisible] = useState(
    !!(traceback.stirShaken || (hopsStirShaken && hopsStirShaken.length))
  );
  return (
    <div>
      <div className="traceback-view">
        <div className="row">
          <div className="col-12 col-md-2">
            <div className="label-bold">Status</div>
            <p className="telecom-text pt-1">
              {tracebackStatuses && tracebackStatuses[status] ? tracebackStatuses[status] : 'None'}
            </p>
          </div>
          <div className="col-12 col-md-3">
            <div className="label-bold">Campaign Name</div>
            <p className="telecom-text blue pt-1">
              <a className="blue" href={`/campaigns/campaign/${traceback.campaignId}`}>
                {campaignName}
              </a>
            </p>
          </div>
          <div className="col-12 col-md-3">
            <div className="label-bold">Traceback Source</div>
            <p className="telecom-text blue pt-1">{source !== '' ? source : 'None'}</p>
          </div>
          <div className="col-6 col-md-2">
            <div className="label-bold center">Hops</div>
            <p className="telecom-text blue center pt-1">
              <a className="blue" href={`/hops?tracebackId=${traceback.tracebackId}`}>
                {numberOfHops}
              </a>
            </p>
          </div>
          <div className="col-6 col-md-2">
            <div className="label-bold center">Elapsed Time</div>
            <p className="telecom-text center pt-1">{getElapsedTime(elapsed)}</p>
          </div>
        </div>
        <div className="row">
          <h5 className="col-12 col-md-12 p-0 traceback-sub-title">Call Details</h5>
          <div className="col-12 col-md-4">
            <div className="label-bold">Called Number</div>
            <p className="telecom-text pt-1">
              <span className="fa fa-phone red me-1" />
              {traceback.calledTN}
            </p>
            <div className="label-bold">Called Country</div>
            <p className="telecom-text">{traceback.calledCountry}</p>
            <div className="label-bold">Date & Time of Call</div>
            <p className="telecom-text mt-1">
              {getClientFormattedDate(tracebackTime, DateFormat.LongBoth)}
            </p>
            <div className="label-bold">Terminating Line Type</div>
            <p className="telecom-text mt-1">{traceback.termLineType}</p>
            <div className="label-bold">Terminating State</div>
            <p className="telecom-text">{traceback.termState}</p>
            <div className="label-bold">Terminating Country</div>
            <p className="telecom-text">{traceback.terminatingCountry}</p>
            <label className="label-bold">Do Not Origin Registry</label>
            <CustomToolTip
              message={
                DnoToolTip.find((v) => v.type === traceback.dnoType)?.description ||
                traceback.dnoType ||
                ''
              }
              tooltipVisible={!!traceback.dnoType}
            >
              <p className="telecom-text mb-0 pt-1">
                {traceback.dno ? 'Industry DNO' : 'Not Listed'}
              </p>
            </CustomToolTip>
            <div className="label-bold mt-2">Do Not Call Registry</div>
            <p className="telecom-text">{traceback.dnc || 'None'}</p>
          </div>
          <div className="col-12 col-md-4">
            <div className="label-bold">Calling Number</div>
            <p className="telecom-text">
              <span className="fa fa-phone green" />
              {traceback.callingTN}
            </p>
            <div className="label-bold">Calling Country</div>
            <p className="telecom-text">{traceback.callingCountry}</p>
            <div className="label-bold">Caller Name</div>
            <p className="telecom-text">{traceback.callerName}</p>
            <div className="label-bold pt-1">Origin Line Type</div>
            <p className="telecom-text mt-1">{traceback.originLineType}</p>
            <div className="label-bold">Originating State</div>
            <p className="telecom-text">{traceback.originState}</p>
            <div className="label-bold">Originating Country</div>
            <p className="telecom-text">{traceback.originCountry}</p>
            <div className="label-bold">Originating Carrier</div>
            <p className="telecom-text">{traceback.originCarrier}</p>
            <div className="label-bold">LNP Status and Date</div>
            <p className="telecom-text">{traceback.lnpStatusAndDate}</p>
          </div>
          <div className="col-12 col-md-4">
            <div className="label-bold">Audio URL</div>
            {traceback.audioUrl ? (
              <Fragment>
                <audio controls>
                  <source src={traceback.audioUrl} />
                </audio>
                <br />
                <a
                  href={traceback.audioUrl}
                  target={'audio'}
                  rel={'noopener noreferrer'}
                  className="telecom-text fontSize"
                  type={'audio/mp3'}
                >
                  {traceback.audioUrl}
                </a>
                {traceback.representativeAudio === false && ( // null means something else
                  <p>Voicemail May Be a Representative Sample of Campaign</p>
                )}
              </Fragment>
            ) : (
              <p className="telecom-text pt-1">{'None'}</p>
            )}
          </div>
        </div>
        <div className="mb-4">
          <h5
            className="p-0 traceback-sub-title mb-3"
            onClick={() => {
              setIsStirShakenVisible((v) => !v);
            }}
            role="button"
          >
            {(traceback.stirShaken || (hopsStirShaken && hopsStirShaken.length)) && (
              <i
                className={`${
                  isStirShakenVisible ? 'fa-solid fa-angle-down' : 'fa-solid fa-angle-right'
                } pe-2 custom-blue`}
              />
            )}
            Stir/Shaken
          </h5>
          <div className={isStirShakenVisible ? '' : 'd-none'}>
            <StirShakenRow stirShaken={traceback.stirShaken} traceback={traceback} />
            {hopsStirShaken &&
              hopsStirShaken
                .sort(
                  (a: StirShakenResponse, b: StirShakenResponse) => a.hopSequence - b.hopSequence
                )
                .map((item: StirShakenResponse) => (
                  <StirShakenRow key={item.hopID} stirShaken={item} traceback={traceback} />
                ))}
          </div>
        </div>
        <div>
          <h5 className="col-md-12 p-0 traceback-sub-title">Terminating Provider Details</h5>
          <div className="row">
            <div className="col-6 col-md-5">
              <div className="label-bold">Terminating Carrier ID</div>
              <p className="telecom-text pt-1">{traceback.termCarrierId}</p>
            </div>
            <div className="col-6 col-md-5">
              <div className="label-bold">Terminating Carrier OCN</div>
              <p className="telecom-text pt-1">{traceback.termCarrierOCN}</p>
            </div>
          </div>
        </div>
        <div>
          <h5 className="col-md-12 p-0 traceback-sub-title">System Comments</h5>
          <CommentComponent input={traceback.comments} includeSystem />
        </div>
        <div>
          <h5 className="col-md-12 p-0 traceback-sub-title">User Comments + Attachments</h5>
          <CommentComponent input={traceback.comments} includeUser />
        </div>
        <div>
          <h5 className="col-md-12 p-0 traceback-sub-title">Additional Details</h5>
          <div className="row">
            <div className="col-6 col-md-4">
              <div className="label-bold">Strike Exempt</div>
              <p className="telecom-text pt-1">{traceback.strikeExempt ? 'Yes' : 'No'}</p>
            </div>
            <div className="col-6 col-md-4">
              <div className="label-bold">Traceback Created</div>
              <p className="telecom-text pt-1">
                {getClientFormattedDate(traceback.create_date, DateFormat.MediumBoth)}
              </p>
            </div>
            <div className="col-6 col-md-4">
              <div className="label-bold">Traceback Created By</div>
              <p className="telecom-text pt-1">{traceback.createdBy}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-md-4">
              <div className="label-bold">Traceback Requestor</div>
              <p className="telecom-text pt-1">
                {traceback.requestors &&
                  traceback.requestors.map((requestor: any) => requestor.name).join(', ')}
              </p>
            </div>
            <div className="col-6 col-md-4">
              <div className="label-bold">Creator's Provider</div>
              <p className="telecom-text pt-1">{traceback.creatorProvider || 'None'}</p>
            </div>
            <div className="col-6 col-md-4">
              <div className="label-bold">Traceback No.</div>
              <p className="telecom-text pt-1">{traceback.tracebackId}</p>
            </div>
            <div className="ms-3 w-50">
              <div className="label-bold">Campaign description</div>
              <p className="telecom-text pt-1">{traceback.campaignDescription}</p>
            </div>
          </div>
        </div>
      </div>
      <BasisForTraceback source={traceback.campaignAttributes} user={user} />
    </div>
  );
};

export default TracebackView;
