import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Breadcrumb from '../components/Breadcrumbs';
import CallerCustomerAndAverageDailyTrafficChart from '../components/Charts/CallerCustomerAndAverageDailyTrafficChart';
import OriginProvidersActionsChart from '../components/Charts/OriginProvidersActionsChart';
import ProviderStatsChart from '../components/Charts/ProviderStatsChart';
import ProviderStirShakenStats from '../components/Charts/ProviderStirShakenStats';
import CustomSelect, { SelectOption } from '../components/CustomSelect';
import ProviderSummaryPanel from '../components/ProviderSummaryPanel';
import UpstreamProviderSummaryPanel from '../components/UpstreamProviderSummaryPanel';
import AdminsComments from '../components/shared/AdminsCommentsProviders';
import FollowProviderCheckbox from '../components/shared/FollowProviderCheckbox';
import LabelSelect from '../components/shared/LabelSelect';
import Legend from '../components/shared/Legend';
import ZendeskWebWidget from '../components/shared/ZendeskWebWidget';
import { userTypes } from '../enum/userTypes';
import { ChartFilterElementsForProviderSummary } from '../interfaces/charts';
import { Provider } from '../interfaces/provider';
import { PersonalInfo } from '../interfaces/user';
import {
  getEndDateFromPeriodNumber,
  getStartDateFromPeriodNumber,
  trimPrefix
} from '../lib/utilities';
import { getProviderObject } from '../redux/provider/thunks';
import { getHopsCSV } from '../redux/reputation/thunks';
import { stateMappings } from '../redux/stateMappings';
import GeneralDatePicker from '../components/GeneralDatePicker';

interface IProps {
  getProviderObject: Function;
  provider: Provider;
  error: string;
  user: PersonalInfo;
  getHopsCSV: Function;
}

const concatWithoutDuplicates = (a: SelectOption[], b: SelectOption[]): SelectOption[] => {
  return a.concat(b.filter((item) => !a.some((element) => element.value === item.value)));
};

const ProviderSummary: React.FC<IProps> = ({
  getProviderObject,
  provider,
  error,
  user,
  getHopsCSV
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const providerId = Number(id || 0);
  const [startDate, setStartDate] = useState<string>(getStartDateFromPeriodNumber(2) || '');
  const [endDate, setEndDate] = useState<string>(getEndDateFromPeriodNumber(2) || '');
  const [filter, setFilter] = useState<ChartFilterElementsForProviderSummary>({
    startDate: startDate,
    endDate: endDate
  });
  const [prerecordedNotesList, setPrerecordedNotesList] = useState<SelectOption[]>([]);
  const [liveNotesList, setLiveNotesList] = useState<SelectOption[]>([]);
  const [prerecordedTermStateList, setPrerecordedTermStateList] = useState<SelectOption[]>([]);
  const [liveTermStateList, setLiveTermStateList] = useState<SelectOption[]>([]);

  useEffect(() => {
    if (error) {
      navigate('/404');
    }
    getProviderObject(providerId);
  }, [providerId, error]);

  useEffect(() => {
    setFilter((v) => ({
      ...v,
      startDate: startDate,
      endDate: endDate
    }));
  }, [startDate, endDate]);

  const handleCheck = (e: any, key: string) => {
    switch (key) {
      case 'selectedTerminatingState':
        setFilter({ ...filter, termState: e?.value || null });
        break;
      case 'labelId':
        setFilter({ ...filter, labelId: e?.value || null });
        break;
      case 'notes':
        setFilter({ ...filter, note: e?.value || null });
        break;
    }
  };

  const clearFilters = () => {
    setStartDate(getStartDateFromPeriodNumber(2) || '');
    setEndDate(getEndDateFromPeriodNumber(2) || '');
  };

  return (
    <Fragment>
      <FollowProviderCheckbox
        className="d-flex providerSummary-title-container  justify-content-between"
        providerId={providerId}
      >
        <Breadcrumb
          title={provider ? provider.name : ''}
          className="table-breadcrumbs"
          reputation={provider.reputation}
        />
      </FollowProviderCheckbox>
      <Card className="table-card mb-0">
        <CardHeader className="card-header-provider">
          <Row className="ms-3 mb-3 align-items-end">
            <GeneralDatePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              defaultStartDate={getStartDateFromPeriodNumber(2)}
              defaultEndDate={getEndDateFromPeriodNumber(2)}
              placeholderTextStart="&#xf133; startDate"
              placeholderTextEnd="&#xf133; endDate"
              containerClassname="col-md-2"
              clearButtonTitle={'Clear'}
              isClearable
              showYearDropdown
              yearDropdown
              scrollableYearDropdown
            />
            <Col md="2">
              <CustomSelect
                className="customselect-small"
                classNamePrefix="customselect"
                selectedOption={filter.termState}
                setSelectedOption={(option) => handleCheck(option, 'selectedTerminatingState')}
                getOptions={concatWithoutDuplicates(prerecordedTermStateList, liveTermStateList)}
                typeLabel={''}
                isClearable
                placeholder="Terminating State"
              />
            </Col>
            <Col md="2">
              <LabelSelect
                onChange={(option) => {
                  handleCheck(option, 'labelId');
                }}
                isSearchable={false}
                isClearable
                value={filter.labelId}
                placeholder="Campaign Labels"
                providerId={providerId}
                startDate={filter.startDate}
                endDate={filter.endDate}
              />
            </Col>
            <Col md="2">
              <CustomSelect
                className="customselect-small"
                classNamePrefix="customselect"
                selectedOption={filter.note}
                setSelectedOption={(option) => handleCheck(option, 'notes')}
                getOptions={concatWithoutDuplicates(prerecordedNotesList, liveNotesList)}
                isSearchable={false}
                isClearable
                placeholder="Role/Status"
                typeLabel={''}
                formatOptionLabel={(e: SelectOption) => (
                  <div className="d-flex align-items-center">
                    {e.label.includes('Down') && (
                      <i className="fa-solid fa-arrow-down hop-dnr-arrow" />
                    )}
                    <span className={`${e.label.includes('Down') ? 'ms-1' : ''}`}>
                      {trimPrefix(e.value, 'Down')}
                    </span>
                  </div>
                )}
              />
            </Col>
            <Col md="2">
              <button type="button" className="btn btn-link" onClick={clearFilters}>
                Clear
              </button>
            </Col>
          </Row>
          <Row className="me-4">
            <h3>Prerecorded or Artificial Calls</h3>
            <p>
              Suspected illegal calls that are made with a prerecorded or artificial voice, which
              include calls made with soundboard or synthetic voices technologies consistent with
              regulatory guidance.
            </p>
          </Row>
          <div className="pb-5 ps-5 pe-5">
            <ProviderStatsChart
              providerId={providerId}
              filterElements={filter}
              legendPosition="right"
              height={90}
            />
          </div>
          <div className="row pb-5 ps-5 pe-5">
            <CallerCustomerAndAverageDailyTrafficChart
              providerId={providerId}
              chartFilters={filter}
              legendPosition="top"
              height={100}
            />

            <OriginProvidersActionsChart
              providerId={providerId}
              chartFilters={filter}
              legendPosition="top"
              height={375}
              width={375}
            />
          </div>
          <ProviderStirShakenStats providerId={providerId} chartFilters={filter} />
          <Row className="pb-3 justify-content-end">
            {user.roleType === userTypes.GovUser && (
              <Col md="2" className="d-flex justify-content-xl-end">
                <Button
                  onClick={() =>
                    getHopsCSV({
                      startDate: filter.startDate,
                      endDate: filter.endDate,
                      providerId,
                      termState: filter.termState || undefined,
                      labelId: filter.labelId || undefined,
                      notes: filter.note || undefined
                    })
                  }
                  className="downloadCsvStyle"
                >
                  <i className="fa fa-download" />
                  {` Download CSV`}
                </Button>
              </Col>
            )}
          </Row>
          <CardBody className="card-body-provider card-summary">
            <ProviderSummaryPanel
              providerId={providerId}
              startDate={filter.startDate}
              endDate={filter.endDate}
              labelId={filter.labelId}
              termState={filter.termState}
              note={filter.note}
              setPrerecordedNotesList={setPrerecordedNotesList}
              setPrerecordedTermStateList={setPrerecordedTermStateList}
              setLiveNotesList={setLiveNotesList}
              setLiveTermStateList={setLiveTermStateList}
            />
            <AdminsComments providerId={providerId} />
            {user.roleType !== userTypes.GovUser && (
              <UpstreamProviderSummaryPanel
                providerId={providerId}
                startDate={filter.startDate}
                endDate={filter.endDate}
              />
            )}
          </CardBody>
        </CardHeader>
      </Card>
      <div className="d-flex justify-content-center pt-4 pb-4">
        <Legend />
      </div>
      <ZendeskWebWidget />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    provider: sm.provider.provider,
    error: sm.provider.error,
    user: sm.user
  };
};

const mapActionsToProps = {
  getProviderObject,
  getHopsCSV
};

export default connect(mapStateToProps, mapActionsToProps)(ProviderSummary);
